import { Navigate, Outlet } from "react-router-dom"
import { DashboardLayout } from "../components/DashboardLayout"
import { useAuth } from "./AuthProvider"

export const PrivateRoutes = () => {
    const { user } = useAuth();
    return user? 
        <DashboardLayout>
            <Outlet />
        </DashboardLayout>
    : <Navigate to="/login" replace />
}