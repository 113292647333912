
import fastPrevIcon from '../assets/img/icon-fast-prev.png'
import prevIcon from '../assets/img/icon-prev.png'
import fastnextIcon from '../assets/img/icon-fast-next.png'
import nextIcon from '../assets/img/icon-next.png'

export const Pagination = ({ data: { page, totalCount, limit }, setCurrentPage }) => {
  console.log(page, totalCount, limit)
  const totalPages = Math.ceil(totalCount / limit);
  const paginationArray = [1];

  if (page > 2) {
    paginationArray.push('...');
  }

  for (let i = Math.max(2, page - 1); i <= Math.min(totalPages - 1, page + 1); i++) {
    paginationArray.push(i);
  }

  if (page < totalPages - 2) {
    paginationArray.push('...');
  }

  if (totalPages > 1)
  {
    paginationArray.push(totalPages);
  }

  const onPageClick = (item) => {
    return () => {
      if (typeof item === "number") {
        console.log(item);
        setCurrentPage(item);
      }
    };
  };

  return (
    <ul className="pagination">
      <li onClick={() => setCurrentPage(page - 4)}>
        <img src={fastPrevIcon} />
      </li>
      <li onClick={() => setCurrentPage(page - 1)}>
        <img src={prevIcon} />
      </li>
      {paginationArray.map((item, index) => (
        <li
          onClick={onPageClick(item)}
          key={index}
          className={item == page ? "active" : ""}
        >
          {item}
        </li>
      ))}
      <li onClick={() => setCurrentPage(page + 1)}>
        <img src={nextIcon} />
      </li>
      <li  onClick={() => setCurrentPage(page + 4)}>
        <img src={fastnextIcon} />
      </li>
    </ul>
  );
};

