import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'purchase_order',
        name: 'Purchase Order No'
    },
    {
        key: 'supplier_name',
        parent: 'supplier',
        name: 'Supplier Name'
    },
    {
        key: 'purchase_date',
        name: 'Purchase Date'
    },
    {
        key: 'total',
        name: 'Total Amount'
    },
    {
        key: 'currency_type',
        name: 'currency'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'purchase_id',
        name: 'Purchase Order ID'
    },
    {
        key: 'supplier_name',
        parent: 'supplier',
        name: 'Supplier Name'
    },
    {
        key: 'purchase_date',
        name: 'Purchase Date'
    },
    {
        key: 'currency_type',
        name: 'Currency Type'
    },
    {
        key: 'total',
        name: 'Total Amount'
    },
    {
        key: 'remark',
        name: 'Remark'
    }
]

const detailPopupTableHeaders = [
    {
        key: 'product_name',
        parent: 'product',
        name: 'Product Name'
    },
    {
        key: 'unit_name',
        parent: 'unit',
        name: 'Unit'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    },
    {
        key: 'price',
        name: 'Price'
    },
    {
        key: 'total_amount',
        name: 'Total'
    }
]

const PurchaseOrderList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const {data, setCurrentPage, setQuery, reload} = useFetchData({
        url: API_URL + '/purchaseOrder',
        defaultQuery: 'status=Pending&status=PARTIAL_ARRIVED&status=ARRIVED'
    });

    console.log('Purchase Order',data)

    return (
        <TableLayout
            title='Purchase Order List'
            data={data}
            headers={headers}
            detailPopupTitle='Purchase Order Information'
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTableHeaders={detailPopupTableHeaders}
            detailPopupTableKey='purchaseOrderDetails'
            idKey='purchase_id'
            endPoint='/purchaseOrder'
            setCurrentPage={setCurrentPage}
            hasSearch
            hasCreate
            hasActions
            createPath={`/purchase/purchase-order/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
            removeEdit={true}
        />
    )
}
 
export default PurchaseOrderList;