import { useLocation } from "react-router-dom";
import TableLayout from "../../components/TableLayout";
import { useFetchData } from "../../hooks/useFetchData";
import { API_URL } from "../../utils/constant";

const headers = [
    {
        key: 'customer_name',
        name: 'Customer Name'
    },
    {
        key: 'member_code',
        name: 'Member Card Number'
    },
    {
        key: 'shop_name',
        name: 'Shop Name'
    },
    {
        key: 'loyalty_points',
        name: 'Point'
    },

    {
        key: 'advanced_mmk',
        name: 'Advanced MMK'
    },

    {
        key: 'advanced_thb',
        name: 'Advanced THB'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'address',
        name: 'Address'
    },
    {
        key: 'status',
        name: 'Status'
    },
];

const detailPopupHeaders = [
    {
        key: 'customer_id',
        name: 'Wholesale Customer ID'
    },
    {
        key: 'customer_name',
        name: 'Customer Name'
    },
    {
        key: 'member_code',
        name: 'Member Card Number'
    },
    {
        key: 'shop_name',
        name: 'Shop Name'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'phone2',
        name: 'Phone2'
    },
    {
        key: 'phone3',
        name: 'Phone 3'
    },
    {
        key: 'phone4',
        name: 'Phone 4'
    },
    {
        key: 'address',
        name: "Address"
    }
]

export default function WholesaleCustomerList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        setQuery({
            customer_name: value.trim(),
        });
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/wholesaleCustomer',
        defaultQuery: 'status=ACTIVE'
    });

    return(
        <TableLayout
            title="Wholesale Customer List"
            data={data}
            headers={headers}
            detailPopupTitle="Wholesale Customer Information"
            detailPopupHeaders={detailPopupHeaders}
            idKey='customer_id'
            endPoint='/wholesaleCustomer'
            hasPointChange
            pointChangeModel="WholesaleCustomer"
            hasSearch
            hasCreate
            createPath={`/customer/wholesale/create`}
            hasActions
            setCurrentPage={setCurrentPage}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            reload={reload}
        />
    );
}