import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png'
import { API_URL } from '../../utils/constant';
import { useAuth } from '../../routers/AuthProvider';

export default function EditProductCategory() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/product/category`;
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('PUT', `/productCategory/${id}`, data, () => {
            navigate(backPath)
        });
    }

    useEffect(() => {
        setFormData({
            product_category_name: initialData.product_category_name,
            sub_product_category_name: initialData.sub_product_category_name,
            remark: initialData.remark,
            prefix_code: initialData.prefix_code
        })
    }, [])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container'>
                <h1 className='sub-page-title'>Edit Product Category</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>

                            <div className='grow'>
                                <label className='x-label input-required'>Category Name</label>
                                <input type='text'
                                    required
                                    name='product_category_name'
                                    defaultValue={initialData.product_category_name}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label'>Sub Category Name</label>
                                <input type='text'
                                    defaultValue={initialData.sub_product_category_name}
                                    name='sub_product_category_name'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    defaultValue={initialData.remark}
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Prefix Code</label>
                                <input type='text'
                                    defaultValue={initialData.prefix_code}
                                    required
                                    name='prefix_code'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}