import React, { useState, useEffect } from "react";
import plusIcon from '../assets/img/icon-plus.png';
import Popup from "./Popup";
import { formatDateToDDMMYY } from "../utils/format-time";

const RetailSalesOrderTable = ({ data, setActiveTab, promotionByTotal, setPromotionByTotal, currencyType, THBToMMK, MMKToTHB, setSubTotal, setSaleProducts, setSubTotalTHB, setSubTotalMMK }) => {
    const [popup, setPopup] = useState({
        id: '',
        show: false
    });

    const onCloseClick = (e) => {
        e.preventDefault();
        setPopup({
            id: '',
            show: false,
        });
    }

    useEffect(() => {
        document.getElementById('external-popup').classList.toggle('show', popup.show);
        const openedEl = document.querySelector('.popup');

        const handleDocumentClick = e => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.parentNode.classList.remove('show')
                onCloseClick(e);
            }
        }
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener('click', handleDocumentClick)
            }, 100)

        }
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [popup.show])

    const onDetailClick = e => {
        e.preventDefault();
        const id = e.target.parentNode.parentNode.id;
        console.log(id)
        setPopup({
            show: true,
            eID: 'promotion-popup',
            id: id,
        });
    }

    const getSubTotal = (currencyType, seperatedType) => {
        let total = 0
        if (currencyType === "MMK") {
            data.forEach(product => {
                total += parseFloat(product.mmkTotal ? product.mmkTotal : product.mmkPrice)
            });
        }

        if (currencyType === "THB") {
            data.forEach(product => {
                total += parseFloat(product.thbTotal ? product.thbTotal : product.thbPrice)
            });
        }
        if (currencyType === "SEPERATED" && seperatedType === "MMK") {
            data.forEach(product => {
                total += parseFloat(product.convertedMMKTotal)
            });
        }

        if (currencyType === "SEPERATED" && seperatedType === "THB") {
            data.forEach(product => {
                total += parseFloat(product.convertedTHBTotal)
            });
        }
        total = parseFloat((total).toFixed(2))
        return total
    }

    useEffect(() => {
        
        if (currencyType === 'SEPERATED') {
            setSubTotalMMK(getSubTotal(currencyType, 'MMK'));
            setSubTotalTHB(getSubTotal(currencyType,'THB'));
        } else {
            setSubTotal(getSubTotal(currencyType));
        }
    }, [data, currencyType])

    const PromotionPopup = ({ id }) => {
        let product, eligiblePromo, applyPromo

        if (id === "By Total") {
            eligiblePromo = promotionByTotal.promotion
            applyPromo = promotionByTotal.applyPromo
        } else {
            product = data.find(item => item.product_id === id)
            eligiblePromo = product.promotion
            applyPromo = product.applyPromo
        }

        return (
            <div className='popup'>
                <div className="flex flex-row-reverse">
                    <button onClick={onCloseClick}><img src={plusIcon} alt="close icon" className="rotate-45 w-5 h-5" /></button>
                </div>
                {eligiblePromo.promotion_type === "By Total" && <div className="flex justify-center py-2">
                    <table className="promotion-detail-table">
                        <tbody>
                            <tr>
                                <td>Promotion Type</td>
                                <td className="px-8">:</td>
                                <td>Amount</td>
                            </tr>
                            <tr>
                                <td>Minimum Total Amount</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.minimum_total_amount}</td>
                            </tr>
                            <tr>
                                <td>Promotion Amount</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.promotion_amount}</td>
                            </tr>
                            <tr>
                                <td>Start Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.start_date))}</td>
                            </tr>
                            <tr>
                                <td>End Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.end_date))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}

                {eligiblePromo.promotion_type === "By BOGO" && <div className="flex justify-center py-2">
                    <table className="promotion-detail-table">
                        <tbody>
                            <tr>
                                <td>Promotion Type</td>
                                <td className="px-8">:</td>
                                <td>Buy One Get One</td>
                            </tr>
                            <tr>
                                <td>Product Name</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.product.product_name}</td>
                            </tr>
                            <tr>
                                <td>Start Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.start_date))}</td>
                            </tr>
                            <tr>
                                <td>End Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.end_date))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}

                {eligiblePromo.promotion_type === "By Percent" && <div className="flex justify-center py-2">
                    <table className="promotion-detail-table">
                        <tbody>
                            <tr>
                                <td>Promotion Type</td>
                                <td className="px-8">:</td>
                                <td>Percent</td>
                            </tr>
                            <tr>
                                <td>Product Name</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.product.product_name}</td>
                            </tr>
                            <tr>
                                <td>Promotion Percent</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.percent_amount}%</td>
                            </tr>
                            <tr>
                                <td>Start Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.start_date))}</td>
                            </tr>
                            <tr>
                                <td>End Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.end_date))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}

                {eligiblePromo.promotion_type === "By Cross Product" && <div className="flex justify-center py-2">
                    <h1>{eligiblePromo.confirm}</h1>
                    <table className="promotion-detail-table">
                        <tbody>
                            <tr>
                                <td>Promotion Type</td>
                                <td className="px-8">:</td>
                                <td>Cross Product</td>
                            </tr>
                            <tr>
                                <td>Buy Products</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.crossableProducts.map(item => <div>{`${item.product.product_name} x ${item.quantity}`}</div>)}</td>
                            </tr>
                            <tr>
                                <td>Get Products</td>
                                <td className="px-8">:</td>
                                <td>{eligiblePromo.promoProducts.map(item => <div>{`${item.product.product_name} x ${item.quantity}`}</div>)}</td>
                            </tr>
                            <tr>
                                <td>Start Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.start_date))}</td>
                            </tr>
                            <tr>
                                <td>End Date</td>
                                <td className="px-8">:</td>
                                <td>{formatDateToDDMMYY(new Date(eligiblePromo.end_date))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}

                <div className="flex justify-center">
                    <button className={applyPromo ? "grey-btn" : "gold-btn"} onClick={(e) => {
                        if (eligiblePromo.promotion_type === "By Total") {
                            setPromotionByTotal({ promotion: promotionByTotal.promotion, applyPromo: true })
                            setActiveTab(1)
                        } else {

                            setSaleProducts(prevData => {
                                return prevData.map(product => (product.product_id === id ? { ...product, applyPromo: true } : product))
                            });
                            if (eligiblePromo.promotion_type === "By Percent") {
                                setActiveTab(2)
                            } else if (eligiblePromo.promotion_type === "By BOGO") {
                                setActiveTab(3)
                            } else {
                                setActiveTab(4)
                            }
                        }
                        onCloseClick(e)
                    }}>{applyPromo ? "Revoke" : "Apply"}</button></div>
            </div>
        );
    }

    return (
        <div className="table-container">
            <div className="mb-4 flex justify-between">
                <h3 className="table-title">Retail Sales Order Information</h3>
            </div>
            <table className='x-table product-list-table'>
                <thead>
                    <tr>
                        <th>Barcode</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>MMK Price</th>
                        <th>THB Price</th>
                        <th>MMK Total</th>
                        <th>THB Total</th>
                        <th>Promotion</th>
                        <th className="button-col"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        return (
                            <tr key={item.product_id} id={item.product_id}>
                                <td>{item.barcode}</td>
                                <td>{item.product_name}</td>
                                <td>
                                    <input id={`qty-${item.product_id}`}
                                        className="w-16 border border-solid border-neutral-300 rounded"
                                        min="1"
                                        value={item.quantity}
                                        type="number"
                                        onChange={(e) => {
                                            const quantity = e.target.value;
                                            const mmkTotal = (quantity * item.mmkPrice).toFixed(2);
                                            const thbTotal = (quantity * item.thbPrice).toFixed(2);
                                            const convertedMMKTotal = item.productPrice.currency === "MMK" ? mmkTotal : 0
                                            const convertedTHBTotal =  item.productPrice.currency === "BAHT" ? thbTotal : 0

                                            setSaleProducts(prevData => {
                                                return prevData.map(product => (product.product_id === item.product_id ? { ...product, quantity, mmkTotal, thbTotal, convertedMMKTotal, convertedTHBTotal } : product));
                                            });

                                            setSubTotal(getSubTotal(currencyType));
                                        }}
                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`MMK-price-${item.product_id}`}
                                        className="w-16"
                                        value={item.mmkPrice} />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`THB-price-${item.product_id}`}
                                        className="w-16"
                                        value={isNaN(item.thbPrice)? 0 : item.thbPrice} />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`MMK-total-${item.product_id}`}
                                        className="w-16"
                                        // value={item.mmkTotal ? item.mmkTotal : item.mmkPrice}
                                        value={item.convertedMMKTotal}
                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`THB-total-${item.product_id}`}
                                        className="w-16"
                                        // value={item.thbTotal ?   item.thbTotal :  item.thbPrice }
                                        value={item.convertedTHBTotal}
                                    />
                                </td>
                                <td className={item.applyPromo ? "apply" : ""}>
                                    {
                                        item.promotion ? <a href='' className={"promotion-btn"}
                                            onClick={onDetailClick}
                                            id={`promo-${item.product_id}`}>{item.promotion.promotion_type}</a> : null
                                    }
                                </td>
                                <td className="button-col" onClick={(e) => {
                                    const filterItem = data.filter(item => item.product_id !== e.target.parentNode.parentNode.id)
                                    setSaleProducts(filterItem)
                                }}><img src={plusIcon} alt="close icon" className="rotate-45 w-5 h-5" /></td>
                            </tr>
                        )
                    })}
                    <tr id="By Total">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">Sub Total</td>

                        {currencyType === "MMK" &&
                             <td>{getSubTotal(currencyType ,"MMK")}</td>
                        }
                         {currencyType === "THB" &&
                             <td>{getSubTotal(currencyType ,"THB")}</td>
                        }
                        {
                            currencyType == "SEPERATED" &&
                            <React.Fragment>
                                <td>{getSubTotal(currencyType ,"MMK")}</td>
                                <td>{getSubTotal(currencyType ,"THB")}</td>
                            </React.Fragment>
                        }
                       
                        <td className={promotionByTotal.applyPromo ? "apply" : ""}>
                            {
                                promotionByTotal.promotion && <a href="" className="promotion-btn" id="amount-promo" onClick={onDetailClick}>By Total</a>
                            }
                        </td>
                        <td className="button-col"></td>
                    </tr>
                </tbody>
            </table>
            <Popup className='popup-container'>
                {popup.eID === 'promotion-popup' && <PromotionPopup id={popup.id} promoType={popup.promoType} />}
            </Popup>
        </div>
    );
}

export default RetailSalesOrderTable;