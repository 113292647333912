import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'purchase_arrival',
        name: 'Purchase Order No'
    },
    {
        key: 'warehouse_name',
        parent: 'warehouse',
        name: 'Arrived Warehouse'
    },
    {
        key: 'arrival_time',
        name: 'Arrival Time'
    },
    // {
    //     key: 'status',
    //     name: 'Status'
    // }
];

const detailPopupHeaders = [
    {
        key: 'purchase_id',
        name: 'Purchase Order ID'
    },
    {
        key: 'warehouse_name',
        parent: 'warehouse',
        name: 'Arrived Warehouse'
    },
    {
        key: 'arrival_time',
        name: 'Arrival Time'
    },
    {
        key: 'remark',
        name: 'Remark'
    }
]

const detailPopupTableHeaders = [
    {
        key: 'barcode',
        name: 'Barcode'
    },
    {
        key: 'product_name',
        parent: 'product',
        name: 'Product Name'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    }
]

const PurchaseArrivalList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const {data, setCurrentPage, setQuery, reload} = useFetchData({
        url: API_URL + '/purchaseArrival',
        sort: '-arrival_time',
        defaultQuery: 'status=ACTIVE'
    });

    return (
        <TableLayout
            title='Purchase Arrival List'
            data={data}
            headers={headers}
            detailPopupTitle='Purchase Arrival Information'
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTableHeaders={detailPopupTableHeaders}
            detailPopupTableTitle='Product List'
            detailPopupTableKey='purchaseArrivalDetails'
            setCurrentPage={setCurrentPage}
            idKey='purchase_arrival_id'
            endPoint='/purchaseArrival'
            hasSearch
            hasActions
            removeEdit={true}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}
 
export default PurchaseArrivalList;