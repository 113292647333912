import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import axios from 'axios';
import { API_URL } from '../../utils/constant';


export default function EditGiftRule() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/loyalty/point-rule/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('PUT', '/pointRule/' + id, data, () => {
            navigate(backPath)
        });
    }

   

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Gift Rule</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Gift Rule Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            
                          
                            <div className='grow'>
                                <label className='x-label input-required'>Point</label>
                                <input type='number'
                                    required
                                    defaultValue={initialData.point}
                                    name='point'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>MMK Amount</label>
                                <input type='number'
                                    required
                                    defaultValue={initialData.mmk_amount}
                                    name='mmk_amount'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>THB Amount</label>
                                <input type='number'
                                    required
                                    defaultValue={initialData.thb_amount}
                                    name='thb_amount'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}