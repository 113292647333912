import React from 'react'
import { useCheckUser } from "../hooks/useCheckUser";
import { getCurrentDateTime } from "../utils/format-time";

const Receipt = ({ mmkTotal, thbTotal, total, subTotal, discount, discountInMMK, discountInTHB, currencyType, voucherCode, paidAmount, change, getPrintItems, saleProducts, printRef, promotionAmount, promotionProducts, exchangeRate, reverseExchangeRate, totalInMMK, totalInTHB, paidAmountInMMK, paidAmountInTHB, changeInMMK, changeInTHB }) => {
    const user = useCheckUser();
    return (
        <div style={{ display: "none" }}>
            <div ref={printRef} className='receipt' style={{ "display": "flex", "flexDirection": "column", "alignItems": "center", "padding": "12px 0px", "textWrap": "wrap" }}>
                <h1>Good Supermarket</h1>
                <h3>Supermarket</h3>
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <h3>No.37/Boke Yoke Street/Ward(4)/Myawaddy</h3>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                        <div><span>{voucherCode}</span></div>
                        <div><span>{getCurrentDateTime()}</span></div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}><span>Cashier: {user.user.name}</span></div>
                </div>
                <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr style={{ borderTop: "1px dashed" }}>
                                <th>No</th>
                                <th style={{ textAlign: "left" }}>Item</th>
                                <th>Qty</th>
                                <th style={{ textAlign: "right" }}>Price</th>
                                <th style={{ textAlign: "right" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getPrintItems(saleProducts).map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td></td>
                                            <td>{item.barcode}</td>
                                        </tr>
                                        <tr key={index + "_details"}>
                                            <td>{index + 1}</td>
                                            <td style={{ textAlign: "left" }}>{item.description}</td>
                                            <td>{item.quantity}</td>
                                            <td style={{ textAlign: "right" }}>{item.price}</td>
                                            <td style={{ textAlign: "right", paddingRight: "0.7rem" }}>{item.total}</td>
                                        </tr>
                                    </React.Fragment>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            {promotionAmount !== 0 && <tr style={{ borderBottom: "1px dashed" }}>
                                <td colSpan={4} style={{ textAlign: "left" }}>Promotion Amount</td>
                                <td style={{ textAlign: "right", paddingRight: "0.7rem" }}>{promotionAmount}</td>
                            </tr>}
                            {/* {(promotionProducts && promotionProducts.length > 0) && <tr style={{ borderBottom: "1px dashed" }}>
                                <td colSpan={5}>Promotion Products</td>
                                {
                                    promotionProducts.map(item => <td style={{ textAlign: "left" }}>{item.quantity} x {item.product_name}</td>)
                                }
                            </tr>} */}
                            {(promotionProducts && promotionProducts.length > 0) && (<>
                                <tr>
                                    <td colSpan={5} style={{ textAlign: "left" }}>Promotion Products</td>
                                </tr>
                                {
                                    promotionProducts.map((item, i) => {
                                        return (
                                            // <tr style={{ borderBottom: (i == promotionProducts.length - 1)?"1px dashed": "none" }}>
                                            //     <td colSpan={5} style={{ textAlign: "left" }}>{item.barcode} x {item.quantity} x {item.product_name}</td>
                                            // </tr>
                                            <React.Fragment key={i}>
                                                <tr >
                                                    <td></td>
                                                    <td>{item.barcode}</td>
                                                </tr>
                                                <tr key={i}>
                                                    < td>{item.quantity}</td>
                                                    <td style={{ textAlign: "left" }}>{item.product_name} </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </>)}
                            <tr style={{ borderTop: promotionProducts?.length > 0 ? "1px dashed" : "none" }}>
                                <td>ပေါင်း</td>
                                <td>{mmkTotal ?? 0}K</td>
                                <td>{thbTotal.toFixed(2) ?? 0}B</td>

                                {
                                    currencyType !== 'SEPERATED' ?
                                        <React.Fragment>
                                            <td>Net</td>
                                            <td>{total}{currencyType === "MMK" ? "K" : "B"}</td>
                                        </React.Fragment>
                                        : null
                                }

                                {
                                    currencyType === 'SEPERATED' ?
                                        <React.Fragment>
                                            <td>Net</td>
                                            <td>{`${totalInMMK}K`}</td>
                                            <td>{`${totalInTHB}B`}</td>
                                        </React.Fragment> : null
                                }
                            </tr>
                            <tr>
                                <td>လျှော့</td>
                                {
                                    currencyType === 'SEPERATED' ?
                                        <React.Fragment>
                                            <td>{`${discountInMMK}K`}</td>
                                            <td>{`${discountInTHB}B`}</td>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <td>{currencyType === "MMK" ? discount : "0"}K</td>
                                            <td>{currencyType === "THB" ? discount : "0"}B</td>
                                        </React.Fragment>

                                }

                                <td>Pay</td>
                                {
                                    currencyType === 'SEPERATED' ?
                                        <React.Fragment>
                                            <td>{`${paidAmountInMMK}K`}</td>
                                            <td>{`${paidAmountInTHB}B`}</td>
                                        </React.Fragment> : <td>{paidAmount}{currencyType === "THB" ? `B` : "K"}</td>
                                }

                            </tr>
                            {/* <tr>
                                <td style={{textWrap: "nowrap"}}>ကျပ်ရှင်း</td>
                                <td>{currencyType === "MMK" && `${paidAmount}K`}</td>
                                <td>Change</td>
                                <td>{change}{currencyType === "MMK" ? "K" : "B"}</td>
                                <td style={{textWrap: "nowrap"}}>Rate 0.00123</td>
                            </tr> */}
                        </tfoot>
                    </table>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <span>Change</span>
                            {
                                currencyType !== 'SEPERATED' ?
                                    <span>{change}{currencyType === "MMK" ? "K" : "B"}</span> : null

                            }
                            {
                                currencyType === 'SEPERATED' ?

                                    <span>{`${changeInMMK}K`}</span> : null

                            }
                            {
                                currencyType === 'SEPERATED' ?

                                    <span>{`${changeInTHB}B`}</span> : null

                            }

                            <span>Rate</span>
                            <span>{currencyType === "MMK" ? exchangeRate : reverseExchangeRate}</span>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", paddingTop: "8px", justifyContent: "space-evenly" }}>
                            <span>Tax 5% included</span>
                            <span>ဝယ်ပြီးပစ္စည်းပြန်မလဲပေးပါ။</span>
                        </div>
                    </div>
                    <div style={{ paddingTop: '20px', paddingBottom: '20px', textAlign: "center" }}>
                        <h3>***THANK YOU***</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Receipt;