import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import axios from 'axios';
import { API_URL } from '../../utils/constant';

export default function CreateGiftAdjustment() {
    const backPath = `/loyalty/gift-adjustment/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('POST', '/presentAdjustment?status=ACTIVE', data, () => {
            navigate(backPath)
        });
    }
    const [warehouses, setWarehouses] = useState();
    useEffect(() => {
        axios.get(API_URL + '/warehouse?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setWarehouses(response.data.data);
            })
    }, []);

    const [presents, setPresents] = useState();
    useEffect(() => {
        axios.get(API_URL + '/present?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setPresents(response.data.data)
            })
    }, []);

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Gift Adjustment</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Gift Adjustment Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Product Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='present_id'
                                        required
                                        onChange={handleInputChange}
                                        defaultValue=''
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {presents && presents.map((item, index) => (
                                            <option key={index} value={item.present_id}>{item.present_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Quantity</label>
                                <input type='number'
                                    required
                                    name='qty'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Warehouse Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='warehouse_id'
                                        className='x-input dropdown'
                                        defaultValue=''
                                        onChange={handleInputChange}
                                    >
                                        <option value='' disabled>Select</option>
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Method</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='method'
                                        className='x-input dropdown'
                                        defaultValue=''
                                        onChange={handleInputChange}
                                    >
                                        <option value='' disabled>Select</option>
                                        <option value="IN">IN</option>
                                        <option value="OUT">OUT</option>
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Gift Adjustment Date</label>
                                <input type='date'
                                    required
                                    name='date'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}