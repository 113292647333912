import  axios  from 'axios'
import { useState, useEffect } from 'react'
import { API_URL } from '../utils/constant'

export const useCheckUser = () => {
    const [user, setUser] = useState({})
    const [isLoading, setisLoading] = useState(true)
    
    const checkUser = (token, callback = ()=> {}) => {
        const localData = JSON.parse(localStorage.getItem('user')) ?? {};
        const authUrl = API_URL + '/auth/me';
        axios.get(authUrl, {
            'headers': {
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            setUser(response.data.data);
            localStorage.setItem('user', JSON.stringify({...response.data.data, token}));
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            setisLoading(false)
            callback();
        })
        .catch(error => {
            
            setUser(null)
            setisLoading(false)
        });
        
    }

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem('user'))?? {};
        setUser(localData);
        checkUser(localData?.token, () => {});
    }, [])

    return {
        user,
        setUser,
        isLoading,
        checkUser
    }

}
