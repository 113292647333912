import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import backArrowIcon from '../../assets/img/back-arrow.png'
import { API_URL } from '../../utils/constant';
import { useAuth } from '../../routers/AuthProvider';

export default function EditWholesaleCustomer() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = '/customer/wholesale';
    const { setLoading } = useAuth();
    const { handleInputChange, formData, setFormData, error, submitForm } = useForm();
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE'
        }
        submitForm('PUT', '/wholesaleCustomer/' + id, data, () => {
            navigate(backPath)
        });
    }

    const [address, setAddress] = useState();
    useEffect(() => {
        axios.get(API_URL + '/common/address', {
            withCredentials: true
        })
            .then(response => {
                setAddress(response.data.data);
            })
    }, [])

    useEffect(() => {
        const elem = document.querySelector('select[name="city_id"].x-input.dropdown');
        const value = elem?.firstElementChild?.value;
        setFormData({ ...formData, city_id: value })

    }, [formData.district_id])

    useEffect(() => {
        const elem = document.querySelector('select[name="township_id"].x-input.dropdown');
        const value = elem?.firstElementChild?.value;
        setFormData({ ...formData, township_id: value })
    }, [formData.city_id])


    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Wholesale Customer</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='pt-5'>
                            <h2 className='sub-title mb-8'>Wholesale Customer Informations</h2>

                            <div className='grid grid-cols-2 gap-9'>

                                <div className='grow'>
                                    <label className='x-label input-required'>Customer Name</label>
                                    <input type='text'
                                        required
                                        defaultValue={initialData.customer_name}
                                        name='customer_name'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>

                                <div className='grow'>
                                    <label className='x-label input-required'>Shop Name</label>
                                    <input type='text'
                                        required
                                        defaultValue={initialData.shop_name}
                                        name='shop_name'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Member Card Number</label>
                                    <input type='text'
                                        required
                                        defaultValue={initialData.member_code}
                                        name='member_code'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Phone 1</label>
                                    <input type='text'
                                        required
                                        defaultValue={initialData.phone1}
                                        name='phone1'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label'>Phone 2</label>
                                    <input type='text'
                                        name='phone2'
                                        defaultValue={initialData.phone2}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>

                                <div className='grow'>
                                    <label className='x-label'>Phone 3</label>
                                    <input type='text'
                                        name='phone3'
                                        defaultValue={initialData.phone3}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>

                                <div className='grow'>
                                    <label className='x-label'>Phone 4</label>
                                    <input type='text'
                                        name='phone4'
                                        defaultValue={initialData.phone4}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                            </div>

                            <div className='form-divider'></div>

                            <h2 className='sub-title mb-8'>Other Informations</h2>
                            <div className='grid grid-cols-2 gap-9'>
                                <div className='grow'>
                                    <label className='x-label'>State</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            name='district_id'
                                            onChange={handleInputChange}
                                            value={formData.district_id ? formData.district_id : initialData.district_id}
                                            className='x-input dropdown'
                                        >
                                            {address && address.district.map((item, index) => (
                                                <option key={index} value={item.district_id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='grow'>
                                    <label className='x-label'>City</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            name='city_id'
                                            onChange={handleInputChange}
                                            value={formData.city_id ? formData.city_id : initialData.city_id}
                                            className='x-input dropdown'
                                        >
                                            {address && address.city.filter(item => item.district_id === parseInt(formData.district_id || initialData.district_id)).map((item, index) => (
                                                <option key={index} value={item.city_id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='grow'>
                                    <label className='x-label'>Township</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            name='township_id'
                                            onChange={handleInputChange}
                                            value={formData.township_id ? formData.township_id : initialData.township_id}
                                            className='x-input dropdown'
                                        >
                                            {address && address.township.filter(item => item.city_id === parseInt(formData.city_id || initialData.city_id)).map((item, index) => {
                                                return <option key={index} value={item.township_id}>{item.name}</option>

                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Address</label>
                                    <input type='text'
                                        defaultValue={initialData.address}
                                        required
                                        name='address'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                            </div>
                        </div>
                        <div className='mt-8 form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </div>

    )
};