import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'date',
        name: 'Gift Adj Date'
    },
    {
        key: 'present_name',
        parent: 'present',
        name: 'Present Name'
    },
    {
        key: 'qty',
        name: 'Quantity'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'present_adjustment_id',
        name: 'Gift Adjustment ID'
    },
    {
        key: 'warehouse_name',
        parent: 'warehouse',
        name: 'Warehouse Name'
    },
    {
        key: 'present_name',
        parent: 'present',
        name: 'Present Name'
    },
    {
        key: 'barcode',
        name: 'Barcode'
    },
    {
        key: 'qty',
        name: 'Quantity'
    },
    {
        key: 'method',
        name: 'Method'
    },
    {
        key: 'adjustment_date',
        name: 'Adjustment Date'
    },
    {
        key: 'remark',//????
        name: "Remark"
    }
]

const GiftAdjustmentList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/presentAdjustment',
    });

    return (
        <TableLayout
            title='Gift Adjustment List'
            data={data}
            headers={headers}
            idKey='present_adjustment_id'
            endPoint='/presentAdjustment'
            setCurrentPage={setCurrentPage}
            removeDetails={true}
            hasSearch
            hasCreate
            hasActions
            createPath={`/loyalty/gift-adjustment/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}
 
export default GiftAdjustmentList;