import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';

export default function CreateCurrencyExchange() {
    const backPath = `/currency-exchange/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            base_currency: "Kyat",
            converted_currency: "Baht",
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('POST', '/currencyExchange', data, () => {
            navigate(backPath)
        });
    }

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Currency Exchange</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Thai Baht to Myanmar Kyat</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Rate</label>
                                <input 
                                    type='number'
                                    required
                                    step={"any"}
                                    name='exchange_rate'
                                    onChange={handleInputChange}
                                   
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Date Time</label>
                                <input type='datetime-local'
                                    required
                                    name='exchange_rate_date'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-divider'></div>
                        <h2 className='sub-title mb-8'>Myanmar Kyat to Thai Baht</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Rate</label>
                                <input type='number'
                                    required
                                   
                                    name='reverse_exchange_rate'
                                    onChange={handleInputChange}
                                    step={0.000001}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Date Time</label>
                                <input type='datetime-local'
                                    required
                                    name='reverse_exchange_rate_date'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}