const PromotionProductTable = ({ products, setProducts, title }) => {
    return (
        <div className='table-container'>
            <div className="mb-4"><h3 className="table-title">{title}</h3></div>
            <table className='x-table product-list-table'>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Barcode</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item, index) => (
                        <tr key={item.product_id}>
                            <td>{index + 1}</td>
                            <td>{item.barcode}</td>
                            <td>{item.product_name}</td>
                            <td>
                                <input id={`qty-${item.product_id}`}
                                    className='w-16 border border-solid border-neutral-300 rounded'
                                    min='1'
                                    value={item.quantity}
                                    onChange={e => {
                                        const quantity = e.target.value
                                        setProducts(prevData => {
                                            return prevData.map(product => (product.product_id === item.product_id ? { ...product, quantity } : product));
                                        });
                                    }}
                                    type="number"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>);
}

export default PromotionProductTable;