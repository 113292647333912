import React from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';

export default function EditUnit() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/unit/list`;
    const { handleInputChange, formData, submitForm, setError, setFormData, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('PUT', '/unit/' + id, data, () => {
            navigate(backPath)
        });
    }

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container'>
                <h1 className='sub-page-title'>Edit Unit</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Unit Name</label>
                                <input type='text'
                                    required
                                    defaultValue={initialData.unit_name}
                                    name='unit_name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    defaultValue={initialData.remark}
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow' style={{ display: 'flex' }}>
                                <input type='checkbox'
                                    name='is_base_unit'
                                    autoComplete='false'
                                    onChange={(e) => setFormData({ ...formData, is_base_unit: e.target.checked})}

                                    defaultChecked={initialData.is_base_unit}
                                    className='x-input-checkbox' />
                                <label className='x-label-checkbox'>Base Unit</label>
                            </div>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}