import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'warehouse_name',
        name: 'Warehouse Name'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'address',
        name: 'Address'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'warehouse_id',
        name: 'Warehouse ID'
    },
    {
        key: 'warehouse_name',
        name: 'Warehouse Name'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'phone2',
        name: 'Phone2'
    },
    {
        key: 'phone3',
        name: 'Phone 3'
    },
    {
        key: 'phone4',
        name: 'Phone 4'
    },
    {
        key: 'address',
        name: "Address"
    }
]


const WarehouseList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        setQuery({
            warehouse_name: value.trim()
        });
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/warehouse',
        defaultQuery: 'status=ACTIVE'
    });

    return (
        <TableLayout
            title='Warehouse List'
            data={data}
            headers={headers}
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTitle='Warehouse Information'
            idKey='warehouse_id'
            endPoint='/warehouse'
            setCurrentPage={setCurrentPage}
            hasDetails
            hasSearch
            hasCreate
            hasActions
            createPath={`/warehouse/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}

export default WarehouseList;