import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backArrowIcon from '../../assets/img/back-arrow.png';
import { formatDateToYYYYMMDD } from '../../utils/format-time';
import printIcon from '../../assets/icon/icon-print.png';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { truncateDecimal } from '../../utils/truncateDecimal';

const WholesaleReport = () => {
  const backPath = '/report/list';

  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
  const [data, setData] = useState([]);
  const [salePersons, setSalePersons] = useState();
  const [salePerson, setSalePerson] = useState();
  const [timeSpan, setTimeSpan] = useState("monthly");


  async function fetchWholeReport(startDate, endDate , salePerson, timeSpan) {
    let url = API_URL + `/report/wholesale_report?fromDate=${startDate}&toDate=${endDate}&cashier_id=${salePerson}&timespan=${timeSpan}`;
    axios
      .get(`${url}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Response - ", response.data);
        setData(response.data.data)
      })
      .catch((e) => {
        console.log(e.response);
      });
  }


  useEffect(() => {
    fetchWholeReport(startDate, endDate, salePerson, timeSpan)
  }, [startDate, endDate, timeSpan, salePerson]);

  useEffect(() => {
    axios.get(API_URL + '/user?status=ACTIVE', {
      withCredentials: true
    })
      .then(response => {
        setSalePersons(response.data.data);
        setSalePerson(response.data.data[0]?.user_id)
        fetchWholeReport(startDate,endDate , response.data.data[0].user_id,timeSpan)
      })
  }, []);

  console.log(salePerson)
  const handleExportClick = (fileName) => {
    const table = document.querySelector("#xlsx-export").cloneNode(true);
    const workbook = XLSX.utils.table_to_book(table, { display: false });
    XLSX.writeFile(workbook, fileName)
  };

  return (
    <div className='sub-page-layout-1'>
      <Link className='back-to-page' to={backPath}>
        <img src={backArrowIcon} />
        Back
      </Link>
      <div className='container' style={{ height: 'auto' }}>
        <div className="flex justify-between text-3xl">
          <h1 className='sub-page-title'>Wholesale Report</h1>

          <div className='flex'>
            <div className="flex">
              <div className='grow'>
                <div className='dd-container' style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <select
                    type='text'
                    required
                    name='transfer_from'
                    className='x-input dropdown'
                    value={salePerson}
                    onChange={e => setSalePerson(e.target.value)}
                  >
                    <option value="" disabled>Select</option>
                    {
                      salePersons && salePersons.map((item) => {
                        return (
                          <option value={item.user_id}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className='grow'>
                <div className='dd-container' style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <select
                    type='text'
                    required
                    name='transfer_from'
                    className='x-input dropdown'
                    value={timeSpan}
                    onChange={e => setTimeSpan(e.target.value)}
                  >
                    <option value="" disabled>Select</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex">
              <input
                required
                name="start_date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                className="x-input mr-3"
                type="date"
              />
              <input
                required
                name="end_date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                className="x-input mr-4"
                type="date"
              />
              <button style={{ backgroundColor: "gold" }} className="header-img-button" onClick={() => {
                handleExportClick(`Wholesale_Report_from_${startDate}_to_${endDate}.xlsx`)
              }}>
                <img src={printIcon} />
              </button>
            </div>
          </div>
        </div>
        <div className="table-container">
          <table className="x-table" id="xlsx-export">
            <thead>
              <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                {/* <th className="hidden" colSpan={5}></th> */}
                <th className="hidden">From</th>
                <th className="hidden">{startDate}</th>
                <th className="hidden">To</th>
                <th className="hidden">{endDate}</th>
              </tr>
              <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                <th className="hidden" colSpan={2}></th>
              </tr>
              <tr>
                <th>Date</th>
                <th>Voucher No</th>
                <th>MMK Total</th>
                <th>THB Total</th>
                <th>Advanced MMK</th>
                <th>Advanced THB</th>
                <th>MMK Discount</th>
                <th>THB Discount</th>
                <th>MMK Net Total</th>
                <th>THB Net Total</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                return (
                  <tr>
                    <td>{item["sale_date"]}</td>
                    <td>{item["voucher_code"]}</td>
                    <td>{truncateDecimal(item["sub_total_mmk"],5) || 0}</td>
                    <td>{truncateDecimal(item["sub_total_thb"],5) || 0}</td>
                    <td>{truncateDecimal (item["advanced_mmk"],5) || 0}</td>
                    <td>{truncateDecimal(item["advanced_thb"],5) || 0}</td>
                    <td>{truncateDecimal(item["discount_mmk"],5) || 0}</td>
                    <td>{truncateDecimal(item["discount_thb"],5) || 0}</td>
                    <td>{truncateDecimal(item["total_mmk"],5) || 0}</td>
                    <td>{truncateDecimal(item["total_thb"],5) || 0}</td>
                    
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WholesaleReport;