import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import { formatDateToYYYYMMDD } from '../../utils/format-time';

export default function EditRedeemedGift() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/loyalty/redeemed-gift/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('PUT', '/issuePresent/' + id, data, () => {
            navigate(backPath)
        });
    }

    const [presents, setPresents] = useState();
    const [presentRules, setPresentRules] = useState();
    const [retailCustomers, setRetailCustomers] = useState();
    const localData = JSON.parse(localStorage.getItem('user')) ?? {};
    useEffect(() => {
        axios.get(API_URL + '/present', {
            headers: {
                'Authorization': `Bearer ${localData?.token}`
            },
            withCredentials: true
        })
            .then(response => {
                setPresents(response.data.data)
            })
    }, []);

    useEffect(() => {
        axios.get(API_URL + '/presentRule', {
            headers: {
                'Authorization': `Bearer ${localData?.token}`
            },
            withCredentials: true
        })
            .then(response => {
                setPresentRules(response.data.data)
            })
    }, []);

    useEffect(() => {
        axios.get(API_URL + '/retailCustomer', {
            headers: {
                'Authorization': `Bearer ${localData?.token}`
            },
            withCredentials: true
        })
            .then(response => {
                setRetailCustomers(response.data.data)
            })
    }, []);

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Redeemed Gift</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Redeemed Gift Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Gift Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='present_id'
                                        required
                                        onChange={handleInputChange}
                                        value={formData.present_id ? formData.present_id : initialData.present_id}
                                        className='x-input dropdown'
                                    >
                                        {presents && presents.map((item, index) => (
                                            <option key={index} disabled={item.status !== 'ACTIVE'} value={item.present_id}>{item.present_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'></div>
                            <div className='grow'>
                                <label className='x-label input-required'>Gift Rule Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='present_rule_id'
                                        required
                                        onChange={handleInputChange}
                                        value={formData.present_rule_id ? formData.present_rule_id : initialData.present_rule_id}
                                        className='x-input dropdown'
                                    >
                                        {presentRules && presentRules.map((item, index) => (
                                            <option key={index} disabled={item.status !== 'ACTIVE'} value={item.present_rule_id}>{item.present_rule_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Quantity</label>
                                <input type='number'
                                    required
                                    name='quantity'
                                    defaultValue={initialData.qty}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Retail Customer Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='retail_customer_id'
                                        required
                                        onChange={handleInputChange}
                                        value={formData.retail_customer_id ? formData.retail_customer_id : initialData.retail_customer_id}
                                        className='x-input dropdown'
                                    >
                                        {retailCustomers && retailCustomers.map((item, index) => (
                                            <option key={index} disabled={item.status !== 'ACTIVE'} value={item.customer_id}>{item.customer_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Date</label>
                                <input type='date'
                                    required
                                    name='date'
                                    defaultValue={formatDateToYYYYMMDD(new Date(initialData.date))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}