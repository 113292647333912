import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../utils/constant';

function RoleDropdown({...rest}) {
  const [userRoles, setuserRoles] = useState([
    {
        role_id: '1',
        role_name: 'Super Admin'
    },
    {
        role_id: '2',
        role_name: 'Admin'
    },
    {
        role_id: '3',
        role_name: 'User'
    },
  ])

  useEffect(() => {
    const url = API_URL + '/role?status=ACTIVE'
    axios.get(url, { withCredentials: true })
    .then(response => {
        let temp = response.data.data;
        // if (user.role.user_role_name !== 'Super Admin')
        // {
        //   temp = temp.filter(item => item.user_role_name !== 'Admin' && item.user_role_name !== 'Super Admin')
        // }
        setuserRoles(temp)
    })
    .catch(e => {
        console.log(e)
    })
  }, [])
  

  return (
    <div className='dd-container'>
        <select 
        type='text' 
        value=''
        className='x-input dropdown'
        {...rest}
        >
            <option value=''>Select</option>
            {
                userRoles.map((item, index) => 
                (
                <option key={index} value={item.role_id}>
                    {item.role_name}
                </option>
                ))
            }
        </select>

    </div>
  )
}

export default RoleDropdown