import { Link } from "react-router-dom";
import Popup from "./Popup";
import { Pagination } from "./Pagination";
import { useEffect, useState } from "react";
import searchIcon from '../assets/img/icon-search.png';
import dotsIcon from '../assets/img/icondots.png';
import editIcon from '../assets/img/icon-edit.png';
import reloadIcon from '../assets/img/icon-reload.png';
import trashCanIcon from '../assets/img/icon-trash.png';
import detailsIcon from '../assets/img/icon-details.png';
import plusIcon from '../assets/img/icon-plus.png';
import printIcon from '../assets/img/icon-print-black.png';
import changeIcon from '../assets/img/exchange.png';

import { ResetPopup } from "./ResetPopup";
import axios from "axios";
import { API_URL } from "../utils/constant";
import { checkISO8601Format, formatDateToDDMMYY, formatDateToDDMMYYYYhmmA } from "../utils/format-time";
import PrintReceiptPopup from "./PrintReceiptPopup";
import PrintBarcodePopup from "./PrintBarcodePopup";
import ListDetailPopup from "./ListDetailPopup";
import DeleteConfirmPopup from "./DeleteConfirmPopup";
import PointChangePopup from "./PointChangePopup";

export default function TableLayout({
  idKey,
  endPoint,
  title,
  subtitle,
  data,
  headers,
  detailPopupTitle,
  detailPopupHeaders,
  hasFilter,
  filterType,
  setFilterType,
  hasSearch,
  hasCreate,
  hasReset,
  hasPrintBarcode,
  hasPointChange,
  hasPrintReceipt,
  createPath,
  hasActions,
  currentPath,
  disabledSerial,
  onSearch,
  removeEdit,
  createIcon,
  removeOptions,
  removePagination,
  removeDetails,
  setCurrentPage,
  createLabel,
  detailPopupTableKey,
  detailPopupTableHeaders,
  detailPopupTableTitle,
  reload,
  pointChangeModel
}) {

  const [popup, setPopup] = useState({
    id: '',
    show: false
  });

  const [optionOpen, setOptionOpen] = useState(false);
  const onCloseClick = (e) => {
    e.preventDefault();
    setPopup({
      id: '',
      show: false,
    });
  }

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll('.popup-options.open');
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle('open')
      setOptionOpen(optionEl.classList.contains('open'))
    }
  }

  const onResetClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'reset-popup',
      id: id
    })
  }

  const onDeleteClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'confirm-popup',
      id: id
    });
  }

  const onDetailClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'list-detail-popup',
      id: id
    });
  }

  const onPrintClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'print-popup',
      id: id
    });
  }

  const onPointChangeClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'point-change-popup',
      id: id
    });
  }

  const onPrintReceiptClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'print-receipt-popup',
      id: id
    });
  }

  useEffect(() => {
    const openedEl = document.querySelector('.popup-options.open');

    const handleDocumentClick = e => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
        openedEl.classList.remove('open')
        setOptionOpen(false)
      }
    }
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick)
      }, 100)

    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [optionOpen]);

  useEffect(() => {
    document.getElementById('external-popup').classList.toggle('show', popup.show);
    const openedEl = document.querySelector('.popup');

    const handleDocumentClick = e => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
        openedEl.parentNode.classList.remove('show')
        onCloseClick(e);
      }
    }
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick)
      }, 100)

    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [popup.show])

  const [results, setResults] = useState([data.results])


  const handleFilterOptionChange = (event) => {
    const selectedValue = event.target.value;
    setFilterType(selectedValue);
  };

  useEffect(() => {
    if (Array.isArray(data.results)) {
      setResults(data.results)

    } else {
      setResults(data.results[filterType])
    }
  }, [data, filterType])

  const deleteItem = async (e, id, path, onCloseClick) => {
    axios.delete(API_URL + path + '/' + `${filterType ? filterType + '/' : ''}` + id, {
      withCredentials: true
    })
      .then(response => {
        onCloseClick(e)
        if (response.status === 200) {
          reload();
        }
      })
  }

  return (
    <div className={subtitle || !title && !subtitle ? "" : "page-layout"}>
      <div className='page-header'>
        {subtitle ? (
          <h3 className="table-title">{subtitle}</h3>
        ) :
          (
            <h1>{title}</h1>
          )}

        {hasSearch && (
          <>
            <div className='search-bar'>
              <img alt="search icon" src={searchIcon} />
              <input className='x-input' type='search' onChange={(e) => onSearch(e.target.value)} />
            </div>
          </>
        )}

        {hasCreate && (
          <Link to={createPath} className='create-button'>
            {createIcon && (
              <img alt="icon" src={createIcon} />
            )}
            {createLabel || '+ Create New'}
          </Link>
        )}

      </div>
      {hasFilter && (
        <div className="mt-9 w-2/6">
          <label className='x-label input-required'>{hasFilter.filterLabel}</label>
          <div className='dd-container'>
            <select
              type='text'
              value={filterType}
              className='x-input dropdown'
              // {...rest}
              onChange={handleFilterOptionChange}
            >
              <option value=''>{hasFilter.filterPlaceholder}</option>
              {
                hasFilter.filterOptions.map((item, index) =>
                (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                ))
              }
            </select>
          </div>
        </div>
      )}
      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              {!disabledSerial && (<th className="serial-num">No</th>)}
              {headers.map((item, index) => (
                <th key={index}>{item.name}</th>
              ))}
              {hasActions && (
                <th className='button-col'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {
              results && results.map((item, i) => {
                const index = ((data.page - 1) * data.limit) + i + 1;
                return (
                  <tr key={i} className='hover-effect'>
                    {!disabledSerial && (<td className="serial-num">{index}</td>)}
                    {headers.map((header, index) => {
                      const value = item[header.parent]?.[header.key] || item[header.key]

                      return <td key={index}
                        style={{
                          color: header.key === 'status' ? item[header.key] === 'ACTIVE' ? '#149D52' : item[header.key] === 'Cancelled' ? '#FF0000' : '#0870D8' : ''
                        }}
                      >{checkISO8601Format(value) ? header.key === 'exchange_rate_date' ? formatDateToDDMMYYYYhmmA(new Date(value)) : formatDateToDDMMYY(new Date(value)) : value}</td>
                    })}
                    {!removeOptions &&
                      <td className='button-col'>
                        <img alt="dots icon" src={dotsIcon} onClick={() => toggleOptions(item[idKey])} />
                        <div className='popup-options' id={item[idKey]}>
                          {!removeEdit && (<Link to={`${currentPath}/${item[idKey]}`} state={{ initialData: item }}>
                            <img alt="edit icon" src={editIcon} />Edit
                          </Link>)}
                          {hasReset && (
                            <a href='' onClick={onResetClick}><img alt="reload icon" src={reloadIcon} />Reset Password</a>
                          )}
                          <a href='' onClick={onDeleteClick}><img alt="trash icon" src={trashCanIcon} />Delete</a>
                          {!removeDetails && (
                            <a href='' onClick={onDetailClick}><img alt="details icon" src={detailsIcon} />Detail</a>
                          )}
                          {hasPrintBarcode && (
                            <a href='' onClick={onPrintClick}><img alt="print icon" src={printIcon} />Print</a>
                          )}
                          {hasPointChange && (
                            <a href='' onClick={onPointChangeClick}><img alt="change icon" src={changeIcon} />Change</a>
                          )}
                          {hasPrintReceipt && (
                            <a href='' onClick={onPrintReceiptClick}><img alt="print icon" src={printIcon} />Print</a>
                          )}
                          {
                            title === 'Purchase Order List'
                            && <Link to='/purchase/purchase-arrival/list/create' state={{ purchaseOrderId: item.purchase_id, purchaseOrderNo: item.purchase_order }}>
                              <img alt="plus icon" src={plusIcon} />Purchase Arrival
                            </Link>
                          }
                        </div>
                      </td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      {
        !removePagination && <div className='pagination-container'>
          <Pagination data={data} setCurrentPage={setCurrentPage} />
        </div>
      }
      <div className='popup-container'>
      </div>
      <Popup className='popup-container'>
        {popup.eID === 'confirm-popup' && <DeleteConfirmPopup path={endPoint} id={popup.id} onConfirmClick={deleteItem} onCloseClick={onCloseClick} />}
        {popup.eID === 'reset-popup' && <ResetPopup setPopup={setPopup} popup={popup} />}
        {popup.eID === 'print-popup' && <PrintBarcodePopup data={results.find(item => item[idKey] === popup.id)} onCloseClick={onCloseClick} />}
        {popup.eID === 'point-change-popup' && <PointChangePopup modelName={pointChangeModel} data={results.find(item => item[idKey] === popup.id)} onCloseClick={onCloseClick} callback={() => {

          window.location.reload();

        }} />}
        {popup.eID === 'print-receipt-popup' && <PrintReceiptPopup data={results.find(item => item[idKey] === popup.id)} onCloseClick={onCloseClick} />}
        {popup.eID === 'list-detail-popup' && <ListDetailPopup detailData={results.find(item => item[idKey] === popup.id)} detailPopupTitle={detailPopupTitle} detailPopupHeaders={detailPopupHeaders} detailPopupTableTitle={detailPopupTableTitle} detailPopupTableHeaders={detailPopupTableHeaders} detailPopupTableKey={detailPopupTableKey} onCloseClick={onCloseClick} />}
      </Popup>
    </div>
  );
}




