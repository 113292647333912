import axios from "axios";
import { useState } from "react";
import { API_URL } from "../utils/constant";
import { useAuth } from "../routers/AuthProvider";


export const useForm = (initData = {}) => {
    const {setLoading, user, setUser} = useAuth();
	const [formData, setFormData] = useState(initData);
    const [error, setError] = useState('');

	const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const fieldChange = (field) => {
        return value => {
            setFormData(old => ({
                ...old,
                [field]: value
            }))
        }
    }
    
    const initializeForm = (values) => {
        setFormData(values);
    };

	const submitForm = async (method, endpoint, data, callback) => {
        console.log('form request payload - --------- ', data)
        try {
            const response = await axios.request({
                method,
                withCredentials: true,
                url: `${API_URL}${endpoint}`,
                data,
            });
            setLoading(false)
            callback(response)
        }
        catch(e) {
            console.log(e)
            setLoading(false)
            if (e.response && e.response.data && e.response.data.message && e.response.status != 500)
            {
                if (e.response.data && e.response.data.key === 'unique violation')
                {
                    setError('Duplicated Field!')
                }
                else {
                    setError(e.response.data.message)
                }

            }
            else {
                console.log('Server Error!', e.response)
                setError('Something went wrong!')
            }
        };
	}

    return {
        handleInputChange,
        initializeForm,
        fieldChange,
        formData, 
        setFormData,
        submitForm,
        setError,
        error, 
    }

}

