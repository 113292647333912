import axios from "axios"
import { useEffect, useState } from "react"


export const useFetchData = ({ url, defaultQuery, sort = '-created_date' }) => {
    const limit = 10
    const [data, setdata] = useState({
        results: [],
        page: 1,
        limit,
        totalCount: 0,
        query: {
            // name: 'Aung',
            // status: 'active'
        },
        sort: sort,
        reloadTrigger: false
    })

    const setCurrentPage = (page) => {
        const totalPage = Math.ceil(data.totalCount / limit);
        page = page < 1 ? 1 : page;
        page = page > totalPage ? totalPage : page;

        setdata({
            ...data,
            page
        })
    }

    useEffect(() => {
        let query = [];
        query.push(defaultQuery)
        for (const key in data.query) {
            query.push(`${key}=${data.query[key]}`)
        }

        query = query.join('&');

        axios.get(`${url}?page=${data.page}&limit=${data.limit}&sort=${sort}&included=true&${query}`, {
            withCredentials: true
        })
            .then(response => {
                setdata({
                    ...data,
                    results: response.data.data,
                    totalCount: response.data.totalCount
                })
            })
            .catch(e => {
                console.log(e.response)
            })
    }, [data.page, data.limit, data.query, data.reloadTrigger])

    const setQuery = (query) => {
        for (const key in query) {
            if (!query[key]) {
                delete query[key]
            }
        }

        setdata({
            ...data,
            page: 1,
            query
        })
    }

    const reload = () => {
        setdata({
            ...data,
            reloadTrigger: !data.reloadTrigger
        })
    }

    return {
        data,
        setCurrentPage,
        setQuery,
        reload
    }
}



