import { useLocation } from "react-router";
import TableLayout from "../../components/TableLayout";
import { API_URL } from "../../utils/constant";
import { useFetchData } from "../../hooks/useFetchData";

const headers = [
    {
        key: 'supplier_name',
        name: 'Name'
    },
    {
        key: 'email',
        name: 'Email'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'supplier_id',
        name: 'Supplier ID'
    },
    {
        key: 'supplier_name',
        name: 'Supplier Name'
    },
    {
        key: 'email',
        name: 'Supplier Email'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'phone2',
        name: 'Phone2'
    },
    {
        key: 'phone3',
        name: 'Phone 3'
    },
    {
        key: 'phone4',
        name: 'Phone 4'
    },
    {
        key: 'address',
        name: "Address"
    }
]

export default function SupplierList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const {data, setCurrentPage, setQuery, reload} = useFetchData({
        url: API_URL + '/supplier',
        defaultQuery: 'status=ACTIVE'
    });

    const handleSearch = (value) => {
        console.log('searching value: ',value);
        setQuery({
            supplier_name: value.trim(),
        });
    }

    return (
        <TableLayout
            title="Supplier List"
            data={data}
            headers={headers}
            detailPopupTitle='Supplier Information'
            detailPopupHeaders={detailPopupHeaders}
            idKey='supplier_id'
            endPoint='/supplier'
            setCurrentPage={setCurrentPage}
            hasSearch
            hasCreate
            createPath={`/supplier/create`}
            hasActions
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            reload={reload}
        />
    );
}