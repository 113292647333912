import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';
import { useState } from 'react';

const headers = [
    {
        key: 'promotion_type',
        name: 'Promotion Type'
    },
    {
        key: 'start_date',
        name: 'Start Date'
    },
    {
        key: 'end_date',
        name: 'End Date'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = {
    amount: [
        {
            key: 'minimum_total_amount',
            name: 'Minimum Total Amount'
        },
        {
            key: 'promotion_amount',
            name: 'Promotion Amount'
        },
        {
            key: 'currency_type',
            name: 'Currency Type'
        },
        {
            key: 'start_date',
            name: 'Start Date'
        },
        {
            key: 'end_date',
            name: 'End Date'
        },
    ],
    percent: [
        {
            key: 'product_name',
            name: 'Product Name',
            parent: 'product'
        },
        {
            key: 'percent_amount',
            name: 'Percent Amount'
        },
        {
            key: 'start_date',
            name: 'Start Date'
        },
        {
            key: 'end_date',
            name: 'End Date'
        },
    ],
    cross_product: [
        {
            key: 'crossableProducts',
            name: 'Buy Products'
        },
        {
            key: 'promoProducts',
            name: 'Get Products'
        },
        {
            key: 'start_date',
            name: 'Start Date'
        },
        {
            key: 'end_date',
            name: 'End Date'
        },
    ],
    bogo: [
        {
            key: 'product_name',
            name: 'Product Name',
            parent: 'product'
        },
        {
            key: 'start_date',
            name: 'Start Date'
        },
        {
            key: 'end_date',
            name: 'End Date'
        },
    ]
}

const filterData = {
    toFilter: 'promotionType',
    filterLabel: 'Promotion Type',
    filterPlaceholder: 'Select Promotion Type',
    filterOptions: [
        { name: 'By Total Amount', value: 'amount' },
        { name: 'By Bogos', value: 'bogo' },
        { name: 'By Percents', value: 'percent' },
        { name: 'By Cross Products', value: 'cross_product' }
    ],
}

const PromotionList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ', value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/promotion',
        defaultQuery: 'status=ACTIVE'
    });

    const [filterType, setFilterType] = useState('amount')
    console.log(filterType)

    return (
        // <div>
            <TableLayout
                title='Promotion List'
                data={data}
                headers={headers}
                detailPopupHeaders={detailPopupHeaders[filterType]}
                idKey={`promotion_by_${filterType === 'cross_product' ? 'cp' : filterType}_id`}
                endPoint='/promotion'
                removePagination={true}
                hasSearch
                setFilterType={setFilterType}
                filterType={filterType}
                hasFilter={filterData}
                hasCreate
                hasActions
                removeEdit={true}
                createPath={`/promotion/list/create`}
                currentPath={currentPath}
                onSearch={(value) => handleSearch(value)}
                disabledSerial
                reload={reload}
            />
        // </div>
    )
}

export default PromotionList;