import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../routers/AuthProvider';
import { useForm } from '../../hooks/useForm';
import backArrowIcon from '../../assets/img/back-arrow.png';
import '../../assets/css/common.css';

const CreateLoyalty = () => {
    const backPath = '/loyalty/list';
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();

    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const data = {
            ...formData,
            status: 'ACTIVE'
        }
        submitForm('POST', '/loyalty', data, () => {
            navigate(backPath)
        });
    }

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create Loyalty</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Loyalty Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Loyalty Name</label>
                                <input type='text'
                                    required
                                    name='loyalty_name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Loyalty Type</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='loyalty_type'
                                        defaultValue=''
                                        onChange={handleInputChange}
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        <option value='retail sale'>Loyalty for Retail Sale</option>
                                        <option value='wholesale'>Loyalty for Wholeale</option>
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Amount</label>
                                <input type='number'
                                    required
                                    name='amount'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Point</label>
                                <input type='number'
                                    required
                                    name='point'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Start Date</label>
                                <input type='date'
                                    required
                                    name='start_date'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>End Date</label>
                                <input type='date'
                                    required
                                    name='end_date'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='mt-8 form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateLoyalty;