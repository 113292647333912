import { useEffect } from "react";
import plusIcon from '../assets/img/icon-plus.png';

const WholesaleOrderTable = ({ data, currencyType, THBToMMK, MMKToTHB, setSubTotal, setSaleProducts , setSubTotalMMK, setSubTotalTHB }) => {
    const getSubTotal = (currencyType, seperatedType) => {
        let total = 0
        if (currencyType === "MMK") {
            data.forEach(product => {
                total += parseFloat(product.mmkTotal ? product.mmkTotal : product.mmkPrice)
            });
        }

        if (currencyType === "THB") {
            data.forEach(product => {
                total += parseFloat(product.thbTotal ? product.thbTotal : product.thbPrice)
            });
        }
        if (currencyType === "SEPERATED" && seperatedType === "MMK") {
            data.forEach(product => {
                total += parseFloat(product.convertedMMKTotal)
            });
        }

        if (currencyType === "SEPERATED" && seperatedType === "THB") {
            data.forEach(product => {
                total += parseFloat(product.convertedTHBTotal)
            });
        }
        total = parseFloat((total).toFixed(2))
        return total
    }

    
    useEffect(() => {
        
        if (currencyType === 'SEPERATED') {
            setSubTotalMMK(getSubTotal(currencyType, 'MMK'));
            setSubTotalTHB(getSubTotal(currencyType,'THB'));
        } else {
            setSubTotal(getSubTotal(currencyType));
        }
    }, [data, currencyType])

    return (
        <div className='table-container'>
            <div className="mb-4 flex justify-between">
                <h3 className="table-title">Wholesale Order Information</h3>
            </div>
            <table className='x-table product-list-table'>
                <thead>
                    <tr>
                        <th>Barcode</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>MMK Price</th>
                        <th>THB Price</th>
                        <th>MMK Amount</th>
                        <th>THB Amount</th>
                        <th className="button-col"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        return (
                            <tr key={item.product_id}>
                                <td>{item.barcode}</td>
                                <td>{item.product_name}</td>
                                <td>
                                    <input id={`qty-${item.product_id}`}
                                        tabIndex={2}
                                        className="w-16 border border-solid border-neutral-300 rounded"
                                        min='1'
                                        defaultValue={item.quantity ? item.quantity : '1'}
                                        type="number"
                                        onChange={(e) => {
                                            const quantity = e.target.value;
                                            const mmkTotal = (quantity * item.mmkPrice).toFixed(2);
                                            const thbTotal = (quantity * item.thbPrice).toFixed(2);
                                            const convertedMMKTotal = item.productPrice.currency === "MMK" ? mmkTotal : 0
                                            const convertedTHBTotal =  item.productPrice.currency === "BAHT" ? thbTotal : 0

                                            setSaleProducts(prevData => {
                                                return prevData.map(product => (product.product_id === item.product_id ? { ...product, quantity, mmkTotal, thbTotal, convertedMMKTotal, convertedTHBTotal } : product));
                                            });

                                            setSubTotal(getSubTotal(currencyType));
                                        }}
                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`MMK-price-${item.product_id}`}
                                        className="w-16"
                                        defaultValue={item.mmkPrice} />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`THB-price-${item.product_id}`}
                                        className="w-16"
                                        defaultValue={item.thbPrice} />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`MMK-total-${item.product_id}`}
                                        className="w-16"
                                        value={item.convertedMMKTotal}

                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        readOnly={true}
                                        disabled={true}
                                        id={`THB-total-${item.product_id}`}
                                        className="w-16"
                                        value={item.convertedTHBTotal}
                                    />
                                </td>

                                <td className="button-col" id={item.product_id} onClick={(e) => {
                                    const filterItem = data.filter(item => item.product_id !== e.target.parentNode.id)
                                    setSaleProducts(filterItem)
                                }}><img src={plusIcon} alt="close icon" className="rotate-45 w-5 h-5" /></td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>)
}

export default WholesaleOrderTable

