import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backArrowIcon from '../../assets/img/back-arrow.png';
import { formatDateToYYYYMMDD } from '../../utils/format-time';
import printIcon from '../../assets/icon/icon-print.png';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import * as XLSX from 'xlsx';

  
const StockAdjustmentReport = () => {
    const backPath = '/report/list';

    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
    const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
    const [data, setData] = useState([]);

    useEffect(() => {
        let url = API_URL + `/report/stock_adjustments?fromDate=${startDate}&toDate=${endDate}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData(response.data.data)
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [startDate, endDate]);

    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <div className="flex justify-between text-3xl">
                    <h1 className='sub-page-title'>Stock Adjustment Report</h1>

                    <div className="flex">
                        <input
                            required
                            name="start_date"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                            className="x-input mr-3"
                            type="date"
                        />
                        <input
                            required
                            name="end_date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            className="x-input mr-4"
                            type="date"
                        />
                        <button style={{ backgroundColor: "gold" }} className="header-img-button" onClick={() => {
                            handleExportClick(`Stock_Adjustment_Report_from_${startDate}_to_${endDate}.xlsx`)
                        }}>
                            <img src={printIcon} />
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="x-table" id="xlsx-export">
                        <thead>
                            <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                                <th className="hidden" colSpan={1}></th>
                                <th className="hidden">From</th>
                                <th className="hidden">{startDate}</th>
                                <th className="hidden">To</th>
                                <th className="hidden">{endDate}</th>
                            </tr>
                            <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                                <th className="hidden" colSpan={5}></th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>Product Name</th>
                                <th>Barcode</th>
                                <th>Qty</th>
                                <th>Adjustment Method</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item["date"]}</td>
                                        <td>{item["product_name"]}</td>
                                        <td>{item["barcode"]}</td>
                                        <td>{item["qty"]}</td>
                                        <td>{item["adjustment_method"]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default StockAdjustmentReport;