import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../routers/AuthProvider';
import { useForm } from '../../hooks/useForm';
import backArrowIcon from '../../assets/img/back-arrow.png';
import '../../assets/css/common.css';
import { API_URL } from '../../utils/constant';
import axios from 'axios';

const CreateWarehouse = () => {
    const backPath = '/warehouse/list';
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const data = {
            ...formData,
            status: 'ACTIVE'
        }
        submitForm('POST', '/warehouse', data, () => {
            navigate(backPath)
        });
    }

    const [address, setAddress] = useState();
    useEffect(() => {
        axios.get(API_URL + '/common/address', {
            withCredentials: true
        })
            .then(response => {
                setAddress(response.data.data)
            })
    }, []);

    useEffect(() => {
        const elem = document.querySelector('select[name="city_id"].x-input.dropdown');
        const value = elem?.children[1]?.value;
        setFormData({ ...formData, city_id: value })
    }, [formData.district_id]);

    useEffect(() => {
        const elem = document.querySelector('select[name="township_id"].x-input.dropdown');
        const value = elem?.children[1]?.value;
        setFormData({ ...formData, township_id: value })
    }, [formData.city_id]);

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Warehouse</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Warehouse Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Warehouse Name</label>
                                <input type='text'
                                    required
                                    name='warehouse_name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'></div>
                            <div className='grow'>
                                <label className='x-label input-required'>Phone 1</label>
                                <input type='text'
                                    required
                                    name='phone1'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Phone 2</label>
                                <input type='text'
                                    name='phone2'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Phone 3</label>
                                <input type='text'
                                    name='phone3'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Phone 4</label>
                                <input type='text'
                                    name='phone4'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-divider'></div>
                        <h2 className='sub-title mb-8'>Other Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label'>State</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='district_id'
                                        onChange={handleInputChange}
                                        defaultValue=''
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {address && address.district.map((item, index) => (
                                            <option key={index} value={item.district_id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label'>City</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='city_id'
                                        onChange={handleInputChange}
                                        defaultValue=''
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {address && address.city.filter(item => item.district_id === parseInt(formData.district_id)).map((item, index) => (
                                            <option key={index} value={item.city_id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Township</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='township_id'
                                        onChange={handleInputChange}
                                        defaultValue={formData.township_id ? formData.township_id : ''}
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {address && address.township.filter(item => item.city_id === parseInt(formData.city_id)).map((item, index) => {
                                            return <option key={index} value={item.township_id}>{item.name}</option>

                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Address</label>
                                <input type='text'
                                    required
                                    name='address'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='mt-8 form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
}

export default CreateWarehouse;