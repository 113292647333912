import PasswordInput from './PasswordInput';

const { useState } = require("react");
const { useForm } = require("../hooks/useForm");


export const ResetPopup = ({ setPopup, popup }) => {
  const [eyes, seteyes] = useState([false, false])

  const { handleInputChange, formData, submitForm, setError, error } = useForm();

  const onSubmit = e => {
    e.preventDefault();
    console.log("reset")
    console.log(formData)

    console.log(popup)

    if (formData.new_password !== formData.confirm_password) {
      setError('passwords not match')
    }

    const data = {
      password: formData.confirm_password,
      status: 'ACTIVE'
    }
    submitForm('PUT', '/user/' + popup.id, data, () => {
      console.log("SUCCESS")
      setPopup({
        id: '',
        eID: '',
        show: false
      })
      // navigate(backPath)
    });
  }

  const onCloseClick = e => {
    e.preventDefault();
    setPopup({
      id: '',
      eID: '',
      show: false
    })
  }

  return (

    <form onSubmit={onSubmit} className='popup w-full'
      style={{
        maxWidth: '600px ',
        padding: '50px 60px 68px 60px '
      }}>
      <h4 className='text-start'>Reset Password</h4>
      <div className='w-full mt-10'>
        <label className='input-required'>New Password</label>
        <PasswordInput
          name='new_password'
          required
          onChange={handleInputChange}
        />
        <label className='input-required'>Confirm Password</label>
        <PasswordInput
          name='confirm_password'
          required
          onChange={handleInputChange}
        />
      </div>

      <div className=' mt-14'>
        {error ? <span className='w-full block text-lg text-red-500 text-center mb-4'>{error}</span> : ''}
        <div className='flex justify-center gap-5'>
          <button onClick={onCloseClick} className='white-btn'>Cancel</button>
          <button type='submit' className='gold-btn'>Reset</button>
        </div>
      </div>
    </form>
  )
}

