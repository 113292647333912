import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import InvoiceDetails from '../../components/InvoiceDetails';
import WholesaleOrderTable from '../../components/WholesaleOrderTable';
import AsyncSelect from 'react-select/async';
import { API_URL } from '../../utils/constant';
import { useReactToPrint } from 'react-to-print';
import Receipt from '../../components/Receipt';
import axios from 'axios';
import moment from 'moment';
import { truncateDecimal } from '../../utils/truncateDecimal';

export default function CreateWholesaleOrder() {
    const backPath = `/sale/wholesale-order/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];

    const { user, setLoading } = useAuth();
    const [saleProducts, setSaleProducts] = useState([]);
    const [currencyType, setCurrencyType] = useState('MMK');
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [taxEnabled, setTaxEnabled] = useState(0);
    const [tax, setTax] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [change, setChange] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [saleDate, setSaleDate] = useState(today);
    const [paidAmount, setPaidAmount] = useState(0);
    const [loyaltyPoint, setLoyaltyPoint] = useState(0);
    const [memberCode, setMemberCode] = useState("");
    const [voucherCode, setVoucherCode] = useState("");
    const [remark, setRemark] = useState();
    const isInitialMount = useRef(true);
    const [customer, setCustomer] = useState({});
    const [mmkTotal, setMmkTotal] = useState(0);
    const [thbTotal, setThbTotal] = useState(0);

    const [totalInMMK, setTotalInMMK] = useState(0);
    const [totalInTHB, setTotalInTHB] = useState(0);
    const [subTotalMMK, setSubTotalMMK] = useState(0);
    const [subTotalTHB, setSubTotalTHB] = useState(0);
    const [advancedInMMK, setAdvancedInMMK] = useState(0);
    const [advancedInTHB, setAdvancedInTHB] = useState(0);
    const [advancedEnabled, setAdvancedEnabled] = useState(0);
    const [taxAmountInMMK, setTaxAmountInMMK] = useState(0);
    const [taxAmountInTHB, setTaxAmountInTHB] = useState(0);
    const [changeInMMK, setChangeInMMK] = useState(0);
    const [changeInTHB, setChangeInTHB] = useState(0);
    const [discountInMMK, setDiscountInMMK] = useState(0);
    const [discountInTHB, setDiscountInTHB] = useState(0);
    const [paidAmountInMMK, setPaidAmountInMMK] = useState(0);
    const [paidAmountInTHB, setPaidAmountInTHB] = useState(0);
    const [promotionAmount, setPromotionAmount] = useState(0);
    const [promotionAmountInMMK, setPromotionAmountInMMK] = useState(0);
    const [promotionAmountInTHB, setPromotionAmountInTHB] = useState(0);


    useEffect(() => {
        let mmkInit = 0;
        let thbInit = 0;
        setMmkTotal(mmkInit)
        setThbTotal(thbInit)
        saleProducts.map((product) => {
            if (currencyType === "MMK") {
                mmkInit = mmkInit + Number(product.mmkTotal)
                setMmkTotal(mmkInit);
            } else {
                thbInit = thbInit + Number(product.thbTotal)
                setThbTotal(thbInit);
            }
        });
    }, [saleProducts, currencyType]);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(e.target)
        setLoading(true)
        const items = getSaleItems(saleProducts);
        const data = {
            items,
            sale_date: saleDate,
            wholesale_customer_id: customer?.customer_id ?? 0,//need to fix this field should not be mandatory
            // wholesale_customer_id: memberCode,
            remark,
            currency_type: currencyType,
            sub_total: subTotal,
            discount,
            tax,
            tax_amount: taxAmount,
            paid_amount: paidAmount,
            change,
            total,
            status: 'ACTIVE',
            loyalty: loyaltyPoint,
            rate:  exchangeRate,
            mmk_total: mmkTotal,
            thb_total: thbTotal,
            
            discount_mmk : discountInMMK,
            discount_thb : discountInTHB,
            sub_total_mmk : subTotalMMK,
            sub_total_thb : subTotalTHB,
            promotion_amount_mmk : promotionAmountInMMK,
            promotion_amount_thb : promotionAmountInTHB,
            tax_mmk : taxAmountInMMK,
            tax_thb : taxAmountInTHB,
            advanced_mmk : advancedInMMK,
            advanced_thb : advancedInTHB,
            paid_amount_mmk : paidAmountInMMK,
            paid_amount_thb : paidAmountInTHB,
            change_mmk : changeInMMK,
            change_thb : changeInTHB,
            total_mmk : totalInMMK,
            total_thb : totalInTHB,
            advanced_enabled : advancedEnabled,
            loyalty_amount : loyaltyRule ? loyaltyRule.amount : 0,
        }

        submitForm('POST', '/wholesale', data,
            (response) => {
                setVoucherCode(response.data.voucher_code)
            })
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handlePrint();
        }
    }, [voucherCode])

    const getSaleItems = (products) => {
        const result = products.map(product => ({
            product_id: product.product_id,
            barcode: product.barcode,
            quantity: product.quantity,
            price: currencyType === "MMK" ? product.mmkPrice : product.thbPrice,
            sub_total: currencyType === "MMK" ? product.mmkTotal : product.thbTotal,
            total: currencyType === "MMK" ? product.mmkTotal : product.mmkTotal
        }));
        return result;
    }

    const getPrintItems = (products) => {
        const result = products.map(product => ({
            description: product.product_name,
            quantity: product.quantity,
            price: currencyType === "MMK" ? product.mmkPrice : product.thbPrice,
            total: currencyType === "MMK" ? product.mmkTotal : product.mmkTotal,
            barcode: product.barcode
        }));
        return result;
    }

    const loadOptions = inputValue => {
        return axios.get(API_URL + '/product?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                return response.data.data.filter(option =>
                    option.product_name.toLowerCase().includes(inputValue.toLowerCase())
                );
            });
    };

    const [exchangeRate, setExchangeRate] = useState();
    const [reverseExchangeRate, setReverseExchangeRate] = useState();

    useEffect(() => {
        axios.get(API_URL + '/currencyExchange?limit=1&page=1&sort=-created_date', {
            withCredentials: true
        })
            .then(response => {
                setExchangeRate(response.data.data[0].exchange_rate)
                setReverseExchangeRate(response.data.data[0].reverse_exchange_rate)
            })
    }, []);

    useEffect(() => {
        if (taxEnabled) {
            setTaxAmount(parseFloat(((tax / 100) * subTotal).toFixed(2)));
        } else {
            setTaxAmount(0);
        }
    }, [tax, subTotal, currencyType, taxEnabled]);

    useEffect(() => {
        setTotal((subTotal + taxAmount - discount).toFixed(2));
    }, [subTotal, discount, taxAmount, currencyType]);

    useEffect(() => {
        if (subTotal >= loyaltyRule?.amount) {
            setLoyaltyPoint(loyaltyRule?.point);
        }
    }, [subTotal])

    useEffect(() => {
        if (taxEnabled && currencyType === "SEPERATED") {
            setTaxAmountInMMK(parseFloat(((tax / 100) * subTotalMMK).toFixed(2)));
        } else {
            setTaxAmountInMMK(0);
        }
    }, [tax, subTotalMMK, taxEnabled])

    useEffect(() => {
        if (taxEnabled && currencyType === "SEPERATED") {
            setTaxAmountInTHB(parseFloat(((tax / 100) * subTotalTHB).toFixed(2)));
        } else {
            setTaxAmountInMMK(0);
        }
    }, [tax, subTotalTHB, taxEnabled])

    useEffect(() => {
        const subTotalVal = parseFloat(subTotal) || 0;
        const taxAmountVal = parseFloat(taxAmount) || 0;
        const discountVal = parseFloat(discount) || 0;
        const promotionAmountVal = parseFloat(promotionAmount) || 0;
        const advancedInMMKVal = parseFloat(advancedInMMK) || 0;
        const advancedInTHBVal = parseFloat(advancedInTHB) || 0;
        let totalValue = subTotalVal + taxAmountVal - discountVal - promotionAmountVal;

        if (advancedEnabled && currencyType === "MMK") {
            totalValue -= advancedInMMKVal
        } else if (advancedEnabled && currencyType === "THB") {
            totalValue -= advancedInTHBVal
        }
        setTotal(truncateDecimal(totalValue, 5))
    }, [subTotal, discount, taxAmount, promotionAmount, advancedEnabled, advancedInMMK, advancedInTHB]);


    useEffect(() => {
        const subTotalMMKVal = parseFloat(subTotalMMK) || 0;
        const taxAmountInMMKVal = parseFloat(taxAmountInMMK) || 0;
        const discountInMMKVal = parseFloat(discountInMMK) || 0;
        const promotionAmountInMMKVal = parseFloat(promotionAmountInMMK) || 0;
        const advancedInMMKVal = parseFloat(advancedInMMK) || 0;
        let totalValue = subTotalMMKVal + taxAmountInMMKVal - discountInMMKVal - promotionAmountInMMKVal
        if (advancedEnabled) {
            totalValue -= advancedInMMKVal
        }
        setTotalInMMK(truncateDecimal(totalValue, 5))
    }, [subTotalMMK, discountInMMK, , taxAmountInMMK, promotionAmountInMMK, advancedInMMK, advancedEnabled]);



    useEffect(() => {
        const subTotalTHBVal = parseFloat(subTotalTHB) || 0;
        const taxAmountInTHBVal = parseFloat(taxAmountInTHB) || 0;
        const discountInTHBVal = parseFloat(discountInTHB) || 0;
        const promotionAmountInTHBVal = parseFloat(promotionAmountInTHB) || 0;
        const advancedInTHBVal = parseFloat(advancedInTHB) || 0;
        let totalValue = subTotalTHBVal + taxAmountInTHBVal - discountInTHBVal - promotionAmountInTHBVal
        if (advancedEnabled) {
            totalValue -= advancedInTHBVal
        }
        setTotalInTHB(truncateDecimal(totalValue, 5))
    }, [subTotalTHB, discountInTHB, , taxAmountInTHB, promotionAmountInTHB, advancedInTHB, advancedEnabled]);

    useEffect(() => {
        const paidAmountVal = parseFloat(paidAmount) || 0;
        const totalVal = parseFloat(total) || 0;
        let changeValue = paidAmountVal - totalVal;
        setChange(truncateDecimal(changeValue,5))
    }, [paidAmount, total])

    useEffect(() => {
        const totalInMMKVal = parseFloat(totalInMMK) || 0;
        const paidAmountInMMKVal = parseFloat(paidAmountInMMK) || 0;
        let changeInMMK = paidAmountInMMKVal - totalInMMKVal;
        setChangeInMMK(truncateDecimal(changeInMMK,5))
    }, [paidAmountInMMK, totalInMMK])

    useEffect(() => {
        const totalInTHBVal = parseFloat(totalInTHB) || 0;
        const paidAmountInTHBVal = parseFloat(paidAmountInTHB) || 0;
        let changeInTHB = paidAmountInTHBVal - totalInTHBVal ;
        setChangeInTHB(truncateDecimal(changeInTHB,5))
    }, [paidAmountInTHB, totalInTHB])

    const printRef = useRef();
    const pageStyle = `
    @media print {
        @page {
        size: 70mm 250mm;
        margin: 0 0.3in 0 0;
        }

        html, body {
            height: auto;
            font-size: 12pt; /* changing to 10pt has no impact */
        }
    }`;

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => navigate(backPath),
        pageStyle: pageStyle
    });

    const barcodeRef = useRef(null)
    useEffect(() => {
        barcodeRef.current.focus();
    }, [])

    const [loyaltyRule, setLoyaltyRule] = useState();

    useEffect(() => {
        axios.get(API_URL + `/loyalty?status=ACTIVE&loyalty_type=wholesale&sort=-created_date`, {
            withCredentials: true
        }).then(response => {
            if (response.data.data.length) {
                const res = response.data.data[0];
                const startDate = moment(res.start_date, 'YYYY-MM-DD HH:mm:ss').startOf('day');
                const endDate = moment(res.end_date, 'YYYY-MM-DD HH:mm:ss').endOf('day');
                const now = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
                if (now > startDate && now < endDate) {
                    setLoyaltyRule(res);
                }
            }
        })

        document.querySelector(".side-nav").style.display = "none"
        document.querySelector(".sub-layout").style.paddingLeft = "0px"
    }, [])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Wholesale Order</h1>
                <div className='form-container'>
                    <form className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>
                            {/* <div className='grow'>
                                <label className='x-label input-required'>Product Name</label>
                                <AsyncSelect
                                    defaultOptions
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    isSearchable={true}
                                    isMulti={true}
                                    getOptionLabel={option => option.product_name}
                                    getOptionValue={option => option.product_id}
                                    value={saleProducts}
                                    onChange={option => {
                                        const newObj = option.map(item => {
                                            const mmkPrice = item.mmkPrice ? item.mmkPrice : convertCurrency(item, "MMK", exchangeRate, reverseExchangeRate)
                                            const thbPrice = item.thbPrice ? item.thbPrice : convertCurrency(item, "BAHT", exchangeRate, reverseExchangeRate)
                                            const quantity = item.quantity ? item.quantity : 1
                                            const mmkTotal = item.mmkTotal ? item.mmkTotal : mmkPrice
                                            const thbTotal = item.thbTotal ? item.thbTotal : thbPrice
                                            return { ...item, quantity, mmkPrice, thbPrice, mmkTotal, thbTotal }
                                        })
                                        setSaleProducts(newObj)
                                    }}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: '#1b1b1b66',
                                            minHeight: '50px'
                                        }),
                                    }}
                                />
                            </div> */}
                            <div className='grow'>
                                <label className='x-label'>Barcode</label>
                                <input type='text'
                                    tabIndex={1}
                                    ref={barcodeRef}
                                    onKeyDown={e => {
                                        e.target.nextElementSibling.classList.remove('block')
                                        e.target.nextElementSibling.classList.add('hidden')
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            const url = API_URL + `/product?barcode=${e.target.value}`
                                            if (saleProducts.some(item => item.barcode === e.target.value)) {
                                                e.target.value = ""
                                                return;
                                            }
                                            axios.get(url, {
                                                withCredentials: true
                                            }).then(response => {
                                                e.target.value = ""
                                                if (response.data.length === 0) {
                                                    console.log("PRODUCT NOT FOUND")
                                                    e.target.nextElementSibling.classList.remove('hidden')
                                                    e.target.nextElementSibling.classList.add('block')
                                                } else {
                                                    const newItem = response.data.data[0];
                                                    const existing = saleProducts.filter(v => v.barcode == newItem.barcode);

                                                    if (existing.length) {

                                                        setSaleProducts(prevData => {
                                                            return prevData.map(product => (product.product_id === existing[0].product_id ?
                                                                {
                                                                    ...product,
                                                                    quantity: parseInt(product.quantity) + 1,
                                                                    mmkTotal: (parseInt(product.quantity) + 1) * product.mmkPrice,
                                                                    thbTotal: (parseInt(product.quantity) + 1) * product.thbPrice,
                                                                    convertedMMKTotal: product.productPrice.currency === "MMK" ? (parseInt(product.quantity) + 1) * product.mmkPrice : 0,
                                                                    convertedTHBTotal: product.productPrice.currency === "BAHT" ? (parseInt(product.quantity) + 1) * product.thbPrice : 0
                                                                } :
                                                                product));
                                                        });
                                                    } else {
                                                        const mmkPrice = convertCurrency(newItem, "MMK", exchangeRate, reverseExchangeRate)
                                                        const thbPrice = convertCurrency(newItem, "BAHT", exchangeRate, reverseExchangeRate)
                                                        const quantity = 1;
                                                        const mmkTotal = mmkPrice
                                                        const thbTotal = thbPrice
                                                        const convertedMMKTotal = newItem.productPrice.currency === "MMK" ? mmkPrice : 0;
                                                        const convertedTHBTotal = newItem.productPrice.currency === "BAHT" ? thbPrice : 0;

                                                        setSaleProducts(prev => [{ ...newItem, quantity, mmkPrice, thbPrice, mmkTotal, thbTotal, convertedMMKTotal, convertedTHBTotal }, ...prev])
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                    name='remark'
                                    className='x-input' />
                                <span className='hidden text-red-500'>Product Not Found</span>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Sale Date</label>
                                <input type='date'
                                    required
                                    name='sale_date'
                                    value={saleDate}
                                    onChange={e => setSaleDate(e.target.value)}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                            <label className='x-label'>Member Code {(memberCode !== "" && customer?.customer_name) ? `(${customer.customer_name} - ${customer.loyalty_points} points)` : ''}</label>
                                <input type='text'
                                    name='remark'
                                    className='x-input'
                                    onKeyDown={e => {
                                        e.target.nextElementSibling.classList.remove('block')
                                        e.target.nextElementSibling.classList.add('hidden')
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            const url = API_URL + `/wholesaleCustomer?member_code=${e.target.value}`
                                            if (saleProducts.some(item => item.barcode === e.target.value)) {
                                                e.target.value = "";
                                                e.target.nextElementSibling.classList.remove('hidden');
                                                e.target.nextElementSibling.classList.add('block');
                                                setCustomer({});
                                                setMemberCode("");
                                                return;
                                            }
                                            axios.get(url, {
                                                withCredentials: true
                                            }).then(response => {
                                                if (response.data.length === 0) {
                                                    e.target.value = "";
                                                    e.target.nextElementSibling.classList.remove('hidden');
                                                    e.target.nextElementSibling.classList.add('block');
                                                    setCustomer({});
                                                    setMemberCode("");
                                                } else {
                                                    setCustomer(response.data.data[0]);
                                                    setAdvancedInMMK(response.data.data[0]?.advanced_mmk);
                                                    setAdvancedInTHB(response.data.data[0]?.advanced_thb)
                                                    setMemberCode(response.data.data[0].member_code)
                                                }
                                            })
                                        }
                                    }}
                                />
                                <span className='hidden text-red-500'>Member Card Not Found</span>
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    onChange={e => {
                                        const value = e.target.value
                                        setRemark(value)
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            return;
                                        }
                                    }}
                                    className='x-input' />
                            </div>
                        </div>
                        <WholesaleOrderTable
                            data={saleProducts}
                            currencyType={currencyType}
                            THBToMMK={exchangeRate}
                            MMKToTHB={reverseExchangeRate}
                            setSubTotal={setSubTotal}
                            setSaleProducts={setSaleProducts}
                            setSubTotalMMK={setSubTotalMMK}
                            setSubTotalTHB={setSubTotalTHB}
                        />
                        <div className='py-12 flex flex-row-reverse justify-around'>
                            <div className='grow-[0.2]'>
                                <InvoiceDetails
                                    total={total}
                                    currencyType={currencyType}
                                    setCurrencyType={setCurrencyType}
                                    subTotal={subTotal}
                                    taxEnabled={taxEnabled}
                                    setTaxEnabled={setTaxEnabled}
                                    tax={tax} setTax={setTax}
                                    taxAmount={taxAmount}
                                    change={change}
                                    discount={discount}
                                    setDiscount={setDiscount}
                                    paidAmount={paidAmount}
                                    setPaidAmount={setPaidAmount}

                                    totalInMMK={totalInMMK}
                                    totalInTHB={totalInTHB}
                                    advancedEnabled={advancedEnabled}
                                    setAdvancedEnabled={setAdvancedEnabled}
                                    discountInMMK={discountInMMK}
                                    discountInTHB={discountInTHB}
                                    setDiscountInMMK={setDiscountInMMK}
                                    setDiscountInTHB={setDiscountInTHB}
                                    subTotalMMK={subTotalMMK}
                                    subTotalTHB={subTotalTHB}
                                    changeInTHB={changeInTHB}
                                    changeInMMK={changeInMMK}
                                    advancedInMMK={advancedInMMK}
                                    advancedInTHB={advancedInTHB}
                                    setAdvancedInMMK={setAdvancedInMMK}
                                    setAdvancedInTHB={setAdvancedInTHB}
                                    taxAmountInMMK={taxAmountInMMK}
                                    taxAmountInTHB={taxAmountInTHB}
                                    paidAmountInMMK={paidAmountInMMK}
                                    paidAmountInTHB={paidAmountInTHB}
                                    setPaidAmountInMMK={setPaidAmountInMMK}
                                    setPaidAmountInTHB={setPaidAmountInTHB}
                                />
                            </div>
                        </div>
                        <Receipt
                            mmkTotal={mmkTotal}
                            thbTotal={thbTotal}
                            total={total}
                            discount={discount}
                            subTotal={subTotal}
                            voucherCode={voucherCode}
                            currencyType={currencyType}
                            THBToMMK={exchangeRate}
                            MMKToTHB={reverseExchangeRate}
                            paidAmount={paidAmount}
                            change={change}
                            getPrintItems={getPrintItems}
                            saleProducts={saleProducts}
                            printRef={printRef}
                            exchangeRate={exchangeRate}
                            reverseExchangeRate={reverseExchangeRate}
                            changeInMMK={changeInMMK}
                            changeInTHB={changeInTHB}
                            discountInMMK={discountInMMK}
                            discountInTHB={discountInTHB}
                            paidAmountInMMK={paidAmountInMMK}
                            paidAmountInTHB={paidAmountInTHB}
                            totalInMMK={totalInMMK}
                            totalInTHB={totalInTHB}
                        />
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn' onClick={onSubmit} tabIndex={4}>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const convertCurrency = (product, currencyType, THBToMMK, MMKToTHB) => {
    let convertedPrice;
    if (currencyType === product.productPrice.currency) {
        convertedPrice = product.productPrice.wholesale_price;
    } else {
        if (product.productPrice.currency === "MMK") {
            convertedPrice = product.productPrice.wholesale_price * MMKToTHB;
        } else {
            convertedPrice = nearValue(product.productPrice.wholesale_price / THBToMMK);
        }
    }
    return convertedPrice;
}

function nearValue(input) {
    const output = Math.ceil(input / 100) * 100;
    return output;
}