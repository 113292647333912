const DeleteConfirmPopup = ({ onConfirmClick, onCloseClick, id, path }) => {
    return (
        <div className='popup'>
            <p>Are you sure to delete?</p>
            <div className='flex justify-between'>
                <button onClick={onCloseClick} className='white-btn'>Cancel</button>
                <button onClick={(e) => onConfirmClick(e, id, path, onCloseClick)} className='red-btn'>Delete</button>
            </div>
        </div>
    )
}
export default DeleteConfirmPopup;