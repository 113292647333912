import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';
import { useEffect } from 'react';

const headers = [
    // {
    //     key: 'retail_sale_id',
    //     name: 'Retail Sales ID'
    // },
    {
        key: 'voucher_code',
        name: 'Voucher Code'
    },
    {
        key: 'sale_date',
        name: 'Sale Date'
    },
    {
        key: 'customer_name',
        parent: 'retailCustomer',
        name: 'Retail Customer Name'
    },
    {
        key: 'loyalty_points',
        parent: 'retailCustomer',
        name: 'Points'
    },
    {
        key: 'total',
        name: 'Total Amount'
    },
    {
        key: 'currency_type',
        name: 'Currency'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'retail_sale_id',
        name: 'Retail Sale Order ID'
    },
    {
        key: 'customer_name',
        parent: 'retailCustomer',
        name: 'Retail Customer Name'
    },
    {
        key: 'voucher_code',
        name: 'Voucher Code'
    },
    {
        key: 'sale_date',
        name: 'Sale Date'
    },
    {
        key: 'currency_type',
        name: 'Currency Type'//????
    },
    {
        key: 'promotion_method',
        name: 'Promotion Method'
    },
    {
        key: 'promotion_amount',
        name: 'Promotion Amount'
    },
    {
        key: 'total',
        name: 'Total Amount'
    },
    // {
    //     key: '',//????
    //     name: 'Paid Amount'
    // },
    {
        key: 'remark',
        name: 'Remark'
    }
]

const detailPopupTableHeaders = [
    {
        key: 'product_name',
        parent: 'product',
        name: 'Product Name'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    },
    {
        key: 'price',
        name: 'Price'
    },
    {
        key: 'total',
        name: 'Amount'
    }

]

const RetailSalesOrderList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const {data, setCurrentPage, setQuery, reload} = useFetchData({
        url: API_URL + '/retailSale',
        defaultQuery: 'status=ACTIVE'
    });

    useEffect(() => {
        document.querySelector(".side-nav").style.display = "flex"
        document.querySelector(".sub-layout").style.paddingLeft = "300px"
    }, [])

    data.results = data.results.map(item => ({
        ...item,
        total : item.currency_type === "SEPERATED" ? parseFloat(item.total_mmk) || parseFloat(item.total_thb) : item.total
    }))

    return (
        <TableLayout
            title='Retail Sales Order List'
            data={data}
            headers={headers}
            detailPopupTitle='Retail Sales Order Information'
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTableTitle='Product List'
            detailPopupTableHeaders={detailPopupTableHeaders}
            detailPopupTableKey='retailSaleDetails'
            idKey='retail_sale_id'
            endPoint='/retailSale'
            setCurrentPage={setCurrentPage}
            removeEdit={true}
            hasSearch
            hasCreate
            hasActions
            hasPrintReceipt
            createPath={`/sale/retail-sales-order/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            hasDetails
            reload={reload}
        />
    )
}
 
export default RetailSalesOrderList;