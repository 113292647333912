import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../routers/AuthProvider';
import { useForm } from '../../hooks/useForm';
import backArrowIcon from '../../assets/img/back-arrow.png';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import '../../assets/css/common.css';
import { formatDateToYYYYMMDD } from '../../utils/format-time';

const EditAdjustment = () => {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = '/warehouse/adjustment/list';
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();

    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const data = {
            ...formData,
            status: 'ACTIVE'
        }
        submitForm('PUT', '/adjustment/' + id, data, () => {
            navigate(backPath)
        });
    }

    const [warehouses, setWarehouses] = useState()
    useEffect(() => {
        axios.get(API_URL + '/warehouse', {
            withCredentials: true
        })
            .then(response => {
                setWarehouses(response.data.data);
            })
    }, []);

    const [products, setProducts] = useState();
    useEffect(() => {
        axios.get(API_URL + '/product', {
            withCredentials: true
        })
            .then(response => {
                setProducts(response.data.data)
            })
    }, [])

    useEffect(() => {
        setFormData({
            product_id: initialData.product_id,
            barcode: initialData.barcode,
            warehouse_id: initialData.warehouse_id,
            adjustment_method: initialData.adjustment_method
        })
    },[])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Adjustment</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Adjustment Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Product Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='product_id'
                                        required
                                        onChange={handleInputChange}
                                        value={formData.product_id ? formData.product_id : initialData.product_id}
                                        className='x-input dropdown'
                                    >
                                        {products && products.map((item, index) => (
                                            <option key={index} disabled={item.status !== 'ACTIVE'} value={item.product_id}>{item.product_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Barcode</label>
                                <input type='text'
                                    defaultValue={initialData.barcode}
                                    required
                                    name='barcode'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Quantity</label>
                                <input type='number'
                                    required
                                    defaultValue={initialData.quantity}
                                    name='quantity'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Warehouse Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='warehouse_id'
                                        value={formData.warehouse_id ? formData.warehouse_id : initialData.warehouse_id}
                                        className='x-input dropdown'
                                        onChange={handleInputChange}
                                    >
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} disabled={warehouse.status !== 'ACTIVE'} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Adjustment Date</label>
                                <input type='date'
                                    required
                                    defaultValue={formatDateToYYYYMMDD(new Date(initialData.adjustment_date))}
                                    name='adjustment_date'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Method</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='adjustment_method'
                                        defaultValue={initialData.adjustment_method}
                                        className='x-input dropdown'
                                        onChange={handleInputChange}
                                    >
                                        <option value="IN">IN</option>
                                        <option value="OUT">OUT</option>
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    defaultValue={initialData.remark}
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='mt-8 form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
}

export default EditAdjustment;