import { useLocation } from "react-router-dom";
import TableLayout from "../../components/TableLayout";
import { useFetchData } from "../../hooks/useFetchData";
import { API_URL } from "../../utils/constant";

const headers = [
    {   
        key: 'unit_name',
        parent: 'base_unit',
        name: 'Base Unit'
    },
    {
        key: 'base_qty',
        name: 'Base Quantity'
    },
    {   
        key: 'unit_name',
        parent: 'converted_unit',
        name: 'Converted Unit'
    },
    {
        key: 'converted_qty',
        name: 'Converted Quantity'
    }
];

export default function UnitConversionList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/uoc',
        defaultQuery: 'status=ACTIVE'
    });

    return (
        <TableLayout
            title='Unit of Conversion List'
            data={data}
            headers={headers}
            removeDetails={true}
            setCurrentPage={setCurrentPage}
            idKey='uoc_id'
            endPoint='/uoc'
            hasSearch
            hasCreate
            hasActions
            createPath={`/unit/conversion/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}