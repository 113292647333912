import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import PurchaseOrderTable from '../../components/PurchaseOrderTable';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';


function useProducts() {
    const [products , setProducts] = useState([])
    useEffect(() => {
        axios.get(API_URL + '/product?status=ACTIVE', {
            withCredentials: true
        })
        .then(response => {
            setProducts(response.data.data)
        }).catch(() => setProducts([]))
    },[])

    return {
        products
    }
}
export default function CreatePurchaseOrder() {
    const backPath = `/purchase/purchase-order/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const { products } = useProducts();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        const items = orderProducts.map(product => {
            let unit_price = parseFloat(document.getElementById(`price-${product.product_id}`).value);
            const checkUOC = uoc.filter(u => u?.converted_unit_id == document.getElementById(`unit-${product.product_id}`).value)

            if (checkUOC.length) {
                unit_price = unit_price/checkUOC[0]?.base_qty
            };

            return {
                product_id: product.product_id,
                unit_id: document.getElementById(`unit-${product.product_id}`).value,
                quantity: document.getElementById(`qty-${product.product_id}`).value,
                price: document.getElementById(`price-${product.product_id}`).value,
                total_amount: document.getElementById(`total-${product.product_id}`).value,
                unit_price
            };
        })

        const data = {
            ...formData,
            items,
            total: overallTotal,
            status: 'ACTIVE',
        }
        
        submitForm('POST', '/purchaseOrder', data, () => {
            navigate(backPath)
        });
    }

    const [suppliers, setSuppliers] = useState();

    const [units, setUnits] = useState();
    const [uoc, setUOC] = useState();
    useEffect(() => {
        
        axios.get(API_URL + '/unit?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setUnits(response.data.data)
            })

        axios.get(API_URL + '/uoc?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setUOC(response.data.data)
            })

        axios.get(API_URL + '/supplier?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setSuppliers(response.data.data);
            })
    }, []);

    const filteredOptions = (options, inputValue) =>  {
        console.log("Products",options);
        console.log('Filter Input', inputValue)
        return  options.filter(option =>
            option.product_name.toLowerCase().includes(inputValue.toLowerCase()) || option.barcode.toLowerCase().includes(inputValue.toLowerCase())
        )
    }

    

    const debouncedLoadOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                    axios.get(API_URL + '/product?status=ACTIVE', {
                        withCredentials: true
                    })
                    .then(response => {
                        const options = response.data?.data
                        resolve(filteredOptions(options,inputValue));
                    })
                
            }, 1000);
  });
    
    useEffect(() => {
        const handleBarcodeInput = (event) => {
            if(event.keyCode === 13) {
                event.preventDefault();
                let barcode = event.target.value.trim();
                debouncedLoadOptions(barcode);
            }
          };
      
          document.addEventListener('keydown', handleBarcodeInput);
      
          return () => {
            document.removeEventListener('keydown', handleBarcodeInput);
          };
    },[])


    const [orderProducts, setOrderProducts] = useState([])
    const [overallTotal, setOverallTotal] = useState(0)

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Purchase Order</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Purchase Order Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Order Product</label>
                                <AsyncSelect
                                    defaultOptions
                                    cacheOptions
                                    loadOptions={debouncedLoadOptions}
                                    isSearchable={true}
                                    isMulti={true}
                                    getOptionLabel={option => `[${option.barcode}] ${option.product_name}`}
                                    getOptionValue={option => option.product_id}
                                    onChange={option => {
                                        const newObj = option.map(item => {
                                            const orderPrice = item.orderPrice ? item.orderPrice : 0
                                            const quantity = item.quantity ? item.quantity : 1
                                            const orderUnit = item.orderUnit ? item.orderUnit : ""
                                            return { ...item, quantity, orderPrice, orderUnit }
                                        })
                                        setOrderProducts(newObj)
                                    }}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: '#1b1b1b66',
                                            minHeight: '50px'
                                        }),
                                    }}
                                />
                                
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Supplier Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='supplier_id'
                                        required
                                        onChange={handleInputChange}
                                        defaultValue=''
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {suppliers && suppliers.map((item, index) => (
                                            <option key={index} value={item.supplier_id}>{item.supplier_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Purchase Date</label>
                                <input type='date'
                                    required
                                    name='purchase_date'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Currency Type</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='currency_type'
                                        onChange={handleInputChange}
                                        defaultValue=''
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        <option value="MMK">MMK</option>
                                        <option value="THB">THB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='grow hidden'>
                                <label className='x-label'>Total Amount</label>
                                <input type='number'
                                    name='total'
                                    value={overallTotal}
                                    disabled
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='pt-9'>
                            <PurchaseOrderTable orderProducts={orderProducts} setOrderProducts={setOrderProducts} units={units} overallTotal={overallTotal} setOverallTotal={setOverallTotal} />
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}