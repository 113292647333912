import { useEffect, useState } from "react";

const RetailPromotionTable = ({ saleProducts, activeTab, setActiveTab, setPromotionAmount, promotionByTotal, currencyType }) => {
    const promoAppliedProducts = saleProducts.filter(product => product.promotion && product.applyPromo)
    const amount = (promotionByTotal.applyPromo && promotionByTotal.promotion) ? promotionByTotal.promotion : null
    const percent = promoAppliedProducts.filter(product => product.promotion.promotion_type === "By Percent")
    const bogo = promoAppliedProducts.filter(product => product.promotion.promotion_type === "By BOGO")
    const cross_product = promoAppliedProducts.filter(product => product.promotion.promotion_type === "By Cross Product")

    useEffect(() => {
        let promoAmount = 0

        if (amount) {
            promoAmount += amount.promotion_amount
        }

        if (percent) {
            percent.map((product) => {
                if (currencyType === "MMK") {
                    const mmkAmount = ((product.promotion.percent_amount / 100) * product.mmkPrice).toFixed(2)
                    const mmkTotal = product.quantity * mmkAmount
                    promoAmount += mmkTotal
                } else {
                    const thbAmount = ((product.promotion.percent_amount / 100) * product.thbPrice).toFixed(2)
                    const thbTotal = product.quantity * thbAmount
                    promoAmount += thbTotal
                }
            })
        }
        setPromotionAmount(parseFloat((promoAmount).toFixed(2)))

    }, [amount, percent])

    return (
        (amount || promoAppliedProducts.length > 0) && <div className="table-container">
            <div className="mb-4 flex justify-between">
                <h3 className="table-title">Promotion Information</h3>
            </div>
            <div className="tab-buttons">
                {
                    amount && <button
                        className={activeTab === 1 ? "active" : ""}
                        onClick={e => {
                            e.preventDefault()
                            setActiveTab(1)
                        }}>By Total</button>
                }
                {
                    percent.length > 0 && <button
                        className={activeTab === 2 ? "active" : ""}
                        onClick={e => {
                            e.preventDefault()
                            setActiveTab(2)
                        }}>By Percent</button>
                }
                {
                    bogo.length > 0 && <button
                        className={activeTab === 3 ? "active" : ""}
                        onClick={e => {
                            e.preventDefault()
                            setActiveTab(3)
                        }}>By BOGO</button>
                }
                {
                    cross_product.length > 0 && <button
                        className={activeTab === 4 ? "active" : ""}
                        onClick={e => {
                            e.preventDefault()
                            setActiveTab(4)
                        }}>By Cross Product</button>
                }
            </div>
            {
                activeTab === 1 && <table className='x-table product-list-table'>
                    <thead>
                        <tr>
                            <th>Promotion Type</th>
                            <th>Minimun Total Amount</th>
                            <th>Promotion Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            amount && (
                                <tr>
                                    <td>By Total</td>
                                    <td>{amount.minimum_total_amount}</td>
                                    <td className="amount">{amount.promotion_amount}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            }

            {
                activeTab === 2 && <table className='x-table product-list-table'>
                    <thead>
                        <tr>
                            <th>Promotion Type</th>
                            <th>Product Name</th>
                            <th>Promotion Percent</th>
                            <th>MMK Amount</th>
                            <th>THB Amount</th>
                            <th>MMK Total</th>
                            <th>THB Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            percent?.map((product, index) => {
                                const mmkAmount = ((product.promotion.percent_amount / 100) * product.mmkPrice).toFixed(2)
                                const thbAmount = ((product.promotion.percent_amount / 100) * product.thbPrice).toFixed(2)
                                const mmkTotal = product.quantity * mmkAmount
                                const thbTotal = product.quantity * thbAmount
                                return (
                                    <tr key={index}>
                                        <td>By Percent</td>
                                        <td>{product.product_name}</td>
                                        <td>{product.promotion.percent_amount} %</td>
                                        <td className="mmk">{mmkAmount}</td>
                                        <td className="thb">{thbAmount}</td>
                                        <td>{mmkTotal}</td>
                                        <td>{thbTotal}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
            {
                activeTab === 3 && <table className='x-table product-list-table'>
                    <thead>
                        <tr>
                            <th>Promotion Type</th>
                            <th>Product Name</th>
                            <th>Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bogo?.map((product, index) => {
                                return (
                                    <tr key={index}>
                                        <td>By Bogo</td>
                                        <td>{product.product_name}</td>
                                        <td>1</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
            {
                activeTab === 4 && <table className='x-table product-list-table'>
                    <thead>
                        <tr>
                            <th>Promotion Type</th>
                            <th>Buy Product</th>
                            <th>Buy Qty</th>
                            <th>Get Product</th>
                            <th>Get Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cross_product?.map((product, index) => {
                                return (
                                    <tr key={index}>
                                        <td>By Cross Product</td>
                                        <td className="flex flex-col">{product.promotion.crossableProducts.map((product, i) => {
                                            return <span key={i}>{product.product.product_name}</span>
                                        })}
                                        </td>
                                        <td className="flex flex-col">{product.promotion.crossableProducts.map((product, i) => {
                                            return <span key={i}>{product.quantity}</span>
                                        })}
                                        </td>
                                        <td className="flex flex-col">{
                                            product.promotion.promoProducts.map((product, i) => {
                                                return <span key={i}>{product.product.product_name}</span>
                                            })}
                                        </td>
                                        <td className="flex flex-col">{product.promotion.promoProducts.map((product, i) => {
                                            return <span key={i}>{product.quantity}</span>
                                        })}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div>
    )
}

export default RetailPromotionTable