import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../routers/AuthProvider';
import { useForm } from '../../hooks/useForm';
import backArrowIcon from '../../assets/img/back-arrow.png';
import '../../assets/css/common.css';
import { API_URL } from '../../utils/constant';
import axios from 'axios';

const EditInventoryTransfer = () => {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = '/warehouse/inventory-transfer/list';
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();

    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const data = {
            ...formData,
            status: 'ACTIVE'
        }
        submitForm('PUT', '/inventoryTransfer', data, () => {
            navigate(backPath)
        });
    }

    const [warehouses, setWarehouses] = useState()
    useEffect(() => {
        axios.get(API_URL + '/warehouse', {
            withCredentials: true
        })
            .then(response => {
                setWarehouses(response.data.data);
            })
    }, []);

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Inventory Transfer</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Inventory Transfer Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer From</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='transfer_to'
                                        className='x-input dropdown'
                                        defaultValue={initialData.transfer_from}
                                        onChange={handleInputChange}
                                    >
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer To</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='transfer_to'
                                        className='x-input dropdown'
                                        defaultValue={initialData.transfer_to}
                                        onChange={handleInputChange}
                                    >
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer Date</label>
                                <input type='date'
                                    required
                                    name='transfer_date'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer Method</label>
                                <input type='text'
                                    required
                                    defaultValue={initialData.transfer_method}
                                    name='transfer_method'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='mt-8 form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
}

export default EditInventoryTransfer;