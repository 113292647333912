import { useLocation } from "react-router-dom";
import TableLayout from "../../components/TableLayout";
import { useFetchData } from "../../hooks/useFetchData";
import { API_URL } from "../../utils/constant";

const headers = [
    {
        key: 'product_category_name',
        name: 'Category Name'
    },
    {
        key: 'sub_product_category_name',
        name: 'Sub Category Name'
    },
    {
        key: 'prefix_code',
        name: 'Prefix Code'
    },
    {
        key: 'status',
        name: 'Status'
    },
];

export default function ProductCategoryList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        setQuery({
            product_category_name: value.trim(),
        });
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/productCategory',
        defaultQuery: 'status=ACTIVE'
    });

    return(
        <TableLayout
            title="Product Category List"
            data={data}
            headers={headers}
            idKey='product_category_id'
            endPoint='/productCategory'
            removeDetails={true}
            hasSearch
            hasCreate
            createPath={`/product/category/create`}
            hasActions
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            reload={reload}
        />
    );
}