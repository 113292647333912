import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import RoleDropdown from '../../components/RoleDropDown';
import { useAuth } from '../../routers/AuthProvider';
import PasswordInput from '../../components/PasswordInput';
import axios from 'axios';
import { API_URL } from '../../utils/constant';

export default function CreateOfficeUser() {
    const backPath = `/office-user/office-user-list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('POST', '/user', data, () => {
            navigate(backPath)
        });
    }

    const [roles, setRoles] = useState();
    useEffect(() => {
        axios.get(API_URL + '/role?sort=role_level', {
            withCredentials: true
        })
            .then(response => {
                setRoles(response.data.data)
            })
    }, [])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container'>
                <h1 className='sub-page-title'>Create New Office User</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>User Role</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='role_id'
                                        onChange={handleInputChange}
                                        defaultValue={''}
                                        // value={formData.district_id ? formData.district_id : ''}
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {
                                            roles && roles.map((item, index) => (
                                                <option key={index} value={item.role_id}>{item.role_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Name</label>
                                <input type='text'
                                    required
                                    name='name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Username</label>
                                <input type='text'
                                    required
                                    name='username'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Password</label>
                                <PasswordInput
                                    name='password'
                                    required
                                    onChange={handleInputChange} />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Phone Number</label>
                                <input type='text'
                                    required
                                    name='phone1'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label'>Description</label>
                                <textarea type='text'
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>

                        </div>
                    </form>
                </div>

            </div>

        </div>
    )
}