import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png'
import { API_URL } from '../../utils/constant';
import RoleDropdown from '../../components/RoleDropDown';
import { useAuth } from '../../routers/AuthProvider';

export default function EditOfficeUser() {
    const params = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const { setLoading } = useAuth();
    const backPath = `/office-user/office-user-list`;
    const { handleInputChange, formData, setFormData, submitForm, error } = useForm();
    const navigate = useNavigate();


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE'
        }
        submitForm('PUT', '/user/' + params.id, data, () => {
            navigate(backPath)
        });
    }

    const [roles, setRoles] = useState();
    useEffect(() => {
        axios.get(API_URL + '/role?sort=role_level', {
            withCredentials: true
        })
            .then(response => {
                setRoles(response.data.data)
            })
    }, [])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container'>
                <h1 className='sub-page-title'>Edit Office User</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>

                            <div className='grow'>
                                <label className='x-label input-required'>User Role</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='role_id'
                                        onChange={handleInputChange}
                                        // defaultValue={initialData.role.role_id}
                                       value={formData.role_id ? formData.role_id : initialData.role.role_id}
                                        // value={formData.district_id ? formData.district_id : ''}
                                        className='x-input dropdown'
                                    >
                                        {
                                            roles && roles.map((item, index) => (
                                                <option key={index} value={item.role_id}>{item.role_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {
                                    console.log(initialData.role)
                                }
                                {/* <RoleDropdown
                                    value={formData.role_id ? formData.role_id : ''}
                                    onChange={handleInputChange}
                                    required
                                    name='role_id'
                                /> */}
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Name</label>
                                <input type='text'
                                    required
                                    name='name'
                                    defaultValue={initialData.name}
                                    // value={formData.name ? formData.name : ''}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Username</label>
                                <input type='text'
                                    required
                                    defaultValue={initialData.username}
                                    // disabled//????
                                    // value={formData.username ? formData.username : ''}
                                    name='username'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Phone Number</label>
                                <input type='text'
                                    required
                                    defaultValue={initialData.phone1}
                                    // value={formData.phone ? formData.phone : ''}
                                    name='phone1'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Description</label>
                                <input type='text'
                                    // value={formData.description ? formData.description : ''}
                                    defaultValue={initialData.remark}
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block mb-5 text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}