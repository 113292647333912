import React, { useEffect, useState, useRef } from 'react';
import plusIcon from '../assets/img/icon-plus.png';
import ReactToPrint from "react-to-print";
import { formatDateToDDMMYYYYhmmssA } from '../utils/format-time';
import { API_URL } from '../utils/constant';
import axios from 'axios';

const PrintReceiptPopup = ({ data, onCloseClick }) => {
    console.log(data?.total_mmk)
    const componentRef = useRef();
    const pageStyle = `
    @media print {
        @page {
        size: 70mm 250mm;
        margin: 0 0.3in 0 0;
        }

        html, body {
            height: auto;
            font-size: 12pt; /* changing to 10pt has no impact */
        }
    }`;

    const [promotionProducts, setPromotionProducts] = useState([]);

    useEffect(() => {
        let promotions = [];
        axios.get(API_URL + '/promotion', {
            withCredentials: true
        })
            .then(response => {
                promotions = response.data.data;

                let promoItems = [];

                data.retailSaleDetails.map(product => {

                    if (product.promotion_type) {
                        if (product.promotion_type === "PERCENT") {
                            // a.promotion_type = "PERCENT"
                            // a.promotion_id = product.promotion_id
                            // a.promotion_amount = (product.promotion.percent_amount / 100) * a.sub_total
                            // a.total = a.sub_total - (product.promotion.percent_amount / 100) * a.sub_total
                        } else if (product.promotion_type === "BOGO") {
                            promoItems.push({ barcode: product.product.barcode, product_name: product.product.product_name, quantity: product.quantity })
                        } else if (product.promotion_type === "CROSS_PRODUCT") {

                            let promos = promotions?.cross_product?.filter((crossProduct) => {
                                return crossProduct?.crossableProducts?.filter(crossableProduct => (product.product_id === crossableProduct.product_id))
                            })
                            // a.promotion_type = "CROSS_PRODUCT"
                            // a.promotion_id = product.promotion_id
                            // a.promotion_amount = 0

                            console.log("promotions: ", promos);
                            promos[0]?.promoProducts.map(promoProduct => {
                                promoItems.push({ barcode: promoProduct.product.barcode, product_name: promoProduct.product.product_name, quantity: (product.quantity * promoProduct.quantity) })
                            });
                        }
                    }
                    setPromotionProducts(promoItems)
                });
            })
    }, []);

    return (
        <div className='popup'>
            <div className="flex flex-row-reverse">
                <button onClick={onCloseClick}><img src={plusIcon} alt="close icon" className="rotate-45 w-5 h-5" /></button>
            </div>
            <div
                ref={componentRef}
                className='receipt' style={{ "display": "flex", "flexDirection": "column", "alignItems": "center", "padding": "12px 0px" }}>
                <h1>Good Supermarket</h1>
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <h3>No.37/Boke Yoke Street/Ward(4)/Myawaddy</h3>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                        <div><span>{data.voucher_code}</span></div>
                        <div><span>{formatDateToDDMMYYYYhmmssA(new Date(data.created_date))}</span></div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}><span>Cashier: {data.created_by_user.name}</span></div>
                </div>
                <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr style={{ borderTop: "1px dashed" }}>
                                <th>No</th>
                                <th style={{ textAlign: "left" }}>Item</th>
                                <th>Qty</th>
                                <th style={{ textAlign: "right" }}>Price</th>
                                <th style={{ textAlign: "right" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.hasOwnProperty("wholesaleDetails") ?
                                    data.wholesaleDetails.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td style={{ textAlign: "left" }}>{item.product.product_name}</td>
                                                <td>{item.quantity}</td>
                                                <td style={{ textAlign: "right" }}>{item.price}</td>
                                                <td style={{ textAlign: "right", paddingRight: "0.7rem" }}>{item.total}</td>
                                            </tr>
                                        )
                                    }) : data.retailSaleDetails.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <tr key={index}>
                                                    <td></td>
                                                    <td style={{ textAlign: "left" }}>{item.product.barcode}</td>
                                                </tr>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: "left" }}>{item.product.product_name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td style={{ textAlign: "right" }}>{item.price}</td>
                                                    <td style={{ textAlign: "right", paddingRight: "0.7rem" }}>{item.total}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                            }
                        </tbody>
                        <tfoot>
                            {data.promotion_amount !== 0 && <tr style={{ borderBottom: "1px dashed" }}>
                                <td colSpan={4} style={{ textAlign: "left" }}>Promotion Amount</td>
                                <td style={{ textAlign: "right", paddingRight: "0.7rem" }}>{data.promotion_amount}</td>
                            </tr>}
                            {(promotionProducts && promotionProducts.length > 0) && <>
                                <tr>
                                    <td colSpan={5} style={{ textAlign: "left" }}>Promotion Products</td>
                                </tr>
                                {
                                    promotionProducts.map((item, i) => {
                                        return (
                                            // <tr style={{ borderBottom: (i == promotionProducts.length - 1)?"1px dashed": "none" }}>
                                            //     <td colSpan={5} style={{ textAlign: "left" }}>{item.quantity} x {item.product_name}</td>
                                            // </tr>
                                            <React.Fragment key={i}>
                                                <tr >
                                                    <td></td>
                                                    <td>{item.barcode}</td>
                                                </tr>
                                                <tr key={i}>
                                                    < td>{item.quantity}</td>
                                                    <td style={{ textAlign: "left" }}>{item.product_name} </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </>}
                            <tr style={{ borderTop: promotionProducts.length > 0 ? "1px dashed" : "none" }}>
                                <td>ပေါင်း</td>
                                <td>{data.mmk_total ?? 0}K</td>
                                <td>{data.thb_total ?? 0}B</td>
                                <td>Net</td>
                                {
                                    data.currency_type !== 'SEPERATED' ?
                                        <td>{data.total}{data.currency_type === "MMK" ? "K" : "B"}</td>

                                        : null
                                }
                                {
                                    data.currency_type === 'SEPERATED' ?
                                        <React.Fragment>
                                            <td>{`${data?.total_mmk}K`}</td>
                                            <td>{`${data?.total_thb}B`}</td>
                                        </React.Fragment> : null
                                }
                                {/* <td>{data.total}{data.currency_type === "MMK" ? "K" : "B"}</td> */}
                            </tr>
                            <tr>
                                <td>လျှော့</td>
                                <td>{data.discount ?? 0}K</td>
                                {/* <td>{data.currency_type === "THB" ? data.total : "0"}B</td> */}
                                <td>Pay</td>
                                {
                                    data.currency_type === 'SEPERATED' ?
                                        <React.Fragment>
                                            <td>{`${data.paid_amount_mmk}K`}</td>
                                            <td>{`${data.paid_amount_thb}B`}</td>
                                        </React.Fragment> : <td>{data?.paid_amount}{data.currency_type === "THB" ? `B` : "K"}</td>
                                }
                            </tr>
                        </tfoot>
                    </table>

                    <div className='change' style={{ display: "flex", justifyContent: "space-evenly", fontWeight: 'normal' }}>
                        <span>Change</span>
                        {
                            data.currency_type !== 'SEPERATED' ?
                                <span>{data.change}{data.currency_type === "MMK" ? "K" : "B"}</span> : null

                        }
                        {
                            data.currency_type === 'SEPERATED' ?

                                <span>{`${data.change_mmk}K`}</span> : null

                        }
                        {
                            data.currency_type === 'SEPERATED' ?

                                <span>{`${data.change_thb}B`}</span> : null

                        }
                        <span>Rate</span>
                        <span>{data.rate ?? 0}</span>
                    </div>

                    <div style={{ display: "flex", paddingTop: "8px", justifyContent: "space-evenly" }}>
                        <span>Tax {data.tax ?? 0}% included</span>
                        <span>ဝယ်ပြီးပစ္စည်းပြန်မလဲပေးပါ။</span>
                    </div>

                    <div style={{ paddingTop: '20px', paddingBottom: '20px', textAlign: "center" }}>
                        <h3>***THANK YOU***</h3>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <ReactToPrint
                    trigger={() => <button className='gold-btn'>
                        Print</button>}
                    content={() => componentRef.current}
                    pageStyle={pageStyle}
                />
            </div>
        </div>
    )
}

export default PrintReceiptPopup;