import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'point',
        name: 'Point'
    },
    {
        key: 'mmk_amount',
        name: 'MMK Amount'
    },
    {
        key: 'thb_amount',
        name: 'THB Amount'
    },
    
];

const GiftRuleList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/pointRule',
        defaultQuery: 'status=ACTIVE'
    });


    return (
        <TableLayout
            title='Point Rule List'
            data={data}
            headers={headers}
            idKey='point_rule_id'
            endPoint='/pointRule'
            setCurrentPage={setCurrentPage}
            removeDetails={true}
            hasSearch
            hasCreate
            hasActions
            createPath={`/loyalty/point-rule/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}
 
export default GiftRuleList;