import { checkISO8601Format, formatDateToDDMMYY } from '../utils/format-time'
import plusIcon from '../assets/img/icon-plus.png'

const ListDetailPopup = ({ detailData, detailPopupTitle, detailPopupHeaders, onCloseClick, detailPopupTableHeaders, detailPopupTableTitle, detailPopupTableKey }) => {
    return (
        <div className='popup'>
            <div className='flex flex-row-reverse'>
                <button onClick={onCloseClick}><img src={plusIcon} alt='close icon' className='rotate-45 w-5 h-5' /></button>
            </div>
            <div className='px-8'>
                <div className='flex pb-4'>
                    <h1 className='text-3xl	'>{detailPopupTitle}</h1>
                </div>
                <div>
                    <table className='list-detail-table'>
                        <tbody>
                            {detailPopupHeaders.map((header, index) => {
                                const value = detailData[header.parent]?.[header.key] || detailData[header.key]
                                if (typeof value === 'array') {
                                    return <tr>Array</tr>
                                }

                                if (Array.isArray(value)) {
                                    return (
                                        <tr key={index}>
                                            <td className='align-baseline'>{header.name}</td><td className='px-8 align-baseline'>:</td>
                                            <td>{value.map(item => <div>{`${item.product.product_name} x ${item.quantity}`}</div>)}</td>
                                        </tr>)
                                }
                                return <tr key={index}><td>{header.name}</td><td className='px-8'>:</td>
                                    <td key={index}>{checkISO8601Format(value) ? formatDateToDDMMYY(new Date(value)) : value}</td></tr>
                            })}
                        </tbody>
                    </table>
                </div>
                {detailPopupTableHeaders &&
                    <>
                        <div className='x-divider my-4'></div>
                        <div>
                            <div className='flex pb-4'>
                                <h1 className='text-3xl	'>{detailPopupTableTitle}</h1>
                            </div>
                            <div>
                                <table className='list-detail-table-type-2'>
                                    <thead>
                                        <tr>
                                            {detailPopupTableHeaders.map((item, index) => (
                                                <th key={index}>{item.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            detailData && detailData[detailPopupTableKey].map((item, i) => {
                                                return (<tr key={i}>
                                                    {detailPopupTableHeaders.map((header, index) => {
                                                        const value = item[header.parent]?.[header.key] || item[header.key]
                                                        return <td key={index}
                                                            style={{
                                                                color: header.key === 'status' ? item[header.key] === 'ACTIVE' ? '#149D52' : item[header.key] === 'Cancelled' ? '#FF0000' : '#0870D8' : ''
                                                            }}
                                                        >{checkISO8601Format(value) ? formatDateToDDMMYY(new Date(value)) : value}</td>
                                                    })}
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                    {
                                        detailPopupTitle === 'Purchase Order Information' &&
                                        <tfoot className='border-t'>
                                            <tr>
                                                <td colSpan={4}>Total Amount</td>
                                                <td>{detailData.total}</td>
                                            </tr>
                                        </tfoot>
                                    }
                                    {
                                        (detailPopupTitle === 'Retail Sales Order Information' || detailPopupTitle === 'Wholesale Order Information') &&
                                        <tfoot className='border-t'>
                                            <tr>
                                                <td colSpan={3}>Total Amount</td>
                                                <td>{detailData.total}</td>
                                            </tr>
                                        </tfoot>
                                    }
                                </table>
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    )
}
export default ListDetailPopup