import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { API_URL } from '../../utils/constant';
import { useFetchData } from '../../hooks/useFetchData';

const headers = [
    {
        key: 'adjustment_date',
        name: 'Adj Date'
    },
    {
        key: 'product_name',
        parent: 'product',
        name: 'Product Name'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    },
    {
        key: 'warehouse_name',
        parent: 'warehouse',
        name: 'Warehouse'
    },
    {
        key: 'adjustment_method',
        name: 'Method'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'adjustment_id',
        name: 'Adjustment ID'
    },
    {
        key: 'warehouse_name',
        parent: 'warehouse',
        name: 'Warehouse Name'
    },
    {
        key: 'product_name',
        parent: 'product',
        name: 'Product Name'
    },
    {
        key: 'barcode',
        name: 'Barcode'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    },
    {
        key: 'adjustment_method',
        name: 'Method'
    },
    {
        key: 'adjustment_date',
        name: 'Adjustment Date'
    },
    {
        key: 'remark',//????
        name: "Remark"
    }
]

const AdjustmentList = () => {
    const location = useLocation();
    const currentPath = location.pathname;
   
    const handleSearch = (value) => {
        console.log('searching value: ', value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/adjustment',
        defaultQuery: 'status=ACTIVE'
    });

    return (
         <TableLayout
            title='Adjustment List'
            data={data}
            headers={headers}
            detailPopupTitle='Adjustment Information'
            detailPopupHeaders={detailPopupHeaders}
            idKey='adjustment_id'
            endPoint='/adjustment'
            setCurrentPage={setCurrentPage}
            removeEdit={true}
            hasSearch
            hasCreate
            hasActions
            createPath={`/warehouse/adjustment/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />       
    )
}

export default AdjustmentList;