import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import { formatDateToYYYYMMDD } from '../../utils/format-time';
import PromotionProductTable from '../../components/PromotionProductTable';
import AsyncSelect from 'react-select/async';


export default function EditPromotion() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/promotion/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            "type": promotionType,
            "data": {
                ...formData,
                [promotionIdKey]: id,
                status: 'ACTIVE',
            }
        };

        if (initialData.promotion_type === "By Cross Product") {
            const crossable_products = buyProducts.map(product => ({
                product_id: product.product_id,
                barcode: product.barcode,
                quantity: document.getElementById(`qty-${product.product_id}`).value
            }))

            const promo_products = getProducts.map(product => ({
                product_id: product.product_id,
                barcode: product.barcode,
                quantity: document.getElementById(`qty-${product.product_id}`).value
            }))

            data.data.crossable_products = crossable_products
            data.data.promo_products = promo_products
        }
        submitForm('PUT', '/promotion/update/', data, () => {
            navigate(backPath)
        });
    }

    let crossableProducts, promoProducts

    if (initialData.promotion_type === "By Cross Product") {
        crossableProducts = initialData.crossableProducts.map(item => {
            const product = item.product;
            product.quantity = item.quantity;
            return product;
        })
    
        promoProducts = initialData.promoProducts.map(item => {
            const product = item.product;
            product.quantity = item.quantity;
            return product;
        });
    }
   

    const [promotionType, setPromotionType] = useState();
    const [promotionIdKey, setPromotionIdKey] = useState();

    useEffect(() => {
        switch (initialData.promotion_type) {
            case 'By Total':
                setPromotionType('AMOUNT')
                setPromotionIdKey('promotion_by_amount_id')
                break;
            case 'By Percent':
                setPromotionType('PERCENT')
                setPromotionIdKey('promotion_by_percent_id')
                break;
            case 'By BOGO':
                setPromotionType('BOGO')
                setPromotionIdKey('promotion_by_bogo_id')
                break;
            case 'By Cross Product':
                setPromotionType('CROSS_PRODUCT')
                setPromotionIdKey('promotion_by_cp_id')
                break;
            default:
                break;
        }
    }, [initialData.promotion_type])

    const [products, setProducts] = useState();
    useEffect(() => {
        axios.get(API_URL + '/product', {
            withCredentials: true
        })
            .then(response => {
                setProducts(response.data.data)
            })
    }, [])

    const [buyProducts, setBuyProducts] = useState(crossableProducts);
    const [getProducts, setGetProducts] = useState(promoProducts);

    const loadOptions = inputValue => {
        return axios.get(API_URL + '/product', {
           withCredentials: true
        })
            .then(response => response.json())
            .then(data => {
                return data.data.filter(option =>
                    option.product_name.toLowerCase().includes(inputValue.toLowerCase())
                );
            });
    };

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Promotion</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Promotion Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            {initialData.promotion_type === 'By Total' && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Promotion Amount</label>
                                    <input type='number'
                                        required
                                        name='promotion_amount'
                                        defaultValue={initialData.promotion_amount}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'></div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Minimum Total Amount</label>
                                    <input type='number'
                                        required
                                        name='minimum_total_amount'
                                        defaultValue={initialData.minimum_total_amount}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Currency Type</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            required
                                            name='currency_type'
                                            defaultValue={initialData.currency_type}
                                            onChange={handleInputChange}
                                            // value={selectedOption} onChange={handleOptionChange}
                                            className='x-input dropdown'
                                        >
                                            <option value="MMK">MMK</option>
                                            <option value="THB">THB</option>
                                        </select>
                                    </div>
                                </div>
                            </>}
                            {initialData.promotion_type === 'By BOGO' && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Product Name</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            name='product_id'
                                            required
                                            onChange={handleInputChange}
                                            value={formData.product_id ? formData.product_id : initialData.product_id}
                                            className='x-input dropdown'
                                        >
                                            {products && products.map((item, index) => (
                                                <option key={index} value={item.product_id}>{item.product_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Barcode</label>
                                    <input type='text'
                                        required
                                        name='barcode'
                                        defaultValue={initialData.barcode}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                            </>}
                            {initialData.promotion_type === 'By Percent' && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Percent</label>
                                    <input type='number'
                                        required
                                        name='percent_amount'
                                        defaultValue={initialData.percent_amount}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'></div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Product Name</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            name='product_id'
                                            required
                                            onChange={handleInputChange}
                                            value={formData.product_id ? formData.product_id : initialData.product_id}
                                            className='x-input dropdown'
                                        >
                                            {products && products.map((item, index) => (
                                                <option key={index} value={item.product_id}>{item.product_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Barcode</label>
                                    <input type='text'
                                        required
                                        name='barcode'
                                        defaultValue={initialData.barcode}
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                            </>}

                            {initialData.promotion_type === 'By Cross Product' &&
                                <>
                                    <div className='grow'>
                                        <label className='x-label input-required'>Buy Product</label>
                                        <AsyncSelect
                                            defaultOptions
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            isSearchable={true}
                                            isMulti={true}
                                            getOptionLabel={option => option.product_name}
                                            getOptionValue={option => option.product_id}
                                            onChange={option => setBuyProducts(option)}
                                            defaultValue={crossableProducts}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: '#1b1b1b66',
                                                    minHeight: '50px'
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className='grow'>
                                        <label className='x-label input-required'>Get Product</label>
                                        <AsyncSelect
                                            defaultOptions
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            isSearchable={true}
                                            isMulti={true}
                                            getOptionLabel={option => option.product_name}
                                            getOptionValue={option => option.product_id}
                                            onChange={option => setGetProducts(option)}
                                            defaultValue={promoProducts}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: '#1b1b1b66',
                                                    minHeight: '50px'
                                                }),
                                            }}
                                        />
                                    </div>
                                </>}

                            <div className='grow'>
                                <label className='x-label input-required'>Start Date</label>
                                <input type='date'
                                    required
                                    name='start_date'
                                    defaultValue={formatDateToYYYYMMDD((new Date(initialData.start_date)))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>End Date</label>
                                <input type='date'
                                    required
                                    name='end_date'
                                    defaultValue={formatDateToYYYYMMDD((new Date(initialData.end_date)))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>



                        </div>
                        {initialData.promotion_type === 'By Cross Product' && <>
                            <div className='pt-9'>
                                <PromotionProductTable data={buyProducts} title='By Product' />
                            </div>
                            <div className='pt-9'>
                                <PromotionProductTable data={getProducts} title='Get Product' />
                            </div>
                        </>}
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}