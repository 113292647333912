import { useState, useEffect } from 'react';
import dashboardCardIcon from '../assets/icon/dailywholesale_dash.png';
import TableLayout from '../components/TableLayout';
import { useFetchData } from '../hooks/useFetchData';
import { API_URL } from '../utils/constant';
import axios from 'axios';

// const customers = [];

const headers = [
  {
    key: 'product_name',
    name: 'Customer Name'
  },
  {
    key: 'barcode',
    name: 'Shop Name'
  },
  {
    key: 'total_qty',
    name: 'Phone'
  }
];

export function Dashboard() {
  const { data, setCurrentPage, setQuery, reload } = useFetchData({
    url: API_URL + '/dashboard/dashboard',
    defaultQuery: 'status=ACTIVE'
  });


  const [dashboard, setDashboard] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    axios.get(API_URL + '/dashboard/dashboard', {
      withCredentials: true
    })
      .then(response => {
        setDashboard(response.data.data)
      })
  }, [])

  useEffect(() => {
    axios.get(API_URL + '/dashboard/top_selling_products', {
      withCredentials: true
    })
      .then(response => {
        setProducts(response.data.data)
      })
  }, [])

  console.log(products)
  console.log(data)

  // useEffect(() => {
  //     axios.get(API_URL + '/', {
  //         headers: {
  //             'Authorization': `Bearer ${localData?.token}`
  //         },
  //         withCredentials: true
  //     })
  //         .then(response => {
  //             setAddress(response.data.data)
  //         })
  // }, [])

  return (
    <div className='home-layout' style={{ height: 'auto' }}>
      <h1 className='title'>Dashboard</h1>

      <div className='amount-stats-container'>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Daily Wholesale</h4>
          <p className='amount'>{dashboard?.dailyWholesale} MMK</p>
        </div>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Daily Retailsale</h4>
          <p className='amount'>{dashboard?.dailyRetailsale} MMK</p>
        </div>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Office Users</h4>
          <p className='amount'>{dashboard?.totalOfficeUser}</p>
        </div>
        {/* <div className='box'>
          <Link to={'/dashboard/weeklySaleDues'}>
            <img src={dashboardCardIcon} className='icon' />
            <h4 className='label'>{ formatMessage('common.weeklySaleDues') }</h4>
            <p className='amount'>{ formatCurrency(stats ? stats.dues.weekly : 0)}</p>
          </Link>

        </div> */}
      </div>

      <div className='amount-stats-container'>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Wholesales Customers</h4>
          <p className='amount'>{dashboard?.totalWholesaleCustomer}</p>
        </div>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Retail Customers</h4>
          <p className='amount'>{dashboard?.totalRetailCustomer}</p>
        </div>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Member Cards</h4>
          <p className='amount'>{dashboard?.totalMemberCard}</p>
        </div>
      </div>

      <div className='amount-stats-container'>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Product Category</h4>
          <p className='amount'>{dashboard?.totalProductCategory}</p>
        </div>
        <div className='box'>
          <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Product</h4>
          <p className='amount'>{dashboard?.totalProduct}</p>
        </div>
        <div className='box'>
          {/* <img src={dashboardCardIcon} className='icon' />
          <h4 className='label'>Total Office Users</h4>
          <p className='amount'>0</p> */}
        </div>
      </div>

      <h3 className='table-title'>Top Selling Items</h3>

      <div className="table-container">

        <table className='x-table'>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Barcode</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {
              products?.map((product) => {
                return (
                  <tr>
                    <td>{product.product_name}</td>
                    <td>{product.barcode}</td>
                    <td>{product.total_qty}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {/* <TableLayout
        subtitle="Top Selling Products"
        data={products}
        headers={headers}
        removeOptions={true}
        removePagination={true}
      /> */}
    </div>
  )
}