import { useFetchData } from "../../hooks/useFetchData";
import { useState, useEffect } from "react";
import { API_URL } from "../../utils/constant";
import { Link, useNavigate } from "react-router-dom";
import backArrowIcon from '../../assets/img/back-arrow.png';
import { useForm } from "../../hooks/useForm";
import { useAuth } from "../../routers/AuthProvider";
import axios from "axios";

const UserPermission = () => {
    const backPath = `/office-user/office-user-list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const { user, setLoading } = useAuth();
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const permissions = getCheckedValues();
        const data = {
            "role_id": roleId,
            permissions,
        }
        submitForm('POST', '/userPermission', data, () => {
            navigate(backPath);
        });
    }

    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const [roleName, setRoleName] = useState();
    // const localData = JSON.parse(localStorage.getItem('user')) ?? {};
    useEffect(() => {
        axios.get(API_URL + '/role?included=true&sort=role_level', {
            // headers: {
            //     'Authorization': `Bearer ${localData?.token}`
            // },
            withCredentials: true
        })
            .then(response => {
                setRoles(response.data.data)
            })
    }, [])

    const updateParentCheckbox = (childCheckbox) => {
        const parentCheckbox = document.querySelector("#" + childCheckbox.getAttribute("data-parent"));
        const childCheckboxes = parentCheckbox.parentElement.nextElementSibling.querySelectorAll("input[type='checkbox'][data-parent='" + parentCheckbox.getAttribute("id") + "']");
        let allChecked = true;

        for (let i = 0; i < childCheckboxes.length; i++) {
            if (!childCheckboxes[i].checked) {
                allChecked = false;
                break;
            }
        }
        parentCheckbox.checked = allChecked;
    }

    const updateChildCheckbox = (parentCheckbox) => {
        const childCheckboxes = parentCheckbox.parentElement.nextElementSibling.querySelectorAll("input[type='checkbox'][data-parent='" + parentCheckbox.getAttribute("id") + "']");

        for (let i = 0; i < childCheckboxes.length; i++) {
            childCheckboxes[i].checked = parentCheckbox.checked;
        }
    }

    const getCheckedValues = () => {
        const checkedCheckboxes = document.querySelectorAll("input[type='checkbox']:checked");
        const checkedValues = [];

        for (let i = 0; i < checkedCheckboxes.length; i++) {
            const dataParentAttribute = checkedCheckboxes[i].getAttribute('data-parent');
            if (dataParentAttribute !== null || checkedCheckboxes[i].getAttribute('data-children')) {
                checkedValues.push(checkedCheckboxes[i].id);
            }
        }

        return checkedValues;
    }

    useEffect(() => {
        const checkboxes = document.querySelectorAll('.permission_list input[type="checkbox"]')
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        const current = roles && roles.filter((role) => role.role_name === roleName)[0];
        console.log("roleName edited: ", roleName);
        setRoleId(current ? current.role_id : '');

        current && current.roleHasPermissions.forEach(permission => {
            const element = document.getElementById(permission.permission_id);

            if (element) {
                element.checked = true;
                if (!element.hasAttribute('data-children')) {
                    updateParentCheckbox(element);
                }
            }
        });
    }, [roleName, roles]);

    return (
        <div className="sub-page-layout-1">
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>User Permission</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label'>User Role</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='role_name'
                                        onChange={e => setRoleName(e.target.value)}
                                        defaultValue=''
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        {roles.length && roles.map(role => (
                                            <option value={role.role_name}>{role.role_name}</option>
                                        ))}
                                        {/* <option value='Admin'>Admin</option>
                                        <option value='Manager'>Manager</option>
                                        <option value='Accountant'>Accountant</option>
                                        <option value='Supervisor'>Supervisor</option>
                                        <option value='Sale Person'>Sale Person</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <ul className="permission_list">
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="0c5469b5-1c52-4ca8-adf3-f52086faf1d4" name="dashboard" data-children="false" />
                                        <label htmlFor="dashboard">Dashboard</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="office_user" name="office_user" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="office_user">Office User</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="4002e02a-df46-48a3-be1d-d59131a539ea" name="office_user_list" data-parent="office_user" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="office_user_list">Office User List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="07beae86-829b-4a18-858a-93f87d834c18" name="user_permission" data-parent="office_user" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="user_permission">User Permission</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="f6c74f14-1b68-47f1-8b25-8f7733343379" name="supplier" data-children="false" />
                                        <label htmlFor="supplier">Supplier</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="customer" name="customer" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="customer">Customer</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="44f20a2e-f4c7-462b-b780-480c7c63bca1" name="retail_customer" data-parent="customer" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="retail_customer">Retail Customer</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="c0e1b44b-a12a-404f-b1ef-5c8670f78c5a" name="wholesale_customer" data-parent="customer" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="wholesale_customer">Wholesale Customer</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="product" name="product" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="product">Product</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="d712e840-9189-4293-a595-17f0297911bc" name="product_category" data-parent="product" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="product_category">Product Category</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="bfb76e09-85ec-4168-a43f-699103d594dd" name="product_list" data-parent="product" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="product_list">Product List</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="unit" name="unit" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="unit">Unit</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="75f2d870-bd0a-41d0-a565-b70433c3e88e" name="unit_list" data-parent="unit" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="unit_list">Unit List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="82f4f773-6379-4f80-8703-3bc1a57a006f" name="unit_of_conversion" data-parent="unit" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="unit_of_conversion">Unit of Conversion</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="baa3f85e-e007-4309-9203-70d51b057a71" name="currency_exchange" data-children="false" />
                                        <label htmlFor="currency_exchange">Currency Exchange</label>
                                    </div>
                                </li>
                            </ul>
                            <ul className="permission_list">
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="31d6a527-44f2-495e-baf5-c1a4da10e01f" name="promotion" data-children="false" />
                                        <label htmlFor="promotion">Promotion</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="warehouse" name="warehouse" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="warehouse">Warehouse</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="35b49a7a-30bd-4c05-877c-8363658fe753" name="warehouse_list" data-parent="warehouse" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="warehouse_list">Warehouse List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="4095d596-fed7-46fe-a452-a148f9463783" name="adjustment" data-parent="warehouse" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="adjustment">Adjustment</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="292d65ab-d643-4143-b1b3-b4ff7d96cefd" name="inventory_transfer" data-parent="warehouse" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="inventory_transfer">Inventory Transfer</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="loyalty" name="loyalty" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="loyalty">Loyalty</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" 
                                            id="7107e691-7edc-11ee-ab46-0a6a4aeef9aa" 
                                            name="loyalty_list" data-parent="loyalty" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="loyalty_list">Loyalty List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" 
                                            id="765ed7ca-7edc-11ee-ab46-0a6a4aeef9aa" 
                                            name="gift_list" data-parent="loyalty" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="gift_list">Gift List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" 
                                            id="7c22874d-7edc-11ee-ab46-0a6a4aeef9aa" 
                                            name="gift_rule_list" data-parent="loyalty" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="gift_rule_list">Gift Rule List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" 
                                            id="8108816e-7edc-11ee-ab46-0a6a4aeef9aa" 
                                            name="gift_adjustment_list" data-parent="loyalty" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="gift_adjustment_list">Gift Adjustment List</label>
                                        </li>
                                       
                                        <li>
                                            <input type="checkbox" 
                                            id="894157ec-7edc-11ee-ab46-0a6a4aeef9aa"
                                            name="redeemed_gift_list" data-parent="loyalty" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="redeemed_gift_list">Redeemed Gift List</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" 
                                            id="8108816e-7edc-11ee-ab46-0a6a4aeef9aa" 
                                            name="point_rule_list" data-parent="loyalty" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="point_rule_list">Point Rule List</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="purchase" name="purchase" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="item1">Purchase</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="04b4b3e7-6963-4c6d-b568-06c3d2797f81" name="purchase_order" data-parent="purchase" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="purchase_order">Purchase Order</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="1c87d40d-1ed9-411c-8c5d-6a4362fe0425" name="purchase_arrival" data-parent="purchase" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="purchase_arrival">Purchase Arrival</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="sale" name="sale" onChange={(e) => updateChildCheckbox(e.target)} />
                                        <label htmlFor="sale">Sale</label>
                                    </div>
                                    <ul className="permission_item__children">
                                        <li>
                                            <input type="checkbox" id="718fd8a3-8eed-4b97-93dc-d75e55d0eafd" name="retail_sale_order" data-parent="sale" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="retail_sale_order">Retail Sale Order</label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="616517d0-9c22-474b-a92a-c4707872717b" name="wholesale_order" data-parent="sale" onChange={(e) => updateParentCheckbox(e.target)} />
                                            <label htmlFor="wholesale_order">Wholesale Order</label>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="permission_item__parent">
                                        <input type="checkbox" id="8dd64ae9-7edc-11ee-ab46-0a6a4aeef9aa" name="report" data-children="false" />
                                        <label htmlFor="report">Report</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default UserPermission;