import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../routers/AuthProvider';
import { useForm } from '../../hooks/useForm';
import backArrowIcon from '../../assets/img/back-arrow.png';
import '../../assets/css/common.css';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import InventoryTransferTable from '../../components/InventoryTransferTable';

const CreateInventoryTransfer = () => {
    const backPath = '/warehouse/inventory-transfer/list';
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();

    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault();

        const transfer_items = products.map(product => ({
            product_id: product.product_id,
            barcode: product.barcode,
            quantity: product.quantity
        }));

        const data = {
            ...formData,
            transfer_items,
            status: 'ACTIVE'
        }

        submitForm('POST', '/inventoryTransfer', data, () => {
            navigate(backPath)
        });
    }

    const [products, setProducts] = useState();
    const [warehouses, setWarehouses] = useState();

    useEffect(() => {
        axios.get(API_URL + '/warehouse?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setWarehouses(response.data.data);
            })
    }, []);

    const loadOptions = inputValue => {
        return axios.get(API_URL + '/product?status=ACTIVE', {
            withCredentials: true
        }).then(response => {
            return response.data.data.filter(option =>
                option.product_name.toLowerCase().includes(inputValue.toLowerCase())
            );
        });
    };

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Inventory Transfer</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Inventory Transfer Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer From</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='transfer_from'
                                        className='x-input dropdown'
                                        defaultValue=""
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled>Select</option>
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer To</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='transfer_to'
                                        className='x-input dropdown'
                                        defaultValue=""
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled>Select</option>
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Transfer Date</label>
                                <input type='date'
                                    required
                                    name='transfer_date'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Products</label>
                                <AsyncSelect
                                    defaultOptions
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    isSearchable={true}
                                    isMulti={true}
                                    getOptionLabel={option => option.product_name}
                                    getOptionValue={option => option.product_id}
                                    onChange={option => {
                                        const newObj = option.map(item => {
                                            const quantity = item.quantity ? item.quantity : 1
                                            return { ...item, quantity }
                                        })
                                        setProducts(newObj)
                                    }}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: '#1b1b1b66',
                                            minHeight: '50px',
                                        }),
                                    }}
                                />
                            </div>
                            {/* <div className='grow'>
                                <label className='x-label input-required'>Transfer Method</label>
                                <input type='text'
                                    required
                                    name='transfer_method'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div> */}
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <InventoryTransferTable products={products} setProducts={setProducts}/>
                        <div className='mt-8 form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
}

export default CreateInventoryTransfer;