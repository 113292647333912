import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import { useParams } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import { formatDateToDDMMYYYYhmmss } from '../../utils/format-time';

export default function EditCurrencyExchange() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/currency-exchange/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('PUT', `/currencyExchange/${id}`, data, () => {
            navigate(backPath)
        });
    }

    console.log(formatDateToDDMMYYYYhmmss(new Date(initialData.exchange_rate_date)));
    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Currency Exchange</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Thai Baht to Myanmar Kyat</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            {/* <div className='grow'>
                                <label className='x-label input-required'>THB</label>
                                <input type='text'
                                    required
                                    name='present_name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div> */}
                            {/* <div className='grow'>
                                <label className='x-label input-required'>MMK</label>
                                <input type='text'
                                    required
                                    name='stock'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div> */}
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Rate</label>
                                <input type='number'
                                    required
                                    name='exchange_rate'
                                    step={"any"}
                                    defaultValue={initialData.exchange_rate}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Date</label>
                                <input type='datetime-local'
                                    required
                                    name='exchange_rate_date'
                                    defaultValue={formatDateToDDMMYYYYhmmss(new Date(initialData.exchange_rate_date))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='form-divider'></div>
                        <h2 className='sub-title mb-8'>Myanmar Kyat to Thai Baht</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            {/* <div className='grow'>
                                <label className='x-label input-required'>MMK</label>
                                <input type='text'
                                    required
                                    name='present_name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>THB</label>
                                <input type='text'
                                    required
                                    name='stock'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div> */}
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Rate</label>
                                <input type='number'
                                    required
                                    step={"any"}
                                    name='reverse_exchange_rate'
                                    defaultValue={initialData.reverse_exchange_rate}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Exchange Date</label>
                                <input type='datetime-local'
                                    required
                                    name='reverse_exchange_rate_date'
                                    defaultValue={formatDateToDDMMYYYYhmmss((new Date(initialData.reverse_exchange_rate_date)))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>

                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}