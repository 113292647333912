export function checkISO8601Format(inputString) {
    var pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return pattern.test(inputString);
}

export function formatDateToDDMMYY(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
}

export function formatDateToYYYYMMDD(date) {
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDateToDDMMYYYYhmmssA(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are 0-based, so we add 1.
    const year = date.getFullYear();

    const hours = date.getHours() % 12 || 12; // Get the hour in 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    return formattedDateTime;
}

export function formatDateToDDMMYYYYhmmA(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are 0-based, so we add 1.
    const year = date.getFullYear();

    const hours = date.getHours() % 12 || 12; // Get the hour in 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    return formattedDateTime;
}

export function formatDateToDDMMYYYYhmmss(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are 0-based, so we add 1.
    const year = date.getFullYear();

    const hours = date.getHours() < 10 ? "0"+date.getHours(): date.getHours(); // Get the hour in 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
}

export function getCurrentDateTime() {
    const currentDate = new Date();
    return formatDateToDDMMYYYYhmmssA(currentDate)

    // const day = currentDate.getDate().toString().padStart(2, '0');
    // const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are 0-based, so we add 1.
    // const year = currentDate.getFullYear();

    // const hours = currentDate.getHours() % 12 || 12; // Get the hour in 12-hour format
    // const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    // const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    // const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    // const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    // return formattedDateTime;
}