import '../assets/css/invoicedetails.css'


const InvoiceDetails = (
    {
        total,
        currencyType,
        setCurrencyType,
        subTotal,
        taxEnabled,
        setTaxEnabled,
        tax,
        promotionAmount,
        setTax,
        taxAmount,
        discount,
        setDiscount,
        paidAmount,
        setPaidAmount,
        change,
        subTotalMMK,
        subTotalTHB,
        setDiscountInMMK,
        setDiscountInTHB,
        discountInMMK,
        discountInTHB,
        totalInMMK,
        totalInTHB,
        changeInTHB,
        changeInMMK,
        paidAmountInMMK,
        setPaidAmountInMMK,
        paidAmountInTHB,
        setPaidAmountInTHB,
        advancedInMMK,
        advancedInTHB,
        advancedEnabled,
        setAdvancedEnabled,
        taxAmountInMMK,
        taxAmountInTHB,
        setAdvancedInMMK,
        setAdvancedInTHB,
        promotionAmountInMMK,
        promotionAmountInTHB
    }) => {
    return (
        <section className='invoice-details'>
            <div>
                <label>Choose Currency</label>
                <div className='flex'>
                    <input type='radio' id='currencyMMK' name='currency' value='MMK' checked={currencyType === 'MMK'} onChange={e => setCurrencyType(e.target.value)} />
                    <label htmlFor='currencyMMK' className='pr-4'>MMK</label>
                    <input type='radio' id='currencyTHB' name='currency' value='THB' checked={currencyType === 'THB'} onChange={e => setCurrencyType(e.target.value)} />
                    <label htmlFor='currencyTHB' className='pr-4'>THB</label>
                    <input type='radio' id='currencySep' name='currency' value='SEPERATED' checked={currencyType === 'SEPERATED'} onChange={e => setCurrencyType(e.target.value)} />
                    <label htmlFor='currencySep' >Seperated</label>
                </div>
            </div>
            {currencyType != 'SEPERATED' ?
                <div>
                    <label htmlFor='subtotal'>Subtotal</label>
                    <span id='subtotal'>{subTotal}</span>
                </div>
                : null
            }

            {
                currencyType === 'SEPERATED' ?

                    <div >
                        <label htmlFor='subTotalMMK'>Subtotal In MMK</label>
                        <span id='subTotalMMK'>{subTotalMMK}</span>
                    </div>
                    : null
            }
            {
                currencyType === 'SEPERATED' ?

                    <div >
                        <label htmlFor='subTotalTHB'>Subtotal In THB</label>
                        <span id='subTotalTHB'>{subTotalTHB}</span>
                    </div>
                    : null
            }
            {
                currencyType != 'SEPERATED' ?
                    <div>
                        <label htmlFor='discountAmount'>Discount</label>
                        <input type='number' id='discountAmount' value={discount} min={0} onChange={e => setDiscount(parseFloat(e.target.value))} />
                    </div>
                    : null
            }

            {
                currencyType === 'SEPERATED' ?

                    <div>
                        <label htmlFor='discountInMMK'>Discount In MMK</label>
                        <input type='number' id='discountInMMK' value={discountInMMK} min={0} onChange={e => setDiscountInMMK(parseFloat(e.target.value))} />
                    </div>


                    : null
            }
            {
                currencyType === 'SEPERATED' ?

                    <div>
                        <label htmlFor='discountInTHB'>Discount In THB</label>
                        <input type='number' id='discountInTHB' value={discountInTHB} min={0} onChange={e => setDiscountInTHB(parseFloat(e.target.value))} />
                    </div>


                    : null
            }

            {
                currencyType != 'SEPERATED' ?
                    <div>
                        <label htmlFor='promotionAmount'>Promotion Amount</label>
                        <input type='number' disabled readOnly id='promotionAmount' value={promotionAmount} min={0} onChange={e => setDiscount(parseFloat(e.target.value))} />
                    </div>
                    : null
            }

            {
                currencyType === 'SEPERATED' ?
                    <div>
                        <label htmlFor='promotionAmountInMMK'>Promotion Amount In MMK</label>
                        <input type='number' disabled readOnly id='promotionAmountInMMK' value={promotionAmountInMMK} min={0} onChange={e => setDiscount(parseFloat(e.target.value))} />
                    </div>
                    : null
            }

            {
                currencyType === 'SEPERATED' ?
                    <div>
                        <label htmlFor='promotionAmountInTHB'>Promotion Amount In THB</label>
                        <input type='number' disabled readOnly id='promotionAmountInTHB' value={promotionAmountInTHB} min={0} onChange={e => setDiscount(parseFloat(e.target.value))} />
                    </div>
                    : null
            }

            <div className='flex flex-row'>
                <div>
                    <label htmlFor='tax'>Tax</label>
                    <input type='checkbox' id='tax' name='tax' value={taxEnabled} onChange={e => setTaxEnabled(e.target.checked ? 1 : 0)} />
                </div>
                <div>
                    <input type='number' id='taxPercent' onChange={e => setTax(parseFloat(e.target.value))} min={0} max={100} value={tax} />
                    <label htmlFor='taxPercent'>%</label>
                </div>
            </div>
            {
                currencyType !== "SEPERATED" ?
                    <div>
                        <label htmlFor='taxAmount'>Tax Amount</label>
                        <span id='taxAmount'>{isNaN(taxAmount) ? 0 : taxAmount}</span>
                    </div>
                    : null
            }

            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='taxAmountInMMK'>Tax Amount In MMK</label>
                        <span id='taxAmountInMMK'>{isNaN(taxAmountInMMK) ? 0 :taxAmountInMMK}</span>
                    </div>
                    : null
            }
            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='taxAmountInTHB'>Tax Amount In THB</label>
                        <span id='taxAmountInTHB'>{isNaN(taxAmountInTHB) ? 0 :taxAmountInTHB }</span>
                    </div>
                    : null

            }

            {
                
                <div className='flex flex-row'>
                    <div>
                        <label htmlFor='advanced'>Adv</label>
                        <input type='checkbox' id='advanced' name='advanced' value={advancedEnabled} onChange={e => setAdvancedEnabled(e.target.checked ? 1 : 0)} />
                    </div>
                    </div>
                   
            }


            {
                (currencyType === "SEPERATED" |  currencyType === "MMK")?
                    <div>
                        <label htmlFor='advancedInMMK'>Advanced In MMK</label>
                        <input type='number' max={`${advancedInMMK}`} id='advancedInMMK' disabled={!advancedEnabled} value={advancedInMMK} min={0} onChange={e => setAdvancedInMMK(parseFloat(e.target.value))} />
                    </div>
                    : null
            }
            {
               ( currencyType === "SEPERATED" |  currencyType === "THB" )?
                    <div>
                        <label htmlFor='advancedInTHB'>Advanced In THB</label>
                        <input type='number' max={`${advancedInTHB}`} id='advancedInTHB' disabled={!advancedEnabled} value={advancedInTHB} min={0} onChange={e => setAdvancedInTHB(parseFloat(e.target.value))} />
                    </div>
                    : null
            }



            {
                currencyType !== "SEPERATED" ?
                    <div>
                        <label htmlFor='totalAmount'>Total Amount</label>
                        <span id='totalAmount'>{total}</span>
                    </div>
                    : null
            }

            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='totalAmount'>Total Amount In MMK</label>
                        <span id='totalAmount'>{totalInMMK}</span>
                    </div>
                    : null
            }

            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='totalAmount'>Total Amount In THB</label>
                        <span id='totalAmount'>{totalInTHB}</span>
                    </div>
                    : null
            }
            {
                currencyType !== "SEPERATED" ?
                    <div>
                        <label htmlFor='paidAmountInMMK'>Paid Amount</label>
                        <input type='number'  value={paidAmount} onChange={e => setPaidAmount(parseFloat(e.target.value))} />
                    </div>
                    : null
            }
            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='paidAmountInMMK'>Paid Amount In MMK</label>
                        <input type='number'  value={paidAmountInMMK} onChange={e => setPaidAmountInMMK(parseFloat(e.target.value))} />
                    </div>
                    : null
            }

            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='paidAmountInTHB'>Paid Amount In THB</label>
                        <input type='number'  value={paidAmountInTHB} onChange={e => setPaidAmountInTHB(parseFloat(e.target.value))} />
                    </div>
                    : null
            }

            {
                currencyType !== "SEPERATED" ?
                    <div>
                        <label htmlFor='charge'>Change</label>
                        <span id='charge'>{change}</span>
                    </div>
                    : null
            }

            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='charge'>Change In MMK</label>
                        <span id='charge'>{isNaN(changeInMMK) ? 0 : changeInMMK}</span>
                    </div>
                    : null
            }

            {
                currencyType === "SEPERATED" ?
                    <div>
                        <label htmlFor='charge'>Change In THB</label>
                        <span id='charge'>{isNaN(changeInTHB) ? 0 : changeInTHB}</span>
                    </div>
                    : null
            }

        </section>
    );
}

export default InvoiceDetails;