import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backArrowIcon from '../../assets/img/back-arrow.png';
import { formatDateToYYYYMMDD } from '../../utils/format-time';
import printIcon from '../../assets/icon/icon-print.png';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import * as XLSX from 'xlsx';

const YearlySaleReport = () => {
    const backPath = '/report/list';
    const [reportYear, setReportYear] = useState(2023);
    const [data, setData] = useState([]);

    useEffect(() => {
        let url = API_URL + `/report/yearly_sale?year=${reportYear}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData(response.data.data)
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [reportYear]);

    const calculateTotal = (data, key) => {
        let total = 0;

        for (const index of data) {
            total += index[key];
        }
        return total;
    }

    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <div className="flex justify-between text-3xl">
                    <h1 className='sub-page-title'>Yearly Sale Report</h1>
                    <div className="flex">
                        <input
                            required
                            name="report_year"
                            value={reportYear}
                            onChange={e => setReportYear(e.target.value)}
                            className="x-input mr-3"
                            type="number"
                        />
                        <button style={{ backgroundColor: "gold" }} className="header-img-button" onClick={() => {
                            handleExportClick(`Yearly_Sale_Report_for_${reportYear}.xlsx`)
                        }}>
                            <img src={printIcon} />
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="x-table" id="xlsx-export">
                        <thead>
                            <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                                <th className="hidden" colSpan={3}></th>
                                <th className="hidden">Year</th>
                                <th className="hidden">{reportYear}</th>

                            </tr>
                            <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                                <th className="hidden" colSpan={2}></th>
                            </tr>
                            <tr>
                                <th>Month</th>
                                <th>Retail Sales MMK</th>
                                <th>Retail Sales THB</th>
                                <th>Wholesale MMK</th>
                                <th>Wholesale THB</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{item["month_name"]}</td>
                                        <td>{item["retail_sale_mmk"] ?? 0}</td>
                                        <td>{item["retail_sale_thb"] ?? 0}</td>
                                        <td>{item["wholesale_mmk"] ?? 0}</td>
                                        <td>{item["wholesale_thb"] ?? 0}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{fontWeight: "bold"}}>Total Amount</td>
                            <td style={{fontWeight: "bold"}}>{data && calculateTotal(data, "retail_sale_mmk")}</td>
                            <td style={{fontWeight: "bold"}}>{data && calculateTotal(data, "retail_sale_thb")}</td>
                            <td style={{fontWeight: "bold"}}>{data && calculateTotal(data, "wholesale_mmk")}</td>
                            <td style={{fontWeight: "bold"}}>{data && calculateTotal(data, "wholesale_thb")}</td>
                        </tr>
                    </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default YearlySaleReport;