import { Dashboard } from "./pages/Dashboard";
import dashboardIcon from "./assets/icon/icon-dashboard.png";
import userIcon from "./assets/icon/icon-user.png";
import customerIcon from './assets/icon/icon-member card.png';
import loyaltyIcon from './assets/icon/icon-loyalty.png';
import promotionIcon from './assets/icon/icon-promotion.png';
import warehouseIcon from './assets/icon/icon-Default.png';
import purchaseIcon from './assets/icon/icon-purchase.png';
import saleIcon from './assets/icon/icon-sale.png';
import currencyIcon from './assets/icon/icon-currency.png';
import unitIcon from './assets/icon/icon-unit.png';
import productIcon from './assets/icon/icon-product.png';
import reportIcon from './assets/icon/icon-report.png';
import { OfficeUserList } from "./pages/OfficeUser/OfficeUserList";
import SupplierList from "./pages/Supplier/SupplierList";
import RetailCustomerList from "./pages/RetailCustomer/RetailCustomerList";
import WholesaleCustomerList from "./pages/WholesaleCustomer/WholesaleCustomerList";
import CreateOfficeUser from "./pages/OfficeUser/CreateOfficeUser";
import EditOfficeUser from "./pages/OfficeUser/EditOfficeUser";
import CreateRetailCustomer from "./pages/RetailCustomer/CreateRetailCustomer";
import EditRetailCustomer from "./pages/RetailCustomer/EditRetailCustomer";
import CreateWholesaleCustomer from "./pages/WholesaleCustomer/CreateWholesaleCustomer";
import EditWholesaleCustomer from "./pages/WholesaleCustomer/EditWholesaleCustomer";
import ProductCategoryList from "./pages/ProductCategory/ProductCategoryList";
import CreateProductCategory from "./pages/ProductCategory/CreateProductCategory";
import EditProductCategory from "./pages/ProductCategory/EditProductCategory";
import ProductList from "./pages/Product/ProductList";
import CreateProduct from "./pages/Product/CreateProduct";
import EditProduct from "./pages/Product/EditProduct";
import UnitList from "./pages/Unit/UnitList";
import CreateUnit from "./pages/Unit/CreateUnit";
import EditUnit from "./pages/Unit/EditUnit";
import UnitConversionList from "./pages/UnitConversion/UnitConversionList";
import CreateUnitConversion from "./pages/UnitConversion/CreateUnitConversion";
import CreateSupplier from "./pages/Supplier/CreateSupplier";
import EditSupplier from "./pages/Supplier/EditSupplier";
import EditUnitConversion from "./pages/UnitConversion/EditUnitConversion";
import CreateWarehouse from "./pages/Warehouse/CreateWarehouse";
import EditWarehouse from "./pages/Warehouse/EditWarehouse";
import WarehouseList from "./pages/Warehouse/WarehouseList";
import AdjustmentList from "./pages/Adjustment/AdjustmentList";
import CreateAdjustment from "./pages/Adjustment/CreateAdjustment";
import EditAdjustment from "./pages/Adjustment/EditAdjustment";
import InventoryTransferList from "./pages/InventoryTransfer/InventoryTransferList";
import CreateInventoryTransfer from "./pages/InventoryTransfer/CreateInventoryTransfer";
import EditInventoryTransfer from "./pages/InventoryTransfer/EditInventoryTransfer";
import PromotionList from "./pages/Promotion/PromotionList";
import CreatePromotion from "./pages/Promotion/CreatePromotion";
import EditPromotion from "./pages/Promotion/EditPromotion";
import LoyaltyList from "./pages/Loyalty/LoyaltyList";
import CreateLoyalty from "./pages/Loyalty/CreateLoyalty";
import EditLoyalty from "./pages/Loyalty/EditLoyalty";
import GiftList from "./pages/Gift/GiftList";
import EditGift from "./pages/Gift/EditGift";
import CreateGift from "./pages/Gift/CreateGift";
import GiftRuleList from "./pages/GiftRule/GiftRuleList";
import CreateGiftRule from "./pages/GiftRule/CreateGiftRule";

import CreatePointRule from "./pages/PointRule/CreatePointRule";
import PointRuleList from "./pages/PointRule/PointRuleList";
import EditPointRule from "./pages/PointRule/EditPointRule";
import EditGiftRule from "./pages/GiftRule/EditGiftRule";
import GiftAdjustmentList from "./pages/GiftAdjustment/GiftAdjustmentList";
import CreateGiftAdjustment from "./pages/GiftAdjustment/CreateGiftAdjustment";
import EditGiftAdjustment from "./pages/GiftAdjustment/EditGiftAdjustment";
import RedeemedGiftList from "./pages/RedeemedGift/RedeemedGiftList";
import CreateRedeemedGift from "./pages/RedeemedGift/CreateRedeemedGift";
import EditRedeemedGift from "./pages/RedeemedGift/EditRedeemedGift";
import PurchaseOrderList from "./pages/PurchaseOrder/PurchaseOrderList";
import CreatePurchaseOrder from "./pages/PurchaseOrder/CreatePurchaseOrder";
import EditPurchaseOrder from "./pages/PurchaseOrder/EditPurchaseOrder";
import PurchaseArrivalList from "./pages/PurchaseArrival/PurchaseArrivalList";
import CreatePurchaseArrival from "./pages/PurchaseArrival/CreatePurchaseArrival";
import EditPurchaseArrival from "./pages/PurchaseArrival/EditPurchaseArrival";
import RetailSalesOrderList from "./pages/RetailSalesOrder/RetailSalesOrderList";
import CreateRetailSalesOrder from "./pages/RetailSalesOrder/CreateRetailSalesOrder";
// import CreateRetailSalesOrderVoucher from "./pages/RetailSalesOrder/CreateRetailSalesOrderVoucher";
import WholesaleOrderList from "./pages/WholesaleOrder/WholesaleOrderList";
import CreateWholesaleOrder from "./pages/WholesaleOrder/CreateWholesaleOrder";
// import CreateWholesaleOrderVoucher from "./pages/WholesaleOrder/CreateWholesaleOrderVoucher";
import CurrencyExchangeList from "./pages/CurrencyExchange/CurrencyExchangeList";
import CreateCurrencyExchange from "./pages/CurrencyExchange/CreateCurrencyExchange";
import EditCurrencyExchange from "./pages/CurrencyExchange/EditCurrencyExchange";
import UserPermission from "./pages/OfficeUser/UserPermission";
import ReportList from "./pages/Report/ReportList";
import RetailSaleReport from "./pages/Report/RetailSaleReport";
import WholesaleReport from "./pages/Report/WholesaleReport";
import StockAdjustmentReport from "./pages/Report/StockAdjustmentReport";
import StockExpiredReport from "./pages/Report/StockExpiredReport";
import PointRedemptionReport from "./pages/Report/PointRedemptionReport";
import OfficeUserReport from "./pages/Report/OfficeUserReport";
import YearlySaleReport from "./pages/Report/YearlySaleReport";
import WarehouseReport from "./pages/Report/WarehouseReport";
import WarehouseStockReport from "./pages/Report/WarehouseStockReport";
import StockPurchaseReport from "./pages/Report/StockPurchaseReport";
import RetailCustomerReport from "./pages/Report/RetailCustomerReport";
import WholesaleCustomerReport from "./pages/Report/WholesaleCustomerReport";

const routes = [
    {
        title: "Dashboard",
        key: "dashboard",
        icon: dashboardIcon,
        path: "/dashboard",
        permissionName: "Dashboard",
        component: <Dashboard />,
    },
    {
        title: "Office User",
        key: "office-user",
        icon: userIcon,
        collapse: true,
        children: [
            {
                title: "Office User List",
                key: "office-user",
                icon: "",
                permissionName: "Office User List",
                children: [
                    {
                        title: "Office User List",
                        key: "office-user-list-view",
                        icon: "",
                        path: "/office-user/office-user-list",
                        component: <OfficeUserList />,
                    },
                    {
                        title: "Create Office User",
                        key: "create-office-user",
                        icon: "",
                        path: "/office-user/create",
                        component: <CreateOfficeUser />,
                    },
                    {
                        title: "Edit Office User",
                        key: "edit-office-user",
                        icon: "",
                        path: "/office-user/office-user-list/:id",
                        component: <EditOfficeUser />
                    }
                ]
            },
            {
                title: "User Permission",
                key: "user-permission",
                icon: "",
                path: "/office-user/user-permission",
                permissionName: "User Permission",
                component: <UserPermission />,
            },
        ]
    },
    {
        title: "Supplier",
        key: "supplier",
        icon: userIcon,
        permissionName: "Supplier",
        children: [
            {
                title: "Supplier",
                key: "supplier",
                icon: '',
                path: "/supplier",
                component: <SupplierList />,
            },
            {
                title: "Create Supplier",
                key: "create_supplier",
                icon: '',
                path: "/supplier/create",
                component: <CreateSupplier />,
            },
            {
                title: "Edit Supplier",
                key: "edit_supplier",
                icon: '',
                path: "/supplier/:id",
                component: <EditSupplier />,
            },
        ]
    },
    {
        title: "Customer",
        key: "customer",
        icon: customerIcon,
        collapse: true,
        children: [
            {
                title: "Retail Customer",
                key: "retail_customer",
                icon: "",
                permissionName: "Retail Customer",
                children: [
                    {
                        title: "Retail Customer",
                        key: "retail_customer",
                        icon: "",
                        path: "/customer/retail",
                        component: <RetailCustomerList />,
                    },
                    {
                        title: "Create Retail Customer",
                        key: "create_retail_customer",
                        icon: "",
                        path: "/customer/retail/create",
                        component: <CreateRetailCustomer />,
                    },
                    {
                        title: "Edit Retail Customer",
                        key: "edit_retail_customer",
                        icon: "",
                        path: "/customer/retail/:id",
                        component: <EditRetailCustomer />,
                    },
                ]
            },
            {
                title: "Wholesale Customer",
                key: "wholesale_customer",
                icon: "",
                permissionName: "Wholesale Customer",
                children: [
                    {
                        title: "Wholesale Customer",
                        key: "wholesale_customer",
                        icon: "",
                        path: "/customer/wholesale",
                        component: <WholesaleCustomerList />,
                    },
                    {
                        title: "Create Wholesale Customer",
                        key: "create_wholesale_customer",
                        icon: "",
                        path: "/customer/wholesale/create",
                        component: <CreateWholesaleCustomer />,
                    },
                    {
                        title: "Edit Wholesale Customer",
                        key: "edit_wholesale_customer",
                        icon: "",
                        path: "/customer/wholesale/:id",
                        component: <EditWholesaleCustomer />,
                    },
                ]
            },
        ]
    },
    {
        title: "Product",
        key: "product",
        icon: productIcon,
        collapse: true,
        children: [
            {
                title: "Product Category",
                key: "product_category",
                icon: "",
                permissionName: "Product Category",
                children: [
                    {
                        title: "Product Category List",
                        key: "product_category",
                        icon: "",
                        path: "/product/category",
                        component: <ProductCategoryList />,
                    },
                    {
                        title: "Create Product Category",
                        key: "create_product_category",
                        icon: "",
                        path: "/product/category/create",
                        component: <CreateProductCategory />,
                    },
                    {
                        title: "Edit Product Category",
                        key: "edit_product_category",
                        icon: "",
                        path: "/product/category/:id",
                        component: <EditProductCategory />,
                    },
                ]
            },
            {
                title: "Product List",
                key: "product_list",
                icon: "",
                permissionName: "Product List",
                children: [
                    {
                        title: "Product List",
                        key: "product_list",
                        icon: "",
                        path: "/product/list",
                        component: <ProductList />,
                    },
                    {
                        title: "Create Product",
                        key: "create_product",
                        icon: "",
                        path: "/product/list/create",
                        component: <CreateProduct />,
                    },
                    {
                        title: "Edit Product",
                        key: "edit_product",
                        icon: "",
                        path: "/product/list/:id",
                        component: <EditProduct />,
                    },
                ]
            },
        ]
    },
    {
        title: "Unit",
        key: "unit",
        icon: unitIcon,
        collapse: true,
        children: [
            {
                title: "Unit List",
                key: "unit_list",
                icon: "",
                permissionName: "Unit List",
                children: [
                    {
                        title: "Unit List",
                        key: "unit_list",
                        icon: "",
                        path: "/unit/list",
                        component: <UnitList />,
                    },
                    {
                        title: "Create Unit",
                        key: "create_unit",
                        icon: "",
                        path: "/unit/list/create",
                        component: <CreateUnit />,
                    },
                    {
                        title: "Edit Unit",
                        key: "edit_unit",
                        icon: "",
                        path: "/unit/list/:id",
                        component: <EditUnit />,
                    },
                ]
            },
            {
                title: "Unit of Conversion",
                key: "unit_conversion",
                icon: "",
                permissionName: "Unit of Conversion",
                children: [
                    {
                        title: "Unit of Conversion",
                        key: "unit_conversion",
                        icon: "",
                        path: "/unit/conversion",
                        component: <UnitConversionList />,
                    },
                    {
                        title: "Create Unit of Conversion",
                        key: "create_unit_conversion",
                        icon: "",
                        path: "/unit/conversion/create",
                        component: <CreateUnitConversion />,
                    },
                    {
                        title: "Edit Unit of Conversion",
                        key: "edit_unit_conversion",
                        icon: "",
                        path: "/unit/conversion/:id",
                        component: <EditUnitConversion />,
                    },
                ]
            },
        ]
    },
    {
        title: "Currency Exchange",
        key: "currency_exchange",
        icon: currencyIcon,
        permissionName: "Currency Exchange",
        children: [
            {
                title: "Currency Exchange List",
                key: "currency_exchange_list",
                icon: "",
                path: "/currency-exchange/list",
                component: <CurrencyExchangeList />,
            },
            {
                title: "Create Currency Exchange List",
                key: "create_currency_exchange_list",
                icon: "",
                path: "/currency-exchange/list/create",
                component: <CreateCurrencyExchange />,
            },
            {
                title: "Edit Currency Exchange List",
                key: "edit_currency_exchange_list",
                icon: "",
                path: "/currency-exchange/list/:id",
                component: <EditCurrencyExchange />,
            }
        ]
    },
    {
        title: "Warehouse",
        key: "warehouse",
        icon: warehouseIcon,
        collapse: true,
        children: [
            {
                title: "Warehouse List",
                key: "warehouse_list",
                icon: "",
                permissionName: "Warehouse List",
                children: [
                    {
                        title: "Warehouse List",
                        key: "warehouse_list",
                        icon: "",
                        path: "/warehouse/list",
                        component: <WarehouseList />,
                    },
                    {
                        title: "Create Warehouse List",
                        key: "create_warehouse_list",
                        icon: "",
                        path: "/warehouse/list/create",
                        component: <CreateWarehouse />,
                    },
                    {
                        title: "Edit Warehouse List",
                        key: "edit_warehouse_list",
                        icon: "",
                        path: "/warehouse/list/:id",
                        component: <EditWarehouse />,
                    }
                ]
            },
            {
                title: "Adjustment",
                key: "adjustment",
                icon: "",
                permissionName: "Adjustment",
                children: [
                    {
                        title: "Adjustment",
                        key: "adjustment",
                        icon: "",
                        path: "/warehouse/adjustment/list",
                        component: <AdjustmentList />,
                    },
                    {
                        title: "Create Adjustment List",
                        key: "create_adjustment_list",
                        icon: "",
                        path: "/warehouse/adjustment/list/create",
                        component: <CreateAdjustment />,
                    },
                    {
                        title: "Edit Adjustment List",
                        key: "edit_adjustment_list",
                        icon: "",
                        path: "/warehouse/adjustment/list/:id",
                        component: <EditAdjustment />,
                    }
                ]
            },
            {
                title: "Inventory Transfer",
                key: "inventory_transfer",
                icon: "",
                permissionName: "Inventory Transfer",
                children: [
                    {
                        title: "Inventory Transfer",
                        key: "inventory_transfer",
                        icon: "",
                        path: "/warehouse/inventory-transfer/list",
                        component: <InventoryTransferList />,
                    },
                    {
                        title: "Create Inventory Transfer List",
                        key: "create_inventory-transfer_list",
                        icon: "",
                        path: "/warehouse/inventory-transfer/list/create",
                        component: <CreateInventoryTransfer />,
                    },
                    {
                        title: "Edit Inventory Transfer List",
                        key: "edit_inventory-transfer_list",
                        icon: "",
                        path: "/warehouse/inventory-transfer/list/:id",
                        component: <EditInventoryTransfer />,
                    }
                ]
            }
        ]
    },
    {
        title: "Promotion",
        key: "promotion",
        icon: promotionIcon,
        permissionName: "Promotion",
        children: [
            {
                title: "Promotion List",
                key: "promotion_list",
                icon: "",
                path: "/promotion/list",
                component: <PromotionList />,
            },
            {
                title: "Create Promotion List",
                key: "create_promotion_list",
                icon: "",
                path: "/promotion/list/create",
                component: <CreatePromotion />,
            },
            {
                title: "Edit Promotion List",
                key: "edit_promotion_list",
                icon: "",
                path: "/promotion/list/:id",
                component: <EditPromotion />,
            }
        ]
    },
    {
        title: "Loyalty",
        key: "loyalty",
        icon: loyaltyIcon,
        collapse: true,
        permissionName: "Loyalty",
        children: [
            {
                title: "Loyalty List",
                key: "loyalty_list",
                icon: "",
                permissionName: "Loyalty List",
                children: [
                    {
                        title: "Loyalty List",
                        key: "loyalty_list",
                        icon: "",
                        path: "/loyalty/list",
                        component: <LoyaltyList />,
                    },
                    {
                        title: "Create Loyalty List",
                        key: "create_loyalty_list",
                        icon: "",
                        path: "/loyalty/list/create",
                        component: <CreateLoyalty />,
                    },
                    {
                        title: "Edit Loyalty List",
                        key: "edit_loyalty_list",
                        icon: "",
                        path: "/loyalty/list/:id",
                        component: <EditLoyalty />,
                    }
                ]
            },
            {
                title: "Gift",
                key: "gift",
                icon: "",
                permissionName: "Gift",
                children: [
                    {
                        title: "Gift List",
                        key: "gift_list",
                        icon: "",
                        path: "/loyalty/gift/list",
                        component: <GiftList />,
                    },
                    {
                        title: "Create Gift List",
                        key: "create_gift_list",
                        icon: "",
                        path: "/loyalty/gift/list/create",
                        component: <CreateGift />,
                    },
                    {
                        title: "Edit Gift List",
                        key: "edit_gift_list",
                        icon: "",
                        path: "/loyalty/gift/list/:id",
                        component: <EditGift />,
                    }
                ]
            },
            {
                title: "Gift Rules",
                key: "gift_rules",
                icon: "",
                permissionName: "Gift Rules",
                children: [
                    {
                        title: "Gift Rule List",
                        key: "gift_rule_list",
                        icon: "",
                        path: "/loyalty/gift-rule/list",
                        component: <GiftRuleList />,
                    },
                    {
                        title: "Create Gift Rule List",
                        key: "create_gift_rule_list",
                        icon: "",
                        path: "/loyalty/gift-rule/list/create",
                        component: <CreateGiftRule />,
                    },
                    {
                        title: "Edit Gift List",
                        key: "edit_gift_rule_list",
                        icon: "",
                        path: "/loyalty/gift-rule/list/:id",
                        component: <EditGiftRule />,
                    }
                ]
            },
            {
                title: "Gift Adjustment",
                key: "gift_adjustment",
                icon: "",
                permissionName: "Gift Adjustment",
                children: [
                    {
                        title: "Gift Adjustment List",
                        key: "gift_adjustment_list",
                        icon: "",
                        path: "/loyalty/gift-adjustment/list",
                        component: <GiftAdjustmentList />,
                    },
                    {
                        title: "Create Gift Adjustment List",
                        key: "create_gift_adjustment_list",
                        icon: "",
                        path: "/loyalty/gift-adjustment/list/create",
                        component: <CreateGiftAdjustment />,
                    },
                    {
                        title: "Edit Gift Adjustment List",
                        key: "edit_gift_adjustment_list",
                        icon: "",
                        path: "/loyalty/gift-adjustment/list/:id",
                        component: <EditGiftAdjustment />,
                    }
                ]
            },
            {
                title: "Redeemed Gift",
                key: "redeemed_gift",
                icon: "",
                permissionName: "Redeemed Gift",
                children: [
                    {
                        title: "Redeemed Gift List",
                        key: "redeemed_gift_list",
                        icon: "",
                        path: "/loyalty/redeemed-gift/list",
                        component: <RedeemedGiftList />,
                    },
                    {
                        title: "Create Redeemed Gift",
                        key: "create_redeemed_gift_list",
                        icon: "",
                        path: "/loyalty/redeemed-gift/list/create",
                        component: <CreateRedeemedGift />,
                    },
                    {
                        title: "Edit Redeemed Gift",
                        key: "edit_redeemed_gift_list",
                        icon: "",
                        path: "/loyalty/redeemed-gift/list/:id",
                        component: <EditRedeemedGift />,
                    }
                ]
            },
            {
                title: "Point Rules",
                key: "point_rules",
                icon: "",
                permissionName: "Point Rules",
                children: [
                    {
                        title: "Point Rule List",
                        key: "point_rule_list",
                        icon: "",
                        path: "/loyalty/point-rule/list",
                        component: <PointRuleList />,
                    },
                    {
                        title: "Create Point Rule List",
                        key: "create_point_rule_list",
                        icon: "",
                        path: "/loyalty/point-rule/list/create",
                        component: <CreatePointRule />,
                    },
                    {
                        title: "Edit Point List",
                        key: "edit_point_rule_list",
                        icon: "",
                        path: "/loyalty/point-rule/list/:id",
                        component: <EditPointRule />,
                    }
                ]
            },
        ]
    },
    {
        title: "Purchase",
        key: "purchase",
        icon: purchaseIcon,
        collapse: true,
        children: [
            {
                title: "Purchase Order",
                key: "purchase_order",
                icon: "",
                permissionName: "Purchase Order",
                children: [
                    {
                        title: "Purchase Order List",
                        key: "purchase_order_list",
                        icon: "",
                        path: "/purchase/purchase-order/list",
                        component: <PurchaseOrderList />,
                    },
                    {
                        title: "Create Purchase Order",
                        key: "create_purchase_order_list",
                        icon: "",
                        path: "/purchase/purchase-order/list/create",
                        component: <CreatePurchaseOrder />,
                    },
                    {
                        title: "Edit Purchase Order",
                        key: "edit_purchase_order_list",
                        icon: "",
                        path: "/purchase/purchase-order/list/:id",
                        component: <EditPurchaseOrder />,
                    }
                ]
            },
            {
                title: "Purchase Arrival",
                key: "purchase_arrival",
                icon: "",
                permissionName: "Purchase Arrival",
                children: [
                    {
                        title: "Purchase Arrival List",
                        key: "purchase_arrival_list",
                        icon: "",
                        path: "/purchase/purchase-arrival/list",
                        component: <PurchaseArrivalList />,
                    },
                    {
                        title: "Create Purchase Arrival",
                        key: "create_purchase_arrival_list",
                        icon: "",
                        path: "/purchase/purchase-arrival/list/create",
                        component: <CreatePurchaseArrival />,
                    },
                    {
                        title: "Edit Purchase Arrival",
                        key: "edit_purchase_arrival_list",
                        icon: "",
                        path: "/purchase/purchase-arrival/list/:id",
                        component: <EditPurchaseArrival />,
                    }
                ]
            },
        ]
    },
    {
        title: "Sale",
        key: "sale",
        icon: saleIcon,
        collapse: true,
        children: [
            {
                title: "Retail Sale Order",
                key: "retail_sale_order",
                icon: "",
                permissionName: "Retail Sale Order",
                children: [
                    {
                        title: "Retail Sales Order",
                        key: "retail_sales_order_list",
                        icon: "",
                        path: "/sale/retail-sales-order/list",
                        component: <RetailSalesOrderList />,
                    },
                    {
                        title: "Create Retail Sales Order",
                        key: "create_retail_sales_order_list",
                        icon: "",
                        path: "/sale/retail-sales-order/list/create",
                        component: <CreateRetailSalesOrder />,
                    },
                    // {
                    //     title: "Edit Retail Sales Order",
                    //     key: "edit_retail_sales_order_list",
                    //     icon: "",
                    //     path: "/sale/retail-sales-order/list/member",
                    //     component: <CreateRetailSalesOrderVoucher />,
                    // }
                ]
            },
            {
                title: "Wholesale Order",
                key: "wholesale_order",
                icon: "",
                permissionName: "Wholesale Order",
                children: [
                    {
                        title: "Wholesale Order",
                        key: "wholesale_order_list",
                        icon: "",
                        path: "/sale/wholesale-order/list",
                        component: <WholesaleOrderList />,
                    },
                    {
                        title: "Create Wholesale Order",
                        key: "create_wholesale_order_list",
                        icon: "",
                        path: "/sale/wholesale-order/list/create",
                        component: <CreateWholesaleOrder />,
                    },
                ]
            },
        ]
    },
    {
        title: "Report",
        key: "report",
        icon: reportIcon,
        permissionName: "Report",
        children: [
            {
                title: "Report List",
                key: "report_list",
                icon: "",
                path: "/report/list",
                component: <ReportList />,
            },
            {
                title: "Office User Report",
                key: "office_user_report",
                icon: "",
                path: "/report/office-user",
                component: <OfficeUserReport/>,
            },
            {
                title: "Retail Sales Report",
                key: "retail_sales_report",
                icon: "",
                path: "/report/retail-sales",
                component: <RetailSaleReport />,
            },
            {
                title: "Wholesale Report",
                key: "wholesale_report",
                icon: "",
                path: "/report/wholesale",
                component: <WholesaleReport />,
            },
            {
                title: "Yearly Sale Report",
                key: "yearly_sale_report",
                icon: "",
                path: "/report/yearly-sale",
                component: <YearlySaleReport />,
            },
            {
                title: "Warehouse Report",
                key: "warehouse_report",
                icon: "",
                path: "/report/warehouse",
                component: <WarehouseReport />
            },
            {
                title: "Warehouse Stock Report",
                key: "warehouse_stock_report",
                icon: "",
                path: "/report/warehouse-stock",
                component: <WarehouseStockReport />
            },
            {
                title: "Stock Purchase",
                key: "stock_purchase",
                icon: "",
                path: "/report/stock-purchase",
                component: <StockPurchaseReport />
            },
            {
                title: "Stock Adjustment",
                key: "stock_adjustment",
                icon: "",
                path: "/report/stock-adjustment",
                component: <StockAdjustmentReport />
            },
            {
                title: "Stock Expired",
                key: "stock_expired",
                icon: "",
                path: "/report/stock-expired",
                component: <StockExpiredReport />
            },
            {
                title: "Point Redemption Report",
                key: "point_redemption_report",
                icon: "",
                path: "/report/point-redemption",
                component: <PointRedemptionReport />
            },
            {
                title: "Retail Customer Report",
                key: "retail_customer_report",
                icon: "",
                path: "/report/retail-customer",
                component: <RetailCustomerReport />
            },
            {
                title: "Wholesale Customer Report",
                key: "retail_customer_report",
                icon: "",
                path: "/report/wholesale-customer",
                component: <WholesaleCustomerReport />
            },
        ]
    },
]

export default routes;