import React from 'react';
import { useNavigate } from 'react-router-dom';
import plusIcon from '../assets/img/icon-plus.png';
import { useForm } from '../hooks/useForm';

const PointChangePopup = ({ data, onCloseClick, modelName , callback }) => {
   
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();
   
    const handleSubmit = (e) => {
        e.preventDefault();
        if (Number(data.loyalty_points) <= Number(formData.point)) {
            setError(`Change point ${formData.point} should not greater than ${data.loyalty_points} or equal 0`)
            return;
        }


        const submitData = {
            ...formData,
            customer_id : data?.customer_id,
            change_point : formData.point,
            modelName : modelName// need to check which table update in database
        }
        submitForm('POST', '/common/exchange-point', submitData, (response) => {
            let data = response?.data.result
            callback(data);
            
        });
    }

    return (
        <div className='popup'>
            <div className="flex flex-row-reverse">
                <button onClick={onCloseClick}><img src={plusIcon} alt="close icon" className="rotate-45 w-5 h-5" /></button>
            </div>

            <div className='flex pb-4'>
                <h1 className='text-3xl	'>Change Point</h1>
            </div>
            <div className='form-container'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div>
                            <label className='x-label'>Point</label>
                            <input type='text'
                                required
                                name='point'
                                max={data?.loyalty_points}
                                onChange={handleInputChange}
                                className='x-input'
                                defaultValue={data?.loyalty_points}

                            />
                        </div>
                        <div className='mt-4'>
                            <label>Choose Currency</label>
                            <div className='flex'>
                                <input required type='radio' id='currencyMMK' name='currency' value='MMK' checked={formData?.currency === 'MMK'} onChange={handleInputChange} />
                                <label htmlFor='currencyMMK' className='cursor-none'>MMK</label>
                                <input required type='radio' className='ml-4' id='currencyTHB' name='currency' value='THB' checked={formData?.currency === 'THB'} onChange={handleInputChange} />
                                <label htmlFor='currencyTHB' className='cursor-none'>THB</label>

                            </div>
                        </div>
                    </div>

                    <div className='mt-8 form-btn-group'>
                        {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                        <div className='flex justify-end gap-5 mt-5'>
                            <button onClick={onCloseClick} className='white-btn'>Cancel</button>
                            <button className='gold-btn'>Change</button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    )
}

export default PointChangePopup;