import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import plusIcon from '../assets/img/icon-plus.png';

const PrintBarcodePopup = ({ data, onCloseClick }) => {
    const componentRef = useRef();
    const pageStyle = `
  @media print {
    @page {
      size: 60mm 40mm;
      margin: 0mm;
    }
    h1 {
      padding: 8px;
    }
  }
  `
    return (
        <div className='popup'>
            <div className="flex flex-row-reverse">
                <button onClick={onCloseClick}><img src={plusIcon} alt="close icon" className="rotate-45 w-5 h-5" /></button>
            </div>
            <div ref={componentRef} className="flex flex-col items-center p-1">
                <h3>Good Supermarket</h3>
                <h1 className='text-4xl'>{data.productPrice.retail_price} {data.productPrice.currency}</h1>
                <h3>{data.product_name}</h3>
                <Barcode width={1} height={16} value={data.barcode} fontSize={8} />
            </div>
            <div className="flex justify-center">
                <ReactToPrint
                    trigger={() => <button className='gold-btn'>
                        Print</button>}
                    content={() => componentRef.current}
                    pageStyle={pageStyle}
                />
            </div>
        </div>
    )
}

export default PrintBarcodePopup;