import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../utils/constant';
import axios from 'axios';
import { formatDateToYYYYMMDD } from '../../utils/format-time';
import PurchaseOrderTable from '../../components/PurchaseOrderTable';
import AsyncSelect from 'react-select/async';


export default function EditPurchaseOrder() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/purchase/purchase-order/list`;
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const items = orderProducts.map(product => ({
            product_id: product.product_id,
            unit_id: product.unit_id,
            quantity: document.getElementById(`qty-${product.product_id}`).value,
            price: document.getElementById(`price-${product.product_id}`).value,
            total_amount: document.getElementById(`total-${product.product_id}`).value,
            currency_type: 'MMK'
        }))
        const data = {
            ...formData,
            items,
            total: overallTotal,
            status: 'ACTIVE',
        }
        submitForm('PUT', '/purchaseOrder/' + id, data, () => {
            navigate(backPath)
        });
    }

    const [suppliers, setSuppliers] = useState()
    useEffect(() => {
        axios.get(API_URL + '/supplier', {
            withCredentials: true
        })
            .then(response => {
                setSuppliers(response.data.data);
            })
    }, []);

    console.log(initialData)

    const purchaseOrderItems = initialData.purchaseOrderDetails.map(item => {
        const product = item.product;
        product.price = item.price;
        product.unit_id = item.unit_id;
        product.quantity = item.quantity;
        product.total_amount = item.total_amount;
        return product;
    })

    const loadOptions = inputValue => {
        return axios.get(API_URL + '/product', {
            withCredentials: true
        })
            .then(response => {
                return response.data.data.filter(option =>
                    option.product_name.toLowerCase().includes(inputValue.toLowerCase())
                );
            });
    };

    useEffect(() => {
        setFormData({
            total: formData.total,
            supplier_id: formData.supplier_id,
            purchase_date: formData.purchase_date,
            currency_type: formData.currency_type,
            remark: initialData.remark
        })
    }, [])

    // useEffect(() => {
    //     setFormData({
    //         sku_unit_id:initialData.productPrice.sku_unit_id,
    //         retail_price:initialData.productPrice.retail_price,
    //         wholesale_price:initialData.productPrice.wholesale_price,
    //         remark: initialData.remark
    //     })
    // }, [])

    const [orderProducts, setOrderProducts] = useState(purchaseOrderItems)
    const [overallTotal, setOverallTotal] = useState(initialData.total)


    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Purchase Order</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Purchase Order Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Order Product</label>
                                <AsyncSelect
                                    defaultOptions
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    isSearchable={true}
                                    isMulti={true}
                                    getOptionLabel={option => option.product_name}
                                    getOptionValue={option => option.product_id}
                                    onChange={option => setOrderProducts(option)}
                                    defaultValue={orderProducts}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: '#1b1b1b66',
                                            minHeight: '50px'
                                        }),
                                    }}
                                />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Supplier Name</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='supplier_id'
                                        required
                                        onChange={handleInputChange}
                                        className='x-input dropdown'
                                        value={formData.supplier_id ? formData.supplier_id : initialData.supplier_id}
                                    >
                                        {suppliers && suppliers.map((item, index) => (
                                            <option key={index} disabled={item.status !== 'ACTIVE'} value={item.supplier_id}>{item.supplier_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Purchase Date</label>
                                <input type='date'
                                    required
                                    name='purchase_date'
                                    defaultValue={formatDateToYYYYMMDD(new Date(initialData.purchase_date))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Currency Type</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='currency_type'
                                        onChange={handleInputChange}
                                        defaultValue={initialData.currency_type}
                                        className='x-input dropdown'
                                    >
                                        <option value="MMK">MMK</option>
                                        <option value="THB">THB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='grow hidden'>
                                <label className='x-label'>Total Amount</label>
                                <input type='number'
                                    name='total'
                                    defaultValue={initialData.total}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    defaultValue={initialData.remark}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <div className='pt-9'>
                            <PurchaseOrderTable data={orderProducts} overallTotal={overallTotal} setOverallTotal={setOverallTotal} />
                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}