import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backArrowIcon from '../../assets/img/back-arrow.png';
import { formatDateToYYYYMMDD } from '../../utils/format-time';
import printIcon from '../../assets/icon/icon-print.png';
import searchIcon from '../../assets/img/icon-search.png';
import Popup from "../../components/Popup";

import { API_URL } from '../../utils/constant';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Select, { components } from 'react-select';

const stockOptions = [
    { value: 'stock_in', label: 'In Stock' },
    { value: 'stock_out', label: 'Out Of Stock' },
]

const WarehouseStockReport = () => {
    const backPath = '/report/list';

    const [data, setData] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState();
    const [products, setProducts] = useState();
    const [product, setProduct] = useState([]);
    const [total_qty, setTotalQty] = useState(0);
    const [total_price, setTotalPrice] = useState(0);
    const [stockStatus, setStockStatus] = useState("all");
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState([])
    console.log(product);

    const [popup, setPopup] = useState({
        id: '',
        show: false
    });
    const [optionOpen, setOptionOpen] = useState(false);

    useEffect(() => {
        axios.get(API_URL + '/warehouse?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                const warehouseOptions = response.data.data?.map((item) => ({
                    ...item,
                    label: item.warehouse_name,
                    value: item.warehouse_id
                }))
                setWarehouses(warehouseOptions);
            })
    }, []);

    function fetchWarehouseReport() {
        return new Promise((resolve, reject) => {
            let product_ids = [];
            if (product.length > 0) {
                product.map(item => {
                    product_ids.push(item.product_id)
                })
            }
            let url = API_URL + `/report/warehouse_stocks?warehouse_id=${warehouse}&category_id=${category}&stock_status=${stockStatus}&product_ids=${JSON.stringify(product_ids)}`;

            axios
                .get(`${url}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    let data = response.data.data;
                    setData(data);
                    let initQty = 0;
                    let initTotal = 0;
                    data.map((item) => {
                        initQty = initQty + Number(item['stock']);
                    })
                    setTotalQty(initQty);
                    resolve(data)
                })
                .catch((e) => {
                    reject(e)
                });
        })

    }


    useEffect(() => {
        axios.get(API_URL + '/productCategory?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                let categoryOptions = response.data.data.map(item => ({
                    ...item,
                    label: item.product_category_name,
                    value: item.product_category_id
                }));
                setCategories(categoryOptions);
            })
    }, []);

    useEffect(() => {
        axios.get(API_URL + `/product?status=ACTIVE&product_category_id=${category}`, {
            withCredentials: true
        })
            .then(response => {
                const productOptions = response.data.data?.map((item) => ({
                    ...item,
                    label: item.product_name,
                    value: item.product_id
                }))
                setProducts(productOptions);
            })
    }, [category]);


    useEffect(() => {
        if (warehouse) {
            fetchWarehouseReport();
        }

    }, [warehouse]);

    const onCloseClick = (e) => {
        e.preventDefault();
        setPopup({
            id: '',
            show: false,
        });
        e.stopPropagation();
    }






    const handleSearch = (e) => {
        e.preventDefault();
        fetchWarehouseReport().then((response) => {

            setPopup({
                id: '',
                show: false,
            });
            setProduct([]);
            setProducts([])

        })
    }


    useEffect(() => {
        const openedEl = document.querySelector('.popup-options.open');

        const handleDocumentClick = e => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.classList.remove('open')
                setOptionOpen(false)
            }
        }
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener('click', handleDocumentClick)
            }, 100)

        }
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [optionOpen]);

    useEffect(() => {
        document.getElementById('external-popup').classList.toggle('show', popup.show);
        const openedEl = document.querySelector('.popup');

        const handleDocumentClick = e => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.parentNode.classList.remove('show')
                onCloseClick(e);
            }
        }
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener('click', handleDocumentClick)
            }, 100)

        }
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [popup.show])

    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
        setPopup({
            show: true,
            eID: 'warehouse-stock-popup',
            id: id
        });
    }

    const handleRemoveProduct = (index) => {
        const newProductList = [...product];
        newProductList.splice(index, 1);
        setProduct(newProductList);
      };

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <div className="flex justify-between text-3xl">
                    <h1 className='sub-page-title'>Warehouse Stock Report</h1>
                    <div className="flex">

                        <div className='grow'>
                            <div className='dd-container' style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <select
                                    type='text'
                                    required
                                    name='transfer_from'
                                    className='x-input dropdown'
                                    defaultValue=""
                                    onChange={e => setWarehouse(e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    {
                                        warehouses && warehouses.map((warehouse, index) =>
                                            <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {/* <div className='grow'>
                            <div className='dd-container' style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <select
                                    type='text'
                                    required
                                    name='transfer_from'
                                    className='x-input dropdown'
                                    value={stockStatus}
                                    onChange={e => setStockStatus(e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    <option value="stock_in">In Stock</option>
                                    <option value="stock_out">Out Of Stock</option>
                                </select>
                            </div>
                        </div> */}




                        <button style={{ backgroundColor: "gold", width: '180px', borderRadius: '5px', marginRight: '10px', cursor: 'pointer' }}
                            onClick={handleOpenModal}
                        >
                            Search
                        </button>
                        <button style={{ backgroundColor: "gold" }} className="header-img-button" onClick={() => {
                            handleExportClick(`Warehouse_Report.xlsx`)
                        }}>
                            <img src={printIcon} />
                        </button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="x-table" id="xlsx-export">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Barcode</th>
                                <th>Expired Date</th>
                                <th>Qty</th>

                                <th>Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item["product_name"]}</td>
                                        <td>{item["barcode"]}</td>
                                        <td>{item["expiry_date"]}</td>
                                        <td>{item["stock"]}</td>
                                        <td>{item["currency_type"]}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td colSpan={3}>Warehouse Total</td>
                                <td>{total_qty}</td>
                                {/* <td>{total_price}</td> */}
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='popup-container'>
            </div>
            <Popup className='popup-container'>
                {popup.eID === 'warehouse-stock-popup' &&
                    <div className="popup" style={{height: "500px",width: "800px" ,display: "flex" , flexDirection : 'column'}}>
                        <div className="flex" style={{flexGrow : 1}}>
                            <div className="w-1/2 p-4">
                                
                                <div className='mt-4'>
                                    <Select
                                        name='category'
                                        options={categories}
                                        onChange={(selected) => {

                                            setCategory(selected?.value)
                                        }}
                                        components={{
                                            MenuList: (props) => {
                                                return (
                                                    <div onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}>
                                                        <components.MenuList {...props} />
                                                    </div>
                                                );
                                            },
                                            MultiValueRemove: (props) => {
                                                return (
                                                    <div onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}>
                                                        <components.MultiValueRemove  {...props} />
                                                    </div>
                                                )
                                            }
                                        }}
                                    />
                                </div>


                                <div className='mt-4'>
                                    <Select
                                        name="product"
                                        options={products}
                                        onChange={(selectedValue) => {
                                            let productOptions = products.filter(item => item.product_id !== selectedValue.product_id);
                                            setProducts(productOptions)
                                            let existingProduct = product.some(item => item.product_id === selectedValue.product_id);
                                            if (!existingProduct) {

                                                setProduct(prev => {
                                                    const newProductList = [...prev];
                                                    newProductList.unshift(selectedValue);
                                                    return newProductList;
                                                });
                                            }

                                        }}
                                        value={null}
                                        styles={{
                                            multiValueRemove: (base) => ({
                                                ...base,
                                                marginTop: '5px'
                                            })
                                        }}
                                        components={{
                                            MenuList: (props) => {
                                                return (
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}>
                                                        <components.MenuList {...props} />
                                                    </div>
                                                );
                                            },
                                            MultiValueRemove: (props) => {
                                                return (
                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}>
                                                        <components.MultiValueRemove  {...props} />
                                                    </div>
                                                )
                                            }
                                        }}
                                    />
                                </div>
                                <div className='flex mt-4'>
                                    <input type='radio' id='all' name='stock' value='all' checked={stockStatus === 'all'} onChange={e => setStockStatus(e.target.value)} />
                                    <label htmlFor='all' className='pr-4 ml-1'>All</label>
                                    <input type='radio' id='stock_in' name='stock' value='stock_in' checked={stockStatus === 'stock_in'} onChange={e => setStockStatus(e.target.value)} />
                                    <label htmlFor='stock_in' className='pr-4 ml-1'>In Stock</label>
                                    <input type='radio' id='stock_out' name='stock' value='stock_out' checked={stockStatus === 'stock_out'} onChange={e => setStockStatus(e.target.value)} />
                                    <label htmlFor='stock_out' className='pr-4 ml-1'>Out Of Stock</label>
                                </div>
                            </div>
                            <div className="w-px bg-gray-400"></div>
                            <div className="flex-1 p-4">
                                <h5 style={{ fontSize: '16px' }}>{product.length ? 'Selected Product' : 'Not Selected Product'}</h5>
                                <div className='mt-2 h-[320px] overflow-auto'>
                                    {product.length ? product.map((item, i) => {
                                        return (
                                            <li key={i}>{item.product_name}
                                                <span onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleRemoveProduct(i)
                                                }} className='w-4 ml-4 text-red-600 cursor-pointer'>
                                                    X

                                                </span>
                                            </li>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>

                        <div className='form-btn-group' style={{marginTop : 'auto' , marginBottom : '10px'}}>
                            <div className='flex justify-center gap-5 mt-5'>
                                <button onClick={onCloseClick} className='white-btn' style={{ cursor: 'pointer' }}>Cancel</button>
                                <button className='gold-btn' onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </div>
                }


            </Popup>
        </div>
    )
}

export default WarehouseStockReport;