import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'voucher_code',
        name: 'Voucher Code'
    },
    {
        key: 'sale_date',
        name: 'Sale Date'
    },
    {
        key: 'customer_name',
        parent: 'wholesaleCustomer',
        name: 'Wholesale Customer Name'
    },
    {
        key: 'loyalty_points',
        parent: 'wholesaleCustomer',
        name: 'Points'
    },
    {
        key: 'total',
        name: 'Total Amount'
    },
    {
        key: 'currency_type',
        name: 'Currency'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'wholesale_id',
        name: 'Wholesale Order ID'
    },
    {
        key: 'customer_name',
        parent: 'wholesaleCustomer',
        name: 'Wholesale Customer Name'
    },
    {
        key: 'voucher_code',
        name: 'Voucher Code'
    },
    {
        key: 'sale_date',
        name: 'Sale Date'
    },
    {
        key: 'currency_type',
        name: 'Currency Type'
    },
    // {
    //     key: 'promotion_method',
    //     name: 'Promotion Method'
    // },
    // {
    //     key: 'promotion_amount',
    //     name: 'Promotion Amount'
    // },
    {
        key: 'total',
        name: 'Total Amount'
    },
    {
        key: '',//????
        name: 'Paid Amount'
    },
    {
        key: 'remark',
        name: 'Remark'
    }
]

const detailPopupTableHeaders = [
    {
        key: 'product_name',
        parent: 'product',
        name: 'Product Name'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    },
    {
        key: 'price',
        name: 'Price'
    },
    {
        key: 'sub_total',
        name: 'Amount'
    }

]

const WholesaleOrderList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const {data, setCurrentPage, setQuery, reload} = useFetchData({
        url: API_URL + '/wholesale',
        defaultQuery: 'status=ACTIVE'
    });

    useEffect(() => {
        document.querySelector(".side-nav").style.display = "flex"
        document.querySelector(".sub-layout").style.paddingLeft = "300px"
    }, [])

    return (
        <TableLayout
            title='Wholesale Order List'
            data={data}
            headers={headers}
            detailPopupTitle='Wholesale Order Information'
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTableTitle='Product List'
            detailPopupTableHeaders={detailPopupTableHeaders}
            detailPopupTableKey='wholesaleDetails'
            endPoint={'/wholesale'}
            idKey='wholesale_id'
            setCurrentPage={setCurrentPage}
            removeEdit={true}
            hasSearch
            hasCreate
            hasActions
            hasPrintReceipt
            createPath={`/sale/wholesale-order/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            hasDetails
            reload={reload}
        />
    )
}
 
export default WholesaleOrderList;