import { useLocation } from "react-router-dom";
import TableLayout from "../../components/TableLayout";
import { useFetchData } from "../../hooks/useFetchData";
import { API_URL } from "../../utils/constant";

const headers = [
    {
        key: 'product_name',
        name: 'Product Name'
    },
    {
        key: 'barcode',
        name: 'Barcode'
    },
    {
        key: 'product_category_name',
        parent: 'productCategory',
        name: 'Category'
    },
    {
        key: 'retail_price',
        parent: 'productPrice',
        name: 'Retail Price'
    },
    {
        key: 'wholesale_price',
        parent: 'productPrice',
        name: 'Wholesale Price'
    },
    {
        key: 'currency',
        parent: 'productPrice',
        name: 'Currency'
    },
    {
        key: 'status',
        name: 'Status'
    },
];

const detailPopupHeaders = [
    {
        key: 'product_id',
        name: 'Product ID'
    },
    {
        key: 'product_name',
        name: 'Product Name'
    },
    {
        key: 'prefix_code',
        name: 'Prefix Code'
    },
    {
        key: 'barcode',
        name: 'Barcode'
    },
    {
        key: 'product_category_name',
        parent: 'productCategory',
        name: 'Product Category'
    },
    {
        key: 'currency',
        parent: 'productPrice',
        name: 'Currency'
    },
    {
        key: 'retail_price',
        parent: 'productPrice',
        name: 'Retail Price'
    },
    {
        key: 'wholesale_price',
        parent: 'productPrice',
        name: 'Wholesale Price'
    },
    // {
    //     key: '',
    //     name: 'SKU',//????
    // },
    {   
        key: 'price_date',
        parent: 'productPrice',
        name: 'Price Date',
    },
    {   
        key: 'remark',
        name: 'Remark',
    }

]

export default function ProductList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
        setQuery({
            product_name: value.trim(),
        });
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/product',
        defaultQuery: 'status=ACTIVE'
    });

    return(
        <TableLayout
            title="Product List"
            data={data}
            headers={headers}
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTitle="Product Information"
            setCurrentPage={setCurrentPage}
            idKey="product_id"
            endPoint="/product"
            hasSearch
            hasCreate
            hasPrintBarcode
            createPath={`/product/list/create`}
            hasActions
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            reload={reload}
        />
    );
}