import React, { useEffect, useState } from 'react'
import profileImg from '../assets/img/user-profile.png'
import logoutImg from '../assets/img/icon-logout.png'
import resetIcon from '../assets/img/icon-reload.png'
import logoutIcon from '../assets/img/icon-logout.png'
import Popup from './Popup';
import { useAuth } from '../routers/AuthProvider';
import { ResetPopup } from './ResetPopup'

export default function UserProfile() {
  const { logout, user } = useAuth();
  const [popup, setPopup] = useState({
    show: false,
    id: '',
    eID: ''
  });

  const onLogout = () => {
    logout();
  }


  useEffect(() => {
    document.getElementById('external-popup').classList.toggle('show', popup.show)

  }, [popup.show])

  return (
    <div className='user-profile-section'>
      <div className='user-profile-divider'></div>
      <div className='profile-layout'>
        <img className='profile-image' src={profileImg} />
        <div className='user-detail'>
          <span className='name'>{user.name ?? 'Default'}</span>
          <span className='role'>{user?.role.role_name ?? 'default'}</span>
        </div>
        <img className='logout-image' src={logoutImg} />

        <div className='profile-popup'>
          <div onClick={() => { setPopup({ id: 0, eID: 'resetPassword', show: true }) }}>
            <img src={resetIcon} />
            <span>Change Password</span>
          </div>
          <div onClick={onLogout}>
            <img src={logoutIcon} />
            <span>Logout</span>
          </div>
        </div>
      </div>

      <Popup className='popup-container'>
        {popup.eID === 'resetPassword' && <ResetPopup setPopup={setPopup} popup={popup} />}
      </Popup>
    </div>
  )
}