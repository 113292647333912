import { useLocation } from "react-router-dom";
import TableLayout from "../../components/TableLayout";
import { useFetchData } from "../../hooks/useFetchData";
import { API_URL } from "../../utils/constant";

const headers = [
    {
        key: 'unit_name',
        name: 'Unit Name'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

export default function UnitList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        setQuery({
            unit_name: value.trim()
        });
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/unit',
        defaultQuery: 'status=ACTIVE'
    });

    return (
        <TableLayout
            title='Unit List'
            data={data}
            headers={headers}
            idKey='unit_id'
            endPoint='/unit'
            removeDetails={true}
            setCurrentPage={setCurrentPage}
            hasSearch
            hasCreate
            hasActions
            createPath={`/unit/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}