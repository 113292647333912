import { useEffect } from "react"
const PurchaseOrderTable = ({ orderProducts, setOrderProducts, units, overallTotal, setOverallTotal }) => {
    const getOverallTotal = () => {
        let total = 0
        orderProducts.forEach(function (item) {
            total += parseFloat(item.quantity * item.orderPrice)
        });
        total = parseFloat((total).toFixed(2))
        setOverallTotal(total)
    }

    useEffect(() => {
        getOverallTotal()
    }, [orderProducts])

    return (
        <div className='table-container'>
            <table className='x-table product-list-table'>
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {orderProducts.map((item) => (
                        <tr key={item.product_id}>
                            <td>{item.product_name}</td>
                            <td>
                                <select
                                    id={`unit-${item.product_id}`}
                                    type='text'
                                    onChange={(e) => {
                                        const orderUnit = e.target.value
                                        setOrderProducts(prevData => {
                                            return prevData.map(product => (product.product_id === item.product_id ? { ...product, orderUnit } : product));
                                        });
                                    }}
                                    value={item.orderUnit}
                                    required
                                    className="w-32 border border-solid border-neutral-300 rounded"
                                >
                                    <option value='' disabled>Select</option>
                                    {
                                        units && units.map((item, index) => (
                                            <option key={index} value={item.unit_id}>{item.unit_name}</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td>
                                <input
                                    id={`qty-${item.product_id}`}
                                    className="w-16 border border-solid border-neutral-300 rounded"
                                    min='1'
                                    value={item.quantity}
                                    type="number"
                                    onChange={(e) => {
                                        const quantity = e.target.value;
                                        setOrderProducts(prevData => {
                                            return prevData.map(product => (product.product_id === item.product_id ? { ...product, quantity } : product));
                                        });
                                        getOverallTotal();
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    id={`price-${item.product_id}`}
                                    className="w-100 border border-solid border-neutral-300 rounded"
                                    min='1'
                                    value={item.orderPrice}
                                    type="number"
                                    onChange={(e) => {
                                        const orderPrice = e.target.value;
                                        setOrderProducts(prevData => {
                                            return prevData.map(product => (product.product_id === item.product_id ? { ...product, orderPrice } : product));
                                        });
                                        getOverallTotal();
                                    }}
                                />
                            </td>
                            <td>
                                <input disabled
                                    id={`total-${item.product_id}`}
                                    style={{ width: '4rem' }}
                                    value={item.quantity * item.orderPrice}
                                /></td>
                        </tr>
                    ))}
                </tbody>
                <tfoot className="font-bold">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Amount</td>
                        <td>{overallTotal}</td>
                    </tr>
                </tfoot>
            </table>
        </div>);
}

export default PurchaseOrderTable;