import { Link, useLocation } from 'react-router-dom';
import plusIcon from '../assets/icon/icon-plus.png';
import routes from '../routes';
import { useAuth } from '../routers/AuthProvider';
import { useEffect, useState } from 'react';

export function Sidebar() {
    const { user } = useAuth();

    // const [childPermissions, setChildPermissions] = useState([]);
    const [sidebarComponents, setSidebarComponents] = useState(null);

    useEffect(() => {
        const tempSidebar = routes.map((route) => {
            if (route.collapse) {
                let childPermissions = [];
                route.children.map((child) => {
                    if (allow(user.role.roleHasPermissions, child.permissionName)) {
                        childPermissions.push(child.permissionName);
                    }
                })
                route.children = route.children.filter(item => childPermissions.includes(item.permissionName))
                if (childPermissions.length === 0) {
                    return null;
                } else {
                    return <NestedNavItem key={route.key} route={route} />
                }
            } else {
                if (!allow(user.role.roleHasPermissions, route.permissionName)) {
                    return null;
                } else {
                    return <NavItem key={route.key} route={route} />;
                }
            }
        });

        setSidebarComponents(tempSidebar);
    }, [user]);

    return (
        <div className='navigation'>
            {sidebarComponents}
        </div>
    );
}

const NestedNavItem = ({ route }) => {
    const { key, icon, title, children } = route;
    const location = useLocation();
    const currentPath = location.pathname;
    const active = currentPath.slice(1).startsWith(key);
    const childrenPath = (c) => {
        if (c.path) {
            return c.path;
        } else {
            return childrenPath(c.children[0]);
        }
    };

    const getFirstChildRoute = (childR) => {
        if (!childR.path && childR.children) {
            return getFirstChildRoute(childR.children[0]);
        } else {
            return childR;
        }
    }

    return (
        <div className='nested-nav-item'>
            <Link to={children[0].path ?? childrenPath(children[0])} key={key} className={`parent-nav ${active ? "active" : ""}`}>
                <img alt='sidebar icon' src={icon} />
                <span>{title}</span>
                <img alt='downarrow' src={plusIcon} className='downarrow' />
            </Link>
            {active && (
                <div className='child-nav'>
                    {children.map((childRoute, index) => {
                        if (childRoute.collapse) {
                            return <NestedNavItem key={childRoute.key} route={childRoute} />
                        } else if (!childRoute.path && childRoute.children) {
                            return <NavItem key={childRoute.key} route={getFirstChildRoute(childRoute)} child={true} />
                        } else {
                            return <NavItem key={childRoute.key} route={childRoute} child={true} />
                        }
                    })}
                </div>
            )}
        </div>
    )
}

const NavItem = ({ route, child = false }) => {
    const { key, icon, title, path } = route;
    const location = useLocation();
    const currentPath = location.pathname;
    const active = currentPath.startsWith(path);

    const childrenPath = (c) => {
        if (c.path) {
            return c.path;
        } else {
            return childrenPath(c.children[0]);
        }
    };

    return (
        <Link key={key} to={path ?? childrenPath(route.children[0])} className={`${child ? "sub-nav-item" : "nav-item"} ${active ? "active" : ""}`}>
            {!child && (<img alt='sidebar icon' src={icon} />)}
            <span>{title}</span>
        </Link>
    );
};

const allow = (data, module) => {
    const xtemp = data.filter(item => item.permission.permission_name == module);
    if (xtemp.length > 0) {
        return true
    }
    else {
        return false
    }
}