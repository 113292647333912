import React, { useRef, useState } from 'react';
import logo from '../../assets/img/goodlogo.png';
import poweredBy from '../../assets/img/powered_by.png'; 
import '../../assets/css/login.css';
import { useForm } from '../../hooks/useForm';
import { useAuth } from '../../routers/AuthProvider';

export default function Login() {
	const {setUser, checkUser, setLoading} = useAuth() ;
	const {handleInputChange, formData, error, setError, submitForm} = useForm();

	const onsubmit = (e) => {
		e.preventDefault();
		setLoading(true);
        // setUser({
        //     name: "Admin",
        //     username: "admin",
        //     email: "admin@mail.com"
        // });
        // setLoading(false);
		submitForm('POST', '/auth/login', formData, response => {
			console.log("login res: ", response.data.data);
			// setUser({
            //     ...response.data.data,
            //     userAccess: response.data.data.token
            // })
			checkUser(response.data.data.token);
		});
	}

	return (
		
		<section className='page' style={{paddingTop: '150px'}}>
			<div className='login-section'>
				<div className='container'>

					<div className='left-side'>
						<img className='logo' src={logo} />
					</div>

					<div className='divider'></div>

					<form className='right-side' onSubmit={onsubmit}>
						<h1>
							Login
						</h1>
						<div className='input-item'>
							<label className='input-required' htmlFor='username'>
								Username
							</label>
							<input 
							required
							id='username' 
							type='text' 
							name='username'
							onChange={handleInputChange}/>
						</div>
						<div className='input-item' style={{marginTop: '20px'}}>
							<label htmlFor='password'  className='input-required'>
								Password
							</label>
							<input 
							required
							id='password' 
							type='password' 
							name='password'
							onChange={handleInputChange}/>
						</div>

						<div className='w-full'>
							<p className='text-red-400 p-0 mt-2'>{error && 'Incorrect username or password!'}</p>
						</div>
						<div className='button-container'>
							{/* <button className='cancel-btn'>
								Cancel
							</button> */}
							<button type='submit' className='login-btn' >
								Login
							</button>
						</div>

					</form>

				</div>
			</div>
            <div className='powered-by'>
                <img src={poweredBy} />
            </div>

		</section>
	)
}