import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import InvoiceDetails from '../../components/InvoiceDetails';
import RetailSalesOrderTable from '../../components/RetailSalesOrderTable';
import AsyncSelect from 'react-select/async';
import { API_URL } from '../../utils/constant';
import { useReactToPrint } from 'react-to-print';
import Receipt from '../../components/Receipt';
import RetailPromotionTable from '../../components/RetailPromotionTable';
import axios from 'axios';
import moment from 'moment';
import PointChangePopup from '../../components/PointChangePopup';
import Popup from '../../components/Popup';
import { truncateDecimal } from '../../utils/truncateDecimal';

export default function CreateRetailSalesOrder() {
    const backPath = `/sale/retail-sales-order/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];
    const { user, setLoading } = useAuth();
    const [saleProducts, setSaleProducts] = useState([]);
    const [currencyType, setCurrencyType] = useState('MMK');
    const [total, setTotal] = useState(0);
    const [totalInMMK, setTotalInMMK] = useState(0);
    const [totalInTHB, setTotalInTHB] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [subTotalMMK, setSubTotalMMK] = useState(0);
    const [subTotalTHB, setSubTotalTHB] = useState(0);
    const [advancedInMMK, setAdvancedInMMK] = useState(0);
    const [advancedInTHB, setAdvancedInTHB] = useState(0);
    const [taxEnabled, setTaxEnabled] = useState(0);
    const [advancedEnabled, setAdvancedEnabled] = useState(0);
    const [tax, setTax] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [taxAmountInMMK, setTaxAmountInMMK] = useState(0);
    const [taxAmountInTHB, setTaxAmountInTHB] = useState(0);
    const [change, setChange] = useState(0);
    const [changeInMMK, setChangeInMMK] = useState(0);
    const [changeInTHB, setChangeInTHB] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [discountInMMK, setDiscountInMMK] = useState(0);
    const [discountInTHB, setDiscountInTHB] = useState(0);
    const [saleDate, setSaleDate] = useState(today);
    const [paidAmount, setPaidAmount] = useState(0);
    const [paidAmountInMMK, setPaidAmountInMMK] = useState(0);
    const [paidAmountInTHB, setPaidAmountInTHB] = useState(0);
    const [promotionAmount, setPromotionAmount] = useState(0);
    const [promotionAmountInMMK, setPromotionAmountInMMK] = useState(0);
    const [promotionAmountInTHB, setPromotionAmountInTHB] = useState(0);

    const [promotionProducts, setPromotionProducts] = useState([]);
    const [promotionByTotal, setPromotionByTotal] = useState({ promotion: {}, applyPromo: false });
    const [loyaltyPoint, setLoyaltyPoint] = useState(0);
    const [voucherCode, setVoucherCode] = useState("");
    const [activeTab, setActiveTab] = useState();
    const [remark, setRemark] = useState();
    const isInitialMount = useRef(true);
    const [customer, setCustomer] = useState(null);
    const [mmkTotal, setMmkTotal] = useState(0);
    const [thbTotal, setThbTotal] = useState(0);

    // pop up implement

    const [popup, setPopup] = useState({
        show: false,
        id: '',
        eID: ''
    });

    useEffect(() => {
        document.getElementById('external-popup').classList.toggle('show', popup.show)
    }, [popup.show])

    const onClosePopup = (e) => {
        e.preventDefault();
        setPopup({
            id: '',
            show: false,
        });

    }


    // pop up implement

    useEffect(() => {
        let mmkInit = 0;
        let thbInit = 0;
        setMmkTotal(mmkInit)
        setThbTotal(thbInit)
        saleProducts.map((product) => {
            if (currencyType === "MMK") {
                mmkInit = mmkInit + Number(product.mmkTotal)
                setMmkTotal(mmkInit);
            } else if (currencyType === "THB") {
                thbInit = thbInit + Number(product.thbTotal)
                setThbTotal(thbInit);
            } else {
                mmkInit = mmkInit + Number(product.convertedMMKTotal)
                setMmkTotal(mmkInit);
                thbInit = thbInit + Number(product.convertedTHBTotal)
                setThbTotal(thbInit);
            }
        });
    }, [saleProducts, currencyType]);

    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true)
        const items = getSaleItems(saleProducts);

        const data = {
            sale_date: saleDate,
            retail_customer_id: customer?.customer_id ?? 0,
            promotion_method: (promotionByTotal.applyPromo && promotionByTotal.promotion) ? "AMOUNT" : "",
            promotion_amount: promotionAmount,
            sub_total: subTotal,
            discount,
            tax,
            tax_amount: taxAmount,
            paid_amount: paidAmount,
            remark,
            total,
            currency_type: currencyType,
            change,
            items,
            loyalty: loyaltyPoint,
            rate: exchangeRate,
            mmk_total: mmkTotal,
            thb_total: thbTotal,

            discount_mmk: discountInMMK,
            discount_thb: discountInTHB,
            sub_total_mmk: subTotalMMK,
            sub_total_thb: subTotalTHB,
            promotion_amount_mmk: promotionAmountInMMK,
            promotion_amount_thb: promotionAmountInTHB,
            tax_mmk: taxAmountInMMK,
            tax_thb: taxAmountInTHB,
            advanced_mmk: advancedInMMK,
            advanced_thb: advancedInTHB,
            paid_amount_mmk: paidAmountInMMK,
            paid_amount_thb: paidAmountInTHB,
            change_mmk: changeInMMK,
            change_thb: changeInTHB,
            total_mmk: totalInMMK,
            total_thb: totalInTHB,
            advanced_enabled : advancedEnabled,

            loyalty_amount : loyaltyRule ? loyaltyRule.amount : 0,

        }

        // handlePrint();

        submitForm("POST", "/retailSale", data,
            (response) => {
                setVoucherCode(response.data.voucher_code)
            }
        )
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handlePrint();
        }
    }, [voucherCode])



    const getSaleItems = (products) => {
        let promoItems = []
        const result = products.map(product => {
            let a;
            a = {
                product_id: product.product_id,
                barcode: product.barcode,
                quantity: product.quantity,
                price: currencyType === "MMK" ? product.mmkPrice : product.thbPrice,
                sub_total: currencyType === "MMK" ? product.mmkTotal : product.thbTotal,
                total: currencyType === "MMK" ? product.mmkTotal : product.thbTotal
            }

            if (product.applyPromo && product.promotion) {
                if (product.promotion.promotion_type === "By Percent") {
                    a.promotion_type = "PERCENT"
                    a.promotion_id = product.promotion.promotion_by_percent_id
                    a.promotion_amount = (product.promotion.percent_amount / 100) * a.sub_total
                    a.total = a.sub_total - (product.promotion.percent_amount / 100) * a.sub_total
                } else if (product.promotion.promotion_type === "By BOGO") {
                    a.promotion_type = "BOGO"
                    a.promotion_id = product.promotion.promotion_by_bogo_id
                    a.promotion_amount = 0
                    a.quantity = a.quantity
                    promoItems.push({ barcode: product.barcode, product_name: product.product_name, quantity: product.quantity })
                } else if (product.promotion.promotion_type === "By Cross Product") {
                    a.promotion_type = "CROSS_PRODUCT"
                    a.promotion_id = product.promotion.promotion_by_cp_id
                    a.promotion_amount = 0
                    // let crossable_qty = 0;
                    // product.promotion.crossableProducts.map(crossProduct => {
                    //     crossProduct.quantity
                    // });

                    // product.promotion.promoProducts.map(promoProduct => {
                    //     // floor(total/crossable_qty) * promo_qty

                    //     promoItems.push({ product_name: promoProduct.product.product_name, quantity: Math.floor(crossable_qty) * promoProduct.quantity })
                    // });
                    product.promotion.promoProducts.map(promoProduct => {
                        promoItems.push({ product_name: promoProduct.product.product_name, quantity: (product.quantity * promoProduct.quantity) })
                    });
                }
            }
            setPromotionProducts(promoItems)
            return a
        });
        return result;
    }

    const getPrintItems = (products) => {
        const result = products.map(product => ({
            description: product.product_name,
            quantity: product.quantity,
            price: currencyType === "MMK" ? product.mmkPrice : product.thbPrice,
            total: currencyType === "MMK" ? product.mmkTotal : product.thbTotal,
            barcode: product.barcode
        }));
        return result;
    }

    const loadOptions = inputValue => {
        return axios.get(API_URL + '/product?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                return response.data.data.filter(option =>
                    option.product_name.toLowerCase().includes(inputValue.toLowerCase())
                );
            });
    };

    const [memberCode, setMemberCode] = useState("");
    const [exchangeRate, setExchangeRate] = useState();
    const [reverseExchangeRate, setReverseExchangeRate] = useState();

    useEffect(() => {
        axios.get(API_URL + '/currencyExchange?limit=1&page=1&sort=-created_date', {
            withCredentials: true
        })
            .then(response => {
                setExchangeRate(response.data.data[0].exchange_rate)
                setReverseExchangeRate(response.data.data[0].reverse_exchange_rate)
            })
    }, []);

    useEffect(() => {
        if (promotionByTotal.promotion) {
            let tempPromoAmt = parseFloat(promotionAmount) + parseFloat(promotionByTotal?.promotion?.promotion_amount) ?? 0;
            console.log("promo: ", promotionByTotal?.promotion?.promotion_amount);
            console.log("discount: ", parseFloat(promotionAmount));
            console.log("TempDis: ", tempPromoAmt);
            setPromotionAmount(tempPromoAmt)
        }
    }, [promotionByTotal]);

    useEffect(() => {
        if (taxEnabled) {
            setTaxAmount(parseFloat(((tax / 100) * subTotal).toFixed(2)));
        } else {
            setTaxAmount(0);
        }
    }, [tax, subTotal, taxEnabled])

    useEffect(() => {
        if (taxEnabled && currencyType === "SEPERATED") {
            setTaxAmountInMMK(parseFloat(((tax / 100) * subTotalMMK).toFixed(2)));
        } else {
            setTaxAmountInMMK(0);
        }
    }, [tax, subTotalMMK, taxEnabled])

    useEffect(() => {
        if (taxEnabled && currencyType === "SEPERATED") {
            setTaxAmountInTHB(parseFloat(((tax / 100) * subTotalTHB).toFixed(2)));
        } else {
            setTaxAmountInMMK(0);
        }
    }, [tax, subTotalTHB, taxEnabled])

    useEffect(() => {
        const subTotalVal = parseFloat(subTotal) || 0;
        const taxAmountVal = parseFloat(taxAmount) || 0;
        const discountVal = parseFloat(discount) || 0;
        const promotionAmountVal = parseFloat(promotionAmount) || 0;
        const advancedInMMKVal = parseFloat(advancedInMMK) || 0;
        const advancedInTHBVal = parseFloat(advancedInTHB) || 0;
        let totalValue = subTotalVal + taxAmountVal - discountVal - promotionAmountVal;

        if (advancedEnabled && currencyType === "MMK") {
            totalValue -= advancedInMMKVal
        } else if (advancedEnabled && currencyType === "THB") {
            totalValue -= advancedInTHBVal
        }
        if (currencyType !== "SEPERATED") {
            setTotal(truncateDecimal(totalValue, 5))   
        }
    }, [subTotal, discount, taxAmount, promotionAmount, advancedEnabled, advancedInMMK, advancedInTHB]);

    useEffect(() => {
        const subTotalMMKVal = parseFloat(subTotalMMK) || 0;
        const taxAmountInMMKVal = parseFloat(taxAmountInMMK) || 0;
        const discountInMMKVal = parseFloat(discountInMMK) || 0;
        const promotionAmountInMMKVal = parseFloat(promotionAmountInMMK) || 0;
        const advancedInMMKVal = parseFloat(advancedInMMK) || 0;
        let totalValue = subTotalMMKVal + taxAmountInMMKVal - discountInMMKVal - promotionAmountInMMKVal
        if (advancedEnabled) {
            totalValue -= advancedInMMKVal
        }
        setTotalInMMK(truncateDecimal(totalValue, 5))
    }, [subTotalMMK, discountInMMK, , taxAmountInMMK, promotionAmountInMMK, advancedInMMK, advancedEnabled]);

    useEffect(() => {
        const subTotalTHBVal = parseFloat(subTotalTHB) || 0;
        const taxAmountInTHBVal = parseFloat(taxAmountInTHB) || 0;
        const discountInTHBVal = parseFloat(discountInTHB) || 0;
        const promotionAmountInTHBVal = parseFloat(promotionAmountInTHB) || 0;
        const advancedInTHBVal = parseFloat(advancedInTHB) || 0;
        let totalValue = subTotalTHBVal + taxAmountInTHBVal - discountInTHBVal - promotionAmountInTHBVal
        if (advancedEnabled) {
            totalValue -= advancedInTHBVal
        }
        setTotalInTHB(truncateDecimal(totalValue, 5))
    }, [subTotalTHB, discountInTHB, , taxAmountInTHB, promotionAmountInTHB, advancedInTHB, advancedEnabled]);


    useEffect(() => {
        const paidAmountVal = parseFloat(paidAmount) || 0;
        const totalVal = parseFloat(total) || 0;
        let changeValue = paidAmountVal - totalVal;
        setChange(truncateDecimal(changeValue,5))
    }, [paidAmount, total])

    useEffect(() => {
        const totalInMMKVal = parseFloat(totalInMMK) || 0;
        const paidAmountInMMKVal = parseFloat(paidAmountInMMK) || 0;
        let changeInMMK = paidAmountInMMKVal - totalInMMKVal;
        setChangeInMMK(truncateDecimal(changeInMMK,5))
    }, [paidAmountInMMK, totalInMMK])

    useEffect(() => {
        const totalInTHBVal = parseFloat(totalInTHB) || 0;
        const paidAmountInTHBVal = parseFloat(paidAmountInTHB) || 0;
        let changeInTHB = paidAmountInTHBVal - totalInTHBVal;
        setChangeInTHB(truncateDecimal(changeInTHB,5))
    }, [paidAmountInTHB, totalInTHB])

    const printRef = useRef();
    const pageStyle = `
    @media print {
        @page {
        size: 70mm 250mm;
        margin: 0 0.3in 0 0;
        }

        html, body {
            height: auto;
            font-size: 12pt; /* changing to 10pt has no impact */
        }
    }`;

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => navigate(backPath),
        pageStyle: pageStyle
    });

    const barcodeRef = useRef(null)
    useEffect(() => {
        barcodeRef.current.focus();
    }, []);

    const currentDate = new Date();
    const [promotions, setPromotions] = useState([]);
    const [loyaltyRule, setLoyaltyRule] = useState();
    const [currentPromotions, setCurrentPromotions] = useState();

    useEffect(() => {
        axios.get(API_URL + '/promotion', {
            withCredentials: true
        }).then(response => {
            setPromotions(response.data.data)
        });

        axios.get(API_URL + `/loyalty?status=ACTIVE&loyalty_type=retail%20sale&sort=-created_date`, {
            withCredentials: true
        }).then(response => {
            if (response.data.data.length) {
                const res = response.data.data[0];
                const startDate = moment(res.start_date, 'YYYY-MM-DD HH:mm:ss').startOf('day');
                const endDate = moment(res.end_date, 'YYYY-MM-DD HH:mm:ss').endOf('day');
                const now = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
                if (now > startDate && now < endDate) {
                    setLoyaltyRule(res);
                }
            }
        })
    }, [])

    useEffect(() => {
        document.querySelector(".side-nav").style.display = "none"
        document.querySelector(".sub-layout").style.paddingLeft = "0px"
    }, [])

    useEffect(() => {
        const filteredData = [];
        const promoTypes = ["amount", "percent", "bogo", "cross_product"];

        promoTypes.forEach(promoType => {
            promotions?.[promoType]?.filter(item => {
                if (item.status !== "ACTIVE") {
                    return;
                }
                const startDate = new Date(item.start_date);
                const endDate = new Date(item.end_date);
                if (currentDate >= startDate && currentDate <= endDate) {
                    filteredData.push(item)
                }
            });
        })
        setCurrentPromotions(filteredData)
    }, [promotions])


    useEffect(() => {
        if (subTotal >= loyaltyRule?.amount) {
            setLoyaltyPoint(loyaltyRule?.point);
        }
        if (subTotalMMK >= loyaltyRule?.amount) {
            setLoyaltyPoint(loyaltyRule?.point);
        }
        if (subTotalTHB >= loyaltyRule?.amount) {
            setLoyaltyPoint(loyaltyRule?.point);
        }
        setPromotionByTotal({ applyPromo: promotionByTotal.applyPromo })
        setSaleProducts(prevData => {
            return prevData.map(product => ({ ...product, promotion: null }));
        });

        currentPromotions?.forEach(promo => {
            if (promo.promotion_type === "By Total") {
                if (promo.currency_type === currencyType) {
                    if (subTotal > promo.minimum_total_amount) {
                        setPromotionByTotal({ promotion: promo, applyPromo: promotionByTotal.applyPromo })
                    }
                }
            } else if (promo.promotion_type === "By Percent") {
                if (saleProducts.some(product => product.product_id === promo.product_id)) {

                    setSaleProducts(prevData => {
                        return prevData.map(product => (product.product_id === promo.product_id ? { ...product, applyPromo: product.applyPromo, promotion: { ...promo, eligibleTimes: product.quantity } } : product));
                    });
                }
            } else if (promo.promotion_type === "By BOGO") {
                if (saleProducts.some(product => product.product_id === promo.product_id)) {

                    setSaleProducts(prevData => {
                        return prevData.map(product => (product.product_id === promo.product_id ? { ...product, applyPromo: product.applyPromo, promotion: { ...promo, eligibleTimes: product.quantity } } : product));
                    });
                }
            } else {
                const crossableProducts = promo.crossableProducts.map(crossableProduct => crossableProduct.product_id)
                let crossable = true;
                crossableProducts.forEach(crossableProduct => {
                    if (!saleProducts.some(product => product.product_id === crossableProduct)) {
                        crossable = false;
                    }
                })

                if (crossable) {
                    const m = []

                    promo.crossableProducts.forEach(crossableProduct => {
                        const quantity = saleProducts.find(product => product.product_id === crossableProduct.product_id).quantity
                        m.push(Math.floor(quantity / crossableProduct.quantity))
                    })

                    const l = Math.min(...m)

                    if (l === 0) {
                        return
                    }

                    promo.crossableProducts.forEach(crossableProduct => {
                        setSaleProducts(prevData => {
                            return prevData.map(product => (product.product_id === crossableProduct.product_id ? { ...product, applyPromo: product.applyPromo, promotion: { ...promo, eligibleTimes: l } } : product));
                        });
                    })
                }
            }
        })
    }, [currentPromotions, subTotal, currencyType, subTotalMMK, subTotalTHB])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Retail Sales Order</h1>
                <form onSubmit={onSubmit} className='form-container'>
                    <div className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>
                            {/* <div className='grow'>
                                <label className='x-label input-required'>Product Name</label>
                                <AsyncSelect
                                    defaultOptions
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    isSearchable={true}
                                    isMulti={true}
                                    getOptionLabel={option => option.product_name}
                                    getOptionValue={option => option.product_id}
                                    value={saleProducts}
                                    onChange={option => {
                                        const newObj = option.map(item => {
                                            const mmkPrice = item.mmkPrice ? item.mmkPrice : 
                                            (item, "MMK", exchangeRate, reverseExchangeRate)
                                            const thbPrice = item.thbPrice ? item.thbPrice : convertCurrency(item, "BAHT", exchangeRate, reverseExchangeRate)
                                            const quantity = item.quantity ? item.quantity : 1
                                            const mmkTotal = item.mmkTotal ? item.mmkTotal : mmkPrice
                                            const thbTotal = item.thbTotal ? item.thbTotal : thbPrice
                                            return { ...item, quantity, mmkPrice, thbPrice, mmkTotal, thbTotal }
                                        })
                                        setSaleProducts(newObj)
                                    }}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: '#1b1b1b66',
                                            minHeight: '50px'
                                        }),
                                    }}
                                />
                            </div> */}
                            <div className='grow'>
                                <label className='x-label'>Barcode</label>
                                <input type='text'
                                    tabIndex={1}
                                    ref={barcodeRef}
                                    onKeyDown={e => {
                                        e.target.nextElementSibling.classList.remove('block')
                                        e.target.nextElementSibling.classList.add('hidden')
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            const url = API_URL + `/product?barcode=${e.target.value}`
                                            // if (saleProducts.some(item => item.barcode === e.target.value)) {
                                            //     e.target.value = ""
                                            //     return;
                                            // }
                                            axios.get(url, {
                                                withCredentials: true
                                            }).then(response => {
                                                console.log(response)
                                                e.target.value = ""
                                                if (response.data.length === 0) {
                                                    e.target.nextElementSibling.classList.remove('hidden')
                                                    e.target.nextElementSibling.classList.add('block')
                                                } else {
                                                    const newItem = response.data.data[0];
                                                    const existing = saleProducts.filter(v => v.barcode == newItem.barcode);
                                                    console.log("existing", existing)
                                                    if (existing.length) {

                                                        setSaleProducts(prevData => {
                                                            return prevData.map(product => (product.product_id === existing[0].product_id ?
                                                                {
                                                                    ...product,
                                                                    quantity: parseInt(product.quantity) + 1,
                                                                    mmkTotal: (parseInt(product.quantity) + 1) * product.mmkPrice,
                                                                    thbTotal: (parseInt(product.quantity) + 1) * product.thbPrice,
                                                                    convertedMMKTotal: product.productPrice.currency === "MMK" ? (parseInt(product.quantity) + 1) * product.mmkPrice : 0,
                                                                    convertedTHBTotal: product.productPrice.currency === "BAHT" ? (parseInt(product.quantity) + 1) * product.thbPrice : 0
                                                                } :
                                                                product));
                                                        });
                                                    } else {
                                                        const mmkPrice = convertCurrency(newItem, "MMK", exchangeRate, reverseExchangeRate)
                                                        const thbPrice = convertCurrency(newItem, "BAHT", exchangeRate, reverseExchangeRate)
                                                        const quantity = 1;
                                                        const mmkTotal = mmkPrice
                                                        const thbTotal = thbPrice
                                                        const convertedMMKTotal = newItem.productPrice.currency === "MMK" ? mmkPrice : 0;
                                                        const convertedTHBTotal = newItem.productPrice.currency === "BAHT" ? thbPrice : 0;
                                                        setSaleProducts(prev => [{ ...newItem, quantity, mmkPrice, thbPrice, mmkTotal, thbTotal, convertedMMKTotal, convertedTHBTotal }, ...prev])
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                    name='remark'
                                    className='x-input' />
                                <span className='hidden text-red-500'>Product Not Found</span>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Sale Date</label>
                                <input type='date'
                                    required
                                    name='sale_date'
                                    value={saleDate}
                                    onChange={e => setSaleDate(e.target.value)}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Member Code {(memberCode !== "" && customer?.customer_name) ? `(${customer.customer_name} - ${customer.loyalty_points} points)` : ''}</label>
                                <input type='text'
                                    name='remark'
                                    onKeyDown={e => {
                                        e.target.nextElementSibling.classList.remove('block')
                                        e.target.nextElementSibling.classList.add('hidden')
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            const url = API_URL + `/retailCustomer?member_code=${e.target.value}`
                                            if (saleProducts.some(item => item.barcode === e.target.value)) {
                                                e.target.value = "";
                                                e.target.nextElementSibling.classList.remove('hidden');
                                                e.target.nextElementSibling.classList.add('block');
                                                setCustomer({});
                                                setMemberCode("");
                                                return;
                                            }
                                            axios.get(url, {
                                                withCredentials: true
                                            }).then(response => {
                                                if (response.data.length === 0) {
                                                    e.target.value = "";
                                                    e.target.nextElementSibling.classList.remove('hidden');
                                                    e.target.nextElementSibling.classList.add('block');
                                                    setCustomer({});
                                                    setMemberCode("");
                                                } else {
                                                    console.log(response.data.data[0])
                                                    setCustomer(response.data.data[0]);
                                                    setAdvancedInMMK(response.data.data[0]?.advanced_mmk);
                                                    setAdvancedInTHB(response.data.data[0]?.advanced_thb)
                                                    setMemberCode(response.data.data[0].member_code)
                                                }
                                            })
                                        }
                                    }}
                                    className='x-input' />
                                <span className='hidden text-red-500'>Member Card Not Found</span>
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    onChange={e => {
                                        const value = e.target.value
                                        setRemark(value)
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            return;
                                        }
                                    }}
                                    className='x-input' />
                            </div>
                        </div>
                        {customer &&
                            <div className='flex flex-row-reverse mt-6'>
                                <button className='gold-btn' onClick={(e) => {
                                    e.preventDefault();
                                    setPopup({ id: 0, eID: 'changePoint', show: true })
                                }}>Change Advance</button>
                            </div>
                        }

                        <RetailSalesOrderTable data={saleProducts} activeTab={activeTab} setActiveTab={setActiveTab} promotionByTotal={promotionByTotal} setPromotionByTotal={setPromotionByTotal} currencyType={currencyType} THBToMMK={exchangeRate} MMKToTHB={reverseExchangeRate} setSubTotal={setSubTotal} setSubTotalMMK={setSubTotalMMK} setSubTotalTHB={setSubTotalTHB} setSaleProducts={setSaleProducts} />
                        <RetailPromotionTable saleProducts={saleProducts} activeTab={activeTab} setActiveTab={setActiveTab} currencyType={currencyType} promotionByTotal={promotionByTotal} setPromotionAmount={setPromotionAmount} />
                        <div className='py-12 flex flex-row-reverse justify-around'>
                            <div className='grow-[0.2]'>
                                <InvoiceDetails
                                    total={total}
                                    totalInMMK={totalInMMK}
                                    totalInTHB={totalInTHB}
                                    currencyType={currencyType}
                                    setCurrencyType={setCurrencyType}
                                    subTotal={subTotal}
                                    taxEnabled={taxEnabled}
                                    setTaxEnabled={setTaxEnabled}
                                    advancedEnabled={advancedEnabled}
                                    setAdvancedEnabled={setAdvancedEnabled}
                                    tax={tax}
                                    setTax={setTax}
                                    taxAmount={taxAmount}
                                    change={change}
                                    discount={discount}
                                    setDiscount={setDiscount}
                                    discountInMMK={discountInMMK}
                                    discountInTHB={discountInTHB}
                                    setDiscountInMMK={setDiscountInMMK}
                                    setDiscountInTHB={setDiscountInTHB}
                                    paidAmount={paidAmount}
                                    setPaidAmount={setPaidAmount}
                                    promotionAmount={promotionAmount}
                                    promotionAmountInMMK={promotionAmountInMMK}
                                    promotionAmountInTHB={promotionAmountInTHB}
                                    subTotalMMK={subTotalMMK}
                                    subTotalTHB={subTotalTHB}
                                    changeInTHB={changeInTHB}
                                    changeInMMK={changeInMMK}
                                    advancedInMMK={advancedInMMK}
                                    advancedInTHB={advancedInTHB}
                                    setAdvancedInMMK={setAdvancedInMMK}
                                    setAdvancedInTHB={setAdvancedInTHB}
                                    taxAmountInMMK={taxAmountInMMK}
                                    taxAmountInTHB={taxAmountInTHB}
                                    paidAmountInMMK={paidAmountInMMK}
                                    paidAmountInTHB={paidAmountInTHB}
                                    setPaidAmountInMMK={setPaidAmountInMMK}
                                    setPaidAmountInTHB={setPaidAmountInTHB}
                                />
                            </div>
                        </div>
                        <Receipt changeInMMK={changeInMMK} changeInTHB={changeInTHB} discountInMMK={discountInMMK} discountInTHB={discountInTHB} paidAmountInMMK={paidAmountInMMK} paidAmountInTHB={paidAmountInTHB} totalInMMK={totalInMMK} totalInTHB={totalInTHB} mmkTotal={mmkTotal} thbTotal={thbTotal} total={total} discount={discount} voucherCode={voucherCode} subTotal={subTotal} currencyType={currencyType} paidAmount={paidAmount} change={change} getPrintItems={getPrintItems} saleProducts={saleProducts} printRef={printRef} promotionAmount={promotionAmount} promotionProducts={promotionProducts} exchangeRate={exchangeRate} reverseExchangeRate={reverseExchangeRate} />
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button type='submit' className='gold-btn' tabIndex={4}>Create</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Popup className='popup-container'>
                {(popup.eID === 'changePoint' && customer) && <PointChangePopup data={customer} onCloseClick={onClosePopup} modelName="RetailCustomer" callback={(res) => {
                    if (res) {
                        setAdvancedInMMK(res?.advanced_mmk);
                        setAdvancedInMMK(res?.advanced_thb);
                    }
                    setPopup({
                        id: '',
                        show: false,
                    });
                }} />}
            </Popup>
        </div>
    )
}

const convertCurrency = (product, currencyType, THBToMMK, MMKToTHB) => {
    let convertedPrice;

    if (currencyType === product.productPrice.currency) {
        convertedPrice = product.productPrice.retail_price;
    } else {
        if (product.productPrice.currency === "MMK") {
            convertedPrice = product.productPrice.retail_price * MMKToTHB;
        } else {
            convertedPrice = nearValue(product.productPrice.retail_price / THBToMMK);
        }
    }
    return convertedPrice;
}

function nearValue(input) {
    const output = Math.ceil(input / 100) * 100;
    return output;
}
