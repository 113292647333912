import React, { useEffect, useState } from 'react'

function PasswordInput({...rest}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    
  
    return () => {
      
    }
  }, [])
  

  return (
    <div className='password-input my-4'>
        <input 
        autoComplete='off'
        className='x-input'
        type={show ? 'text' : 'password'}
        {...rest}
        />
        <div 
        className={show ? 'toggle-icon show' : 'toggle-icon'}
        onClick={() => setShow(old => !old)}
        ></div>
    </div>
  )
}

export default PasswordInput