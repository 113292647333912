import logo from '../assets/img/goodlogo.png'
import '../assets/css/dashboard.css';
import { Sidebar } from './Sidebar';
import UserProfile from './UserProfile';

export function DashboardLayout({children}) {
    return (
        <div className='page'>
            <div className='dashboard-layout'>
                <div className='side-nav'>
                <div className='dashboard-logo'>
                    <img className='dashboard-logo' src={logo}/>
                </div>
                <Sidebar />
                <UserProfile />
                </div>
                <div className='sub-layout'>
                    {children}
                </div>
            </div>
        </div>
    );
}