import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import TableLayout from '../../components/TableLayout';
import { useLocation } from 'react-router-dom';

const data = {
    results: [
        {
            id: 0,
            productName: 'Product Name 1',
            unit: 'ABC',
            quantity: '1',
            price: '10',
            totalAmount: '10',
            status: 'Active'
        },
        {
            id: 1,
            productName: 'Product Name 1',
            unit: 'ABC',
            quantity: '1',
            price: '10',
            totalAmount: '10',
            status: 'Active'
        },
    ],
    page: 1,
    limit: 10
}

const headers = [
    {
        key: 'productName',
        name: 'Product Name'
    },
    {
        key: 'unit',
        name: 'Unit'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    },
    {
        key: 'price',
        name: 'Price'
    },
    {
        key: 'totalAmount',
        name: 'Total Amount'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

export default function EditPurchaseArrival() {
    const backPath = `/purchase/purchase-arrival/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            status: 'ACTIVE',
        }
        submitForm('POST', '/user', data, () => {
            navigate(backPath)
        });
    }

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Purchase Arrival</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Purchase Arrival Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Supplier Name</label>
                                <input type='text'
                                    required
                                    name='supplier_name'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Purchase Date</label>
                                <input type='text'
                                    required
                                    name='purchase_date'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Currency Type</label>
                                <input type='text'
                                    required
                                    name='currency_type'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Total Amount</label>
                                <input type='text'
                                    name='total_amount'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <TableLayout
                            data={data}
                            headers={headers}
                            hasActions
                            disabledSerial
                        />
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}