const PurchaseArrivalTable = ({ purchaseOrder }) => {
    const today = new Date();
    const formattedDate = today.toISOString().substr(0, 10);
    return (<div className='table-container'>
        <table className='x-table product-list-table'>
            <thead>
                <tr>
                    <th>Barcode</th>
                    <th>Product Name</th>
                    <th>Ordered Quantity</th>
                    <th>Arrived Quantity</th>
                    <th>Arrival Quantity</th>
                    {/* <th>Batch</th> */}
                    <th>Expiry Date</th>
                </tr>
            </thead>
            <tbody>
                {purchaseOrder.purchaseOrderDetails?.map((item) => {
                    const arrivedQty = purchaseOrder.aggregratedArrivalDetails.filter((detail) => detail.product_id === item.product.product_id)[0]?.quantity || 0;
                    return (
                        <tr key={item.product.product_id}>
                            <td>{item.product.barcode}</td>
                            <td>{item.product.product_name}</td>
                            <td>
                                <input
                                    readOnly={true}
                                    disabled={true}
                                    className="w-16"
                                    min='1'
                                    value={item.quantity}
                                    type="number"
                                />
                            </td>
                            <td>
                                <input type="number"
                                    value={arrivedQty}
                                    readOnly={true}
                                    disabled={true}
                                    className="w-16" />
                            </td>
                            <td>
                                <input id={`qty-${item.product_id}`}
                                    type="number"
                                    min={0}
                                    max={item.quantity - arrivedQty}
                                    defaultValue={0}
                                    className="w-16 border border-solid border-neutral-300 rounded"
                                />
                            </td>
                            {/* <td>
                                <input id={`batch-${item.product_id}`}
                                    min={0}
                                    defaultValue={0}
                                    className="w-16 border border-solid border-neutral-300 rounded"
                                    type="number" />
                            </td> */}
                            <td>
                                <input id={`exp-${item.product_id}`}
                                defaultValue={formattedDate}
                                type="date"/>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>);
}

export default PurchaseArrivalTable;