import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'transfer_date',
        name: 'Transfer Date'
    },
    {
        key: 'warehouse_name',
        parent: 'transferFrom',
        name: 'Trasfer From'
    },
    {
        key: 'warehouse_name',
        parent: 'transferTo',
        name: 'Transfer To'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

const detailPopupHeaders = [
    {
        key: 'transfer_id',
        name: 'Transfer ID'
    },
    {
        key: 'warehouse_name',
        parent: 'transferFrom',
        name: 'Transfer From'
    },
    {
        key: 'warehouse_name',
        parent: 'transferTo',
        name: 'Transfer To'
    },
    {
        key: 'remark',//????
        name: "Remark"
    }
]

const detailPopupTableHeaders = [
    {
        key: 'product_name',
        parent: 'productDetails',
        name: 'Product Name'
    },
    {
        key: 'barcode',
        parent: 'productDetails',
        name: 'Barcode'
    },
    {
        key: 'quantity',
        name: 'Quantity'
    }
]

const InventoryTransferList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/inventoryTransfer',
        defaultQuery: 'status=ACTIVE'
    });

    return (
        <TableLayout
            title='Inventory Transfer List'
            data={data}
            headers={headers}
            detailPopupTitle='Inventory Transfer Information'
            detailPopupHeaders={detailPopupHeaders}
            detailPopupTableHeaders={detailPopupTableHeaders}
            detailPopupTableKey='transferDetails'
            idKey='transfer_id'
            endPoint='/inventoryTransfer'
            setCurrentPage={setCurrentPage}
            removeEdit={true}
            hasSearch
            hasCreate
            hasActions
            createPath={`/warehouse/inventory-transfer/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}

export default InventoryTransferList;