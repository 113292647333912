import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import AsyncSelect from 'react-select/async';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import PromotionProductTable from '../../components/PromotionProductTable';

export default function CreatePromotion() {
    const backPath = `/promotion/list`;
    const { handleInputChange, formData, submitForm, setError, error, setFormData } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();

    const [selectedOption, setSelectedOption] = useState('');
    const [promotionType, setPromotionType] = useState();

    const handleOptionChange = (event) => {
        setFormData({})
        setSelectedOption(event.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            "type": selectedOption,
            "data": {
                ...formData,
                "promotion_type": promotionType,
                status: 'ACTIVE',
            }
        }
        if (selectedOption === "CROSS_PRODUCT") {
            const crossable_products = buyProducts.map(product => ({
                product_id: product.product_id,
                barcode: product.barcode,
                quantity: product.quantity
            }))

            const promo_products = getProducts.map(product => ({
                product_id: product.product_id,
                barcode: product.barcode,
                quantity: product.quantity
            }))

            data.data.crossable_products = crossable_products
            data.data.promo_products = promo_products
        }

        if (selectedOption === "PERCENT") {
            data.data.product_id = percentProduct.product_id
            data.data.barcode = percentProduct.barcode
        }

        if (selectedOption === "BOGO") {
            data.data.product_id = bogoProduct.product_id
            data.data.barcode = bogoProduct.barcode
        }
        submitForm('POST', '/promotion', data, () => {
            navigate(backPath)
        });
    }

    useEffect(() => {
        switch (selectedOption) {
            case 'AMOUNT':
                setPromotionType('By Total')
                break;
            case 'PERCENT':
                setPromotionType('By Percent')
                break;
            case 'BOGO':
                setPromotionType('By BOGO')
                break;
            case 'CROSS_PRODUCT':
                setPromotionType('By Cross Product')
                break;
            default:
                break;
        }
    }, [selectedOption])

    const [buyProducts, setBuyProducts] = useState([]);
    const [getProducts, setGetProducts] = useState([]);
    const [percentProduct, setPercentProduct] = useState();
    const [bogoProduct, setBogoProduct] = useState();

    const loadOptions = inputValue => {
        return axios.get(API_URL + '/product?status=ACTIVE', {
            withCredentials: true
        }).then(response => {
            return response.data.data.filter(option =>
                option.product_name.toLowerCase().includes(inputValue.toLowerCase())
            );
        });
    };

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Promotion</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Promotion Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Promotion Type</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='promotion_type'
                                        value={selectedOption} onChange={handleOptionChange}
                                        className='x-input dropdown'
                                    >
                                        <option value='' disabled>Select</option>
                                        <option value="AMOUNT">Promotion By Total Amount</option>
                                        <option value="BOGO">Promotion By Bogos</option>
                                        <option value="PERCENT">Promotion By Percents</option>
                                        <option value="CROSS_PRODUCT">Promotion By Cross Products</option>
                                    </select>
                                </div>
                            </div>
                            <div className='grow'></div>
                            {selectedOption === 'AMOUNT' && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Promotion Amount</label>
                                    <input type='number'
                                        required
                                        min={0}
                                        name='promotion_amount'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'></div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Minimum Total Amount</label>
                                    <input type='number'
                                        required
                                        min={0}
                                        name='minimum_total_amount'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Currency Type</label>
                                    <div className='dd-container'>
                                        <select
                                            type='text'
                                            required
                                            name='currency_type'
                                            onChange={handleInputChange}
                                            defaultValue=''
                                            className='x-input dropdown'
                                        >
                                            <option value='' disabled>Select</option>
                                            <option value="MMK">MMK</option>
                                            <option value="THB">THB</option>
                                        </select>
                                    </div>
                                </div>
                            </>}
                            {selectedOption === 'BOGO' && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Product Name</label>
                                    <AsyncSelect
                                        defaultOptions
                                        cacheOptions
                                        loadOptions={loadOptions}
                                        isSearchable={true}
                                        getOptionLabel={option => option.product_name}
                                        getOptionValue={option => option.product_id}
                                        onChange={option => setBogoProduct(option)}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: '#1b1b1b66',
                                                minHeight: '50px'
                                            }),
                                        }}
                                    />
                                </div>
                                <div className='grow'></div>
                            </>}
                            {selectedOption === 'PERCENT' && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Percent</label>
                                    <input type='number'
                                        min={0}
                                        required
                                        name='percent_amount'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>Product Name</label>
                                    <AsyncSelect
                                        defaultOptions
                                        cacheOptions
                                        loadOptions={loadOptions}
                                        isSearchable={true}
                                        getOptionLabel={option => option.product_name}
                                        getOptionValue={option => option.product_id}
                                        onChange={option => setPercentProduct(option)}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: '#1b1b1b66',
                                                minHeight: '50px'
                                            }),
                                        }}
                                    />
                                </div>
                            </>}

                            {selectedOption === 'CROSS_PRODUCT' &&
                                <>
                                    <div className='grow'>
                                        <label className='x-label input-required'>Buy Product</label>
                                        <AsyncSelect
                                            defaultOptions
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            isSearchable={true}
                                            isMulti={true}
                                            getOptionLabel={option => option.product_name}
                                            getOptionValue={option => option.product_id}
                                            onChange={option => {
                                                const newObj = option.map(item => {
                                                    const quantity = item.quantity ? item.quantity : 1
                                                    return { ...item, quantity }
                                                })
                                                setBuyProducts(newObj)
                                            }}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: '#1b1b1b66',
                                                    minHeight: '50px'
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className='grow'>
                                        <label className='x-label input-required'>Get Product</label>
                                        <AsyncSelect
                                            defaultOptions
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            isSearchable={true}
                                            isMulti={true}
                                            getOptionLabel={option => option.product_name}
                                            getOptionValue={option => option.product_id}
                                            onChange={option => {
                                                const newObj = option.map(item => {
                                                    const quantity = item.quantity ? item.quantity : 1
                                                    return { ...item, quantity }
                                                })
                                                setGetProducts(newObj)
                                            }}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: '#1b1b1b66',
                                                    minHeight: '50px'
                                                }),
                                            }}
                                        />
                                    </div>
                                </>}

                            {selectedOption && <>
                                <div className='grow'>
                                    <label className='x-label input-required'>Start Date</label>
                                    <input type='date'
                                        required
                                        name='start_date'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                                <div className='grow'>
                                    <label className='x-label input-required'>End Date</label>
                                    <input type='date'
                                        required
                                        name='end_date'
                                        autoComplete='false'
                                        onChange={handleInputChange}
                                        className='x-input' />
                                </div>
                            </>
                            }
                        </div>
                        {selectedOption === 'CROSS_PRODUCT' && <>
                            <div className='pt-9'>
                                <PromotionProductTable products={buyProducts} setProducts={setBuyProducts} title='Buy Product' />
                            </div>
                            <div className='pt-9'>
                                <PromotionProductTable products={getProducts} setProducts={setGetProducts} title='Get Product' />
                            </div>
                        </>}
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}