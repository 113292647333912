import { Suspense, useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router';
import { PrivateRoutes } from './routers/PrivateRoutes';
import { PublicRoutes } from './routers/PublicRoutes';
import routes from './routes';
import "./assets/css/common.css";
import Login from './pages/auth/Login';
import axios from 'axios';
import { API_URL } from './utils/constant';

const agenttype = "Admin";




function App() {
  return (
    <Suspense>
      <Routes>
        <Route element={PrivateRoutes()} >
          {routes.map((route) => {
            let page = route;
            if (route.children) {
              page = route.children[0];
            }
           

            // if (agenttype !== "Admin") {
            //   if (page.key.includes("masteragent") || 
            //       page.path.includes("winning") ||
            //       page.key === "announcement") {
            //       return null;
            //   }
            // }
            // if (agenttype !== "Master Agent" && agenttype !== "Admin") {
            //     if (page.key === "agents") {
            //         return null;
            //     }
            // }
            // if (agenttype !== "Agent" && agenttype !== "Master Agent") {
            //     if (page.path.includes("player")) {
            //         return null;
            //     }
            // } 
            return (
              <Route exact path={route.path} element={(agenttype !== "Admin" && route.path == "/dashboard") ? route.component2 : route.component} key={route.key}>
                {route.children && route.children.map((child) => {
                  let checkChild = child;
                  if (child.children) {
                    checkChild = child.children[0];
                  }


                  // if (permission && !permission.includes(checkChild.title)) {
                  //   console.log('NO PERMISSION')
                  //   return null
                  // }
                  // console.log(checkChild.key)
                  // if (agenttype === "Admin") {
                    // if (checkChild.key === ("user-permission") ||
                    //   checkChild.key === "office-user-list-view") {
                    //   return null;
                    // }
                  // }
                  // // user-permission

                  // if (agenttype === "Master Agent") {
                  //   if (checkChild.key === "master-agent-detail-report" ||
                  //     checkChild.key === "gross-profit-report") {
                  //     return null;
                  //   }
                  // }
                  // if (agenttype === "Agent") {
                  //   if (checkChild.key === "master-agent-detail-report" ||
                  //     checkChild.key === "agent-detail-report" ||
                  //     checkChild.key === "comission-report" ||
                  //     checkChild.key === "gross-profit-report") {
                  //     return null;
                  //   }
                  // }
                  return (
                    <Route path={child.path} element={child.component} key={child.key} >
                      {child.children && child.children.map((child2) => (
                        <Route path={child2.path} element={child2.component} key={child2.key} />
                      ))}
                    </Route>
                  )
                })}
              </Route>
            )
          })}
        </Route>
        <Route element={PublicRoutes()} >
          <Route exact path="/login" element={<Login />} key="login" />
        </Route>
        <Route exact path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Suspense>
  );
}

export default App;