import { useLocation } from 'react-router-dom';
import TableLayout from '../../components/TableLayout';
import { useFetchData } from '../../hooks/useFetchData';
import { API_URL } from '../../utils/constant';

const headers = [
    {
        key: 'exchange_rate',
        name: 'THB to MMK'
    },
    {
        key: 'reverse_exchange_rate',
        name: 'MMK to THB'
    },
    {
        key: 'exchange_rate_date',
        name: 'Exchange Date'
    }
];

const CurrencyExchangeList = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const handleSearch = (value) => {
        console.log('searching value: ',value);
    }

    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: API_URL + '/currencyExchange',
        defaultQuery: 'status=ACTIVE'
    });

    return (
        <TableLayout
            title='Currency Exchange List'
            data={data}
            headers={headers}
            idKey='exchange_rate_id'
            endPoint='/currencyExchange'
            removeDetails={true}
            setCurrentPage={setCurrentPage}
            hasSearch
            hasCreate
            hasActions
            createPath={`/currency-exchange/list/create`}
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            disabledSerial
            reload={reload}
        />
    )
}
 
export default CurrencyExchangeList;