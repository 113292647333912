import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import { truncateDecimal } from '../../utils/truncateDecimal';

export default function EditProduct() {
    const { id } = useParams();
    const location = useLocation();
    const { initialData } = location.state;
    const backPath = `/product/list`;
    const { handleInputChange, formData, submitForm, setFormData, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const [selectedProductCategory, setSelectedProductCategory] = useState(initialData.productCategory.product_category_name);
    const [selectedSubproductCategory, setSelectedSubproductCategory] = useState(initialData.productCategory.sub_product_category_name);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = {
            ...formData,
            // barcode,
            product_category_id: getProductCategoryId(selectedProductCategory),
            retail_price : truncateDecimal(formData.retail_price,5),
            wholesale_price : truncateDecimal(formData.wholesale_price,5),
            status: 'ACTIVE',
        }
        console.log(data)
        submitForm('PUT', '/product/' + id, data, () => {
            navigate(backPath)
        });
    }

    const [categories, setCategories] = useState();
    const [barcode, setBarcode] = useState(initialData.barcode);

    useEffect(() => {
        axios.get(API_URL + '/productCategory', {
            withCredentials: true
        })
            .then(response => {
                setCategories(response.data.data)
            })
    }, [])

    const productCategories = Array.from(new Set(categories && categories.map(category => category.product_category_name)));

    const [units, setUnits] = useState();
    useEffect(() => {
        axios.get(API_URL + '/unit', {
            withCredentials: true
        })
            .then(response => {
                setUnits(response.data.data)
            })
    }, [])

    function getProductCategoryId(productCategory) {
        const selectedProduct = categories && categories.find(category => category.product_category_name === productCategory);
        return selectedProduct ? selectedProduct.product_category_id : null;
    }

    useEffect(() => {
        setFormData({
            sku_unit_id: initialData.productPrice.sku_unit_id,
            retail_price: initialData.productPrice.retail_price,
            wholesale_price: initialData.productPrice.wholesale_price,
            remark: initialData.remark
        })
    }, [])

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Edit Product</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label input-required'>Product Category</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='product_category_id'
                                        onChange={e => setSelectedProductCategory(e.target.value)}
                                        value={selectedProductCategory}
                                        className='x-input dropdown'
                                    >
                                        {productCategories.map(category => (
                                            <option key={category} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Product Sub Category</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        name='product_sub_category_id'
                                        required
                                        onChange={e => setSelectedSubproductCategory(e.target.value)}
                                        value={selectedSubproductCategory}
                                        className='x-input dropdown'
                                    >
                                        {categories && categories
                                            .filter(category => category.product_category_name === selectedProductCategory)
                                            .map(category => (
                                                <option key={category.sub_product_category_name} value={category.sub_product_category_name}>
                                                    {category.sub_product_category_name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Product Name</label>
                                <input type='text'
                                    readOnly
                                    disabled
                                    required
                                    name='product_name'
                                    defaultValue={initialData.product_name}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Prefix Code</label>
                                <input type='text'
                                    required
                                    name='prefix_code'
                                    defaultValue={initialData.prefix_code}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Barcode</label>
                                <input type='text'
                                    required
                                    disabled
                                    readOnly
                                    name='barcode'
                                    defaultValue={initialData.barcode}
                                    onChange={e => setBarcode(e.target.value)}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            setBarcode(e.target.value)
                                        }
                                    }}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>SKU</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='sku_unit_id'
                                        onChange={handleInputChange}
                                        value={formData.sku_unit_id ? formData.sku_unit_id : initialData.productPrice.sku_unit_id}
                                        className='x-input dropdown'
                                    >
                                        {
                                            units && units.map((item, index) => (
                                                <option disabled={item.status !== "ACTIVE"} key={index} value={item.unit_id}>{item.unit_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            {/* <div className='grow'>
                                <label className='x-label input-required'>Expired Date</label>
                                <input type="date"
                                    required
                                    name="expired_date"
                                    defaultValue={formatDateToYYYYMMDD(new Date(initialData.expired_date))}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div> */}

                            <div className='grow'>
                                <label className='x-label input-required'>Currency Type</label>
                                <div className='dd-container'>
                                    <select
                                        type='text'
                                        required
                                        name='currency'
                                        defaultValue={initialData.productPrice.currency}
                                        onChange={handleInputChange}
                                        className='x-input dropdown'
                                    >
                                        <option value='BAHT'>THB</option>
                                        <option value='MMK'>MMK</option>
                                    </select>
                                </div>
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Retail Sale Price</label>
                                <input type='decimal'
                                    required
                                    name='retail_price'
                                    defaultValue={initialData.productPrice.retail_price}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>Wholesale Price</label>
                                <input type='decimal'
                                    required
                                    name='wholesale_price'
                                    defaultValue={initialData.productPrice.wholesale_price}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    defaultValue={initialData.remark}
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>

                        </div>
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}