import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import backArrowIcon from '../../assets/img/back-arrow.png';
import "../../assets/css/common.css";
import { useAuth } from '../../routers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../utils/constant';
import PurchaseArrivalTable from '../../components/PurchaseArrivalTable';
import axios from 'axios';


export default function CreatePurchaseArrival() {
    const location = useLocation();
    const { purchaseOrderId, purchaseOrderNo } = location.state;
    const backPath = `/purchase/purchase-arrival/list`;
    const { handleInputChange, formData, submitForm, setError, error } = useForm();
    const navigate = useNavigate();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const items = purchaseOrder.purchaseOrderDetails.map(product => {
            return {
                product_id: product.product_id,
                barcode: product.product.barcode,
                uocQty: product?.unit?.uoc?.base_qty ?? 1,
                quantity: document.getElementById(`qty-${product.product_id}`).value,
                expiry_date: document.getElementById(`exp-${product.product_id}`).value
            };
        })

        const data = {
            items,
            purchase_id: purchaseOrderId,
            ...formData,
            purchase_arrival: purchaseOrderNo,
        }

        submitForm('POST', '/purchaseArrival', data, () => {
            navigate(backPath)
        });
    }

    const [warehouses, setWarehouses] = useState();
    useEffect(() => {
        axios.get(API_URL + '/warehouse?status=ACTIVE', {
            withCredentials: true
        })
            .then(response => {
                setWarehouses(response.data.data);
            })
    }, []);

    const [purchaseOrder, setPurchaseOrder] = useState([]);

    useEffect(() => {
        axios.get(API_URL + `/purchaseOrder/${purchaseOrderId}`, {
            withCredentials: true
        })
            .then(response => {
                setPurchaseOrder(response.data.data);
            })
    }, []);

    return (
        <div className='sub-page-layout-1'>
            <Link className='back-to-page' to={backPath}>
                <img src={backArrowIcon} />
                Back
            </Link>
            <div className='container' style={{ height: 'auto' }}>
                <h1 className='sub-page-title'>Create New Purchase Arrival</h1>
                <div className='form-container'>
                    <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                        <h2 className='sub-title mb-8'>Purchase Arrival Information</h2>
                        <div className='grid grid-cols-2 gap-9'>
                            <div className='grow'>
                                <label className='x-label'>Purchase Order ID</label>
                                <input type='text'
                                    name='purchase_order_id'
                                    autoComplete='false'
                                    value={purchaseOrderNo}
                                    readOnly
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Arrive Warehouse</label>
                                <div className='dd-container'>
                                    <select
                                        required
                                        type='text'
                                        name='arrived_warehouse_id'
                                        className='x-input dropdown'
                                        defaultValue=''
                                        onChange={handleInputChange}
                                    >
                                        <option value='' disabled>Select</option>
                                        {
                                            warehouses && warehouses.map((warehouse, index) =>
                                                <option key={index} value={warehouse.warehouse_id}>{warehouse.warehouse_name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>Arrive Date</label>
                                <input type='date'
                                    required
                                    name='arrival_time'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                            <div className='grow'>
                                <label className='x-label'>Remark</label>
                                <input type='text'
                                    name='remark'
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                    className='x-input' />
                            </div>
                        </div>
                        <PurchaseArrivalTable purchaseOrder={purchaseOrder} />
                        <div className='form-btn-group'>
                            {error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                            <div className='flex justify-end gap-5 mt-5'>
                                <Link to={backPath} className='white-btn'>Cancel</Link>
                                <button className='gold-btn'>Create</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}