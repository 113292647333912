import  axios  from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { API_URL } from "../utils/constant";
import { useCheckUser } from "../hooks/useCheckUser";

const AuthContext = createContext(null);

export const useAuth = () => {
    const auth = useContext(AuthContext);
    return auth;
}

const AuthProvider = ({children}) => {
    const {user, setUser, isLoading, checkUser} = useCheckUser();
    const [loading, setLoading]= useState(false);

    useEffect(() => {
        if (!loading)
        {
            document.getElementById('external-popup').classList.remove('show')
        }
    }, [loading])

    const logout = () => {
        const url = API_URL + '/auth/logout';
        const localData = JSON.parse(localStorage.getItem('user'))??{};
        axios.request({
            method: "POST",
            url,
            headers: {
                'Authorization': `Bearer ${localData?.token}`
            },
        })
        .then(response => {
            console.log('logout')
            setUser(null);
            localStorage.setItem('user', '{}');
        }).catch(e => {
            console.log(e.response)
            setUser(null);
            localStorage.setItem('user', '{}');
        })


    }

    return (
        <AuthContext.Provider value={{user, setUser, logout, checkUser, loading, setLoading}}>
            { !isLoading && children}
        </AuthContext.Provider>
    )

}

export default AuthProvider;