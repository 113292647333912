import { useLocation } from "react-router";
import TableLayout from "../../components/TableLayout";
import { useFetchData } from "../../hooks/useFetchData";
import { API_URL } from "../../utils/constant";

const headers = [
    {
        key: 'name',
        name: 'Name'
    },
    {
        key: 'username',
        name: 'Username'
    },
    {
        key: 'phone1',
        name: 'Phone'
    },
    {
        key: 'status',
        name: 'Status'
    }
];

export function OfficeUserList() {
    const location = useLocation();
    const currentPath = location.pathname;

    const {data, setCurrentPage, setQuery, reload} = useFetchData({
        url: API_URL + '/user',
        defaultQuery: 'status=ACTIVE'
    });

    const handleSearch = (value) => {
        console.log('searching value: ',value);
        setQuery({
            name: value.trim(),
        });
    }

    return (
        <TableLayout
            title="Office User List"
            data={data}
            headers={headers}
            idKey='user_id'
            endPoint='/user'
            removeDetails={true}
            setCurrentPage={setCurrentPage}
            hasSearch
            hasCreate
            hasReset
            createPath={`/office-user/create`}
            hasActions
            currentPath={currentPath}
            onSearch={(value) => handleSearch(value)}
            reload={reload}
        />
    );
}