import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function ReportList() {
    let reportArray = [
        {
            title: "Office User Report",
            link: "/report/office-user",
            group: "operation"
        },
        {
            title: "Retail Sale Report",
            link: "/report/retail-sales",
            group: "sales"
        },
        {
            title: "Wholesale Report",
            link: "/report/wholesale",
            group: "sales"
        },
        {
            title: "Yearly Sale Report",
            link: "/report/yearly-sale",
            group: "sales"
        },
        {
            title: "Warehouse Report",
            link: "/report/warehouse",
            group: "inventory&stock"
        },
        {
            title: "Warehouse Stock Report",
            link: "/report/warehouse-stock",
            group: "inventory&stock"
        },
        {
            title: "Stock Purchase Report",
            link: "/report/stock-purchase",
            group: "inventory&stock"
        },
        {
            title: "Stock Expired Report",
            link: "/report/stock-expired",
            group: "inventory&stock"
        },
        {
            title: "Stock Adjustment Report",
            link: "/report/stock-adjustment",
            group: "inventory&stock"
        },
        {
            title: "Retail Customer Report",
            link: "/report/retail-customer",
            group: "customer"
        },
        {
            title: "Wholesale Customer Report",
            link: "/report/wholesale-customer",
            group: "customer"
        },
        {
            title: "Point Redemption Report",
            link: "/report/point-redemption",
            group: "customer"
        },
    ];

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>Report</h1>
            </div>

            <h4 className="mt-8 text-lg">Operation Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'operation').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>

            {/* <h4 className="mt-8 text-lg">Merchant Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'merchant').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul> */}

            <h4 className="mt-8 text-lg">Sales Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'sales').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>


            <h4 className="mt-8 text-lg">Inventory And Stock Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'inventory&stock').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>

            {/* <h4 className="mt-8 text-lg">Receivable and Payable Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'receivable&payable').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul> */}

            <h4 className="mt-8 text-lg">Customer Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'customer').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>


            {/* <ul className="mt-3 ml-6">
                {reportArray.map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul> */}
        </div>

    )
}

const ReportItem = ({ item }) => {
    return (
        <li className="mb-5">
            <Link className="text-xl underline" to={item.link}>{item.title}</Link>
        </li>
    );
};

export default ReportList;
